import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function PagionationDropdown(props){

    //pagina attuale
    //numero di record
    //funzione per cambiare

    const lastHandler = () =>{
        var last = Math.ceil(props.totaleRecord / props.recordPerPagina)
        props.setPagina(last)
    }

    return (
    <Pagination style={{fontWeight:"bolder"}}>
        <Pagination.First disabled={props.pagina === 1 ? true : false} onClick={()=>props.setPagina(1)} />
        <Pagination.Prev disabled={props.pagina === 1 ? true : false} onClick={()=>props.setPagina(props.pagina-1)}/>
        <Pagination.Item key="this" active={true}>{props.pagina}</Pagination.Item>
        <Pagination.Next disabled={props.pagina === Math.ceil(props.totaleRecord / props.recordPerPagina) ? true : false}  onClick={()=>props.setPagina(props.pagina+1)}/>
        <Pagination.Last disabled={props.pagina === Math.ceil(props.totaleRecord / props.recordPerPagina) ? true : false} onClick={()=>lastHandler()}/>
    </Pagination>
    )

}