import React,{useState,useContext} from 'react'
import {AuthContext,AuthConsumer} from '../authentication'
import { PazienteContext } from '../context/PazienteContext'
import { AdminContext } from '../context/AdminContext'
import FormComponent from './FormComponent'
import './Style.css'

export default function FunctionalComponent(){
const {login,data,getUserInfo,getPazienti,getProtocolli} = useContext(AuthContext)
const {getData} = useContext(AdminContext);
const {getScriptTraining} = useContext(PazienteContext);

const [controlloLocalStorage,setControlloLocalStorage] = useState(true);
const [loading,setLoading]=useState(false);
const [credenzialiNonValide,setCredenzialiNonValide]=useState(false);
const [pazientiEstratti,setPazientiEstratti]=useState(false);
const [scriptEstratto,setScriptEstratto]=useState(false);
const [utentiEstratti,setUtentiEstratti]=useState(false);
const [errore,setErrore] = useState(null);

const doLogin =async (username,password) =>{
    setErrore(null);
    //Tentativo di login
    if(!loading){
        setLoading(true)
        try{
            //Se il login e' andato a buon fine
            await login(username,password)
                .then(async function(){
                    setCredenzialiNonValide(false)
                    doGetUserInfo()
                })
        }catch(error){
            //Se il login non e' andato a buon fine
            if(!credenzialiNonValide || loading){
            setCredenzialiNonValide(true)
            setErrorText(error)
            }
        }
    }
}//doLogin

const doGetUserInfo =async ()=>{
    try{
        //Ricezione delle informazioni riguardanti 
        await getUserInfo("Bearer "+data.jwt)
            .then(function(){
                setLoading(false)
            })
    }catch(error){
        //Se la ricezione delle informazioni non e' andata a buon fine
        setErrorText(error)
    }
}//doGetUserInfo

const doGetPazienti =async () =>{
    setLoading(true)
    try{
        await getPazienti()
            .then(async function(){
               await doGetProtocolli()
            })
    }catch(error){
        console.log(error)
        setErrorText(error)
    }
}

const doGetProtocolli =async () =>{
    try{
        await getProtocolli()
            .then(function(){
                setLoading(false)
                setPazientiEstratti(true)
            })
    }catch(error){
        console.log(error)
        setErrorText(error)
    }
}

const doGetScriptTraining = async () =>{
    setLoading(true)
    try{
        await getScriptTraining(AuthConsumer._currentValue)
            .then(function(){
                setLoading(false)
                setScriptEstratto(true)
            })
    }catch(error){
        console.log(error)
        setErrorText(error)
    }
}

const doGetUtenti = async () =>{
    setLoading(true);
    try{
        await getData().then(function(){
            setLoading(false);
            setUtentiEstratti(true);
        })
    }catch(error){
        console.log(error);
        setErrorText(error)
    }

}

const controllaStorage = () =>{
    setControlloLocalStorage(false)
    if(sessionStorage.getItem('authCont') !== null && sessionStorage.getItem('pazCont') !== null){
        // Remove all saved data from sessionStorage
        sessionStorage.clear();
    }
}

const setErrorText = (error) =>{
    var text = error.response === undefined ? "Errore di comunicazione con il server" : error.response.data;
    
    if(errore === null) {
        setErrore(text);
        setLoading(false);
    }
} 

return (
<div className="containerLogin">
    {controlloLocalStorage ? controllaStorage() : null}
    <FormComponent 
        doLogin={(username,password)=>doLogin(username,password)} credenzialiNonValide={credenzialiNonValide} loading={loading}
        pazientiEstratti={pazientiEstratti} doGetPazienti={()=>doGetPazienti()} 
        scriptEstratto={scriptEstratto} doGetScriptTraining={()=>doGetScriptTraining()}
        utentiEstratti={utentiEstratti} doGetUtenti={()=>doGetUtenti()} errore={errore}
        />
</div>)

}