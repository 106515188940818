import React,{useState} from 'react'
import {Alert, Row,Col , Dropdown,Button} from 'react-bootstrap'
import FormMedicoTerapista from './FormMedicoTerapista'
import FormPaziente from './FormPaziente'

export default function FormRegistrazione(props){

    const [ruolo,setRuolo] = useState(null)
    const [testoRuolo,setTestoRuolo] = useState("Seleziona Ruolo")

    const selezionaRuolo = (id,nome) =>{
        setRuolo(id);
        setTestoRuolo(nome);
    }

    const getDropRuoli = () =>{
        const body=[];
        body.push(<Dropdown.Item key={1} onClick={()=>{selezionaRuolo(1,"Medico")}} >Medico</Dropdown.Item>)
        body.push(<Dropdown.Item key={3} onClick={()=>{selezionaRuolo(3,"Terapista")}} >Terapista</Dropdown.Item>)
        body.push(<Dropdown.Item key={2} onClick={()=>{selezionaRuolo(2,"Paziente")}} >Paziente</Dropdown.Item>);

        return body
    }

    return (
    <div>
        <Alert variant="primary"><b>Dati generali</b></Alert>
        <Row>
            <Col md="6" xs="12">
                <Alert variant="info"><b>Seleziona ruolo</b></Alert>
            </Col>
            <Col md="6" xs="12">
                <Dropdown style={{width:"100%"}}>
                    <Dropdown.Toggle style={{width:"100%"}}>{testoRuolo}</Dropdown.Toggle>
                    <Dropdown.Menu style={{width:"100%"}}>{getDropRuoli()}</Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
        <br/>
        {ruolo === null ? 
            null 
            :
            ruolo === 2 ?
                <FormPaziente  {...props} ruolo={ruolo} testoRuolo={testoRuolo}/>
                :
                <FormMedicoTerapista {...props} ruolo={ruolo} testoRuolo={testoRuolo}/>
        }
        <br/><br/>
        <Button variant="danger" onClick={()=>props.setSceltaMenu()}  style={{width:"20%",marginLeft:"80%"}}>INDIETRO</Button>
    </div>)

}