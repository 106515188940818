import React,{useState} from 'react'
import { Table,Row,Col,Form,Badge,Dropdown,Alert } from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { getObjectGroup,checkFiltriGruppo } from '../Util';
import Pagination from '../../../components/BasicComponents/Pagination';
import PagionationDropdown from './PaginationDropdown';

export default function TabellaGruppi(props){

    //filtri
    const [filtroNomeGruppo,setFiltroNomeGruppo] = useState("");
    const [filtriMembri,setFiltriMembri] = useState([]);
    const [nominativiBadge,setNominativiBadge] = useState([]);
    const [nomeUtente,setNomeUtente] = useState("")
    //pagination
    const [pagina,setPagina]=useState(1);
    const [recordPerPagina,setRecordPerPagina]=useState(5);
    //paginationDropdown
    const [paginaDrop,setPaginaDrop] = useState(1);
    const [recordDrop,setRecordDrop] = useState(0);
    const recordPerPaginaDrop = 4

    const getHeader = () =>{
        const header = [];
        header.push(<th key="nomeHeader">Nome</th>);
        header.push(<th key="numeroMedici">Numero Medici</th>);
        header.push(<th key="numeroTerapisti">Numero Terapisti</th>);
        header.push(<th key="numeroPazienti">Numero Pazienti</th>);
        header.push(<th key="numeroScript">Numero Script</th>);
        header.push(<th key="infoHeader">Info</th>);
        return <tr key="rowHeader">{header}</tr>
    }

    const getBody = () =>{
        const body = [];
        var contatore = 0;
        AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
            var objGruppo = getObjectGroup(gruppo);
            //checkFiltri
            var checkFiltri = checkFiltriGruppo(objGruppo,filtroNomeGruppo,filtriMembri)
            if(checkFiltri){
                const row = []
                row.push(<td key={"nome"+objGruppo.id}>{objGruppo.nome}</td>);
                row.push(<td key={"numeroMedici"+objGruppo.id}>{objGruppo.numeroMedici}</td>);
                row.push(<td key={"numeroTerapisti"+objGruppo.id}>{objGruppo.numeroTerapisti}</td>);
                row.push(<td key={"numeroPazienti"+objGruppo.id}>{objGruppo.numeroPazienti}</td>);
                row.push(<td key={"numeroScript"+objGruppo.id}>{objGruppo.numeroScript}</td>);
                row.push(<td key={"info"+objGruppo.id} role="button" onClick={()=>props.vediInfo(objGruppo)}><FontAwesomeIcon icon={faInfoCircle} /></td>);
                body.push(<tr key={"row"+objGruppo.id}>{row}</tr>);
                contatore++;
            }
        })

        return (
            <div>
                <Table bordered striped hover style={{textAlign:"center"}}>
                    <thead>{getHeader()}</thead>
                    <tbody>{body}</tbody>
                </Table>
                <Pagination totaleRecord={contatore} recordPerPagina={recordPerPagina} setRecordPerPagina={(num)=>setRecordPerPagina(num)} setPagina={(value)=>setPagina(value)} pagina={pagina}/>
            </div>
            )
    }

    const addUtente = (utente,nominativo) =>{
        const newMembri = filtriMembri;
        var obj = {
            ...utente,
            nominativo:nominativo
        }
        newMembri.push(obj)
        setFiltriMembri(newMembri)
        getNominativiBadge()
    }

    const getDropUtenti = () =>{
        const USERS = AdminConsumer._currentValue.utenti;
        const body = []
        var contatore = 0
        var primoElemento = (recordPerPaginaDrop * paginaDrop) - recordPerPaginaDrop
        var ultimoElemento = recordPerPaginaDrop * paginaDrop
        USERS.forEach(utente=>{
            var selezionato = false;
            filtriMembri.forEach(filtri=>{
                if(filtri.user.id === utente.user.id) selezionato = true
            })
            if(!selezionato){
                var nominativo = utente.user.ruolo === 2 ? utente.user.username : utente.user.nome + " " + utente.user.cognome;
                if(nominativo.toUpperCase().indexOf(nomeUtente.toUpperCase()) > -1){
                    if(contatore >= primoElemento && contatore < ultimoElemento)
                        body.push(<Dropdown.Item key={"dropitem"+utente.user.id} onClick={()=>addUtente(utente,nominativo)}>{nominativo}</Dropdown.Item>)
                    contatore++
                }
            }
        })
        if(contatore !== recordDrop) setRecordDrop(contatore)

        return body
    }

    const getNominativiBadge= () =>{
        const body = [];
        filtriMembri.forEach(utenteSelezionato=>{
            body.push(<Badge key={"badge"+utenteSelezionato.user.id} style={{marginLeft:"5px",fontSize:"0.85em",backgroundColor:"white",color:"black"}}>{utenteSelezionato.nominativo+" "}<FontAwesomeIcon style={{color:"red"}} icon={faTimesCircle} role="button" onClick={()=>removeMembroDaFiltro(utenteSelezionato.user.id)}/></Badge>)
        })
        setNominativiBadge(body)
    }

    const removeMembroDaFiltro = (idIgnore) =>{
        const body = [];
        filtriMembri.forEach(utenteSelezionato=>{
            if(utenteSelezionato.user.id !== idIgnore) body.push(utenteSelezionato)
        })
        setFiltriMembri(body)
    }

    return (
        <div>
            <Row>
                <Col md="4">
                    <Form.Control type="text" placeholder="Nome gruppo" onChange={(event)=>setFiltroNomeGruppo(event.target.value)} value={filtroNomeGruppo} />
                </Col>
                <Col md="1"/>
                <Col md="7">
                    <Dropdown style={{width:"100%"}}>
                        <Dropdown.Toggle style={{width:"100%"}}>
                            Seleziona membri
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Alert>
                            <Dropdown.Header >
                                <Form.Control type="text" placeholder="Cerca membro" onChange={(event)=>setNomeUtente(event.target.value)} />
                            </Dropdown.Header>
                            {getDropUtenti()}
                            <Dropdown.Divider/>
                            { recordDrop > 0 ?
                                <PagionationDropdown recordPerPagina={recordPerPaginaDrop} totaleRecord={recordDrop} pagina={paginaDrop} setPagina={(page)=>setPaginaDrop(page)} />
                                :
                                <Alert variant="danger">Nessun utente trovato con i parametri di ricerca inseriti</Alert>
                            }
                            </Alert>
                        </Dropdown.Menu>
                    </Dropdown>
                    {filtriMembri.length > 0 ?
                    <div>
                        <br/>
                        <Alert variant="info">Membri selezionati :{filtriMembri.length !== nominativiBadge.length ? getNominativiBadge():nominativiBadge}</Alert>
                    </div>
                    : null
                    }
                </Col>
            </Row>
            <br/>
            {getBody()}
        </div>
    )

}