import React from 'react'
import {Row,Col} from 'react-bootstrap'
import './Loader.css'
export default function Loader(props){

    const variant = props.pagina==="Med" || props.pagina==="Paz" ? "spinner-grow text-primary" : "spinner-grow text-light"
    const testo = props.pagina==="Med" || props.pagina==="Paz" ? "textMed" : "text"

    return(
    <div className="spinner-container">
        <div className="wrapper">
            <Row className="spinner">
                <Col><div className={variant} style={{width:"10vw",height:"10vw"}} role="status"/></Col>
                <Col><div className={variant} style={{width:"10vw",height:"10vw"}} role="status"/></Col>
                <Col><div className={variant} style={{width:"10vw",height:"10vw"}} role="status"/></Col>
            </Row>
            <br/>
        </div>
        <div className="divText">
            <h3 className={testo} >Loading...</h3>
        </div>
    </div>
    )
}