import React,{useState} from 'react'
import {Table,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../components/BasicComponents/Pagination'
import FiltriTabella from './FiltriTabella';
import {verificaFiltri} from './CheckFiltriFunction'

export default function TabellaMedici(props){

    //filtri
    const [nominativo,setNominativo] = useState("");
    const [ruoloId,setRuoloId] = useState(null);
    const [gruppoId,setGruppoId] = useState(null);
    //pagination
    const [pagina,setPagina]=useState(1);
    const [recordPerPagina,setRecordPerPagina]=useState(5);
    const [numeroElementi,setNumeroElementi]=useState(0);

    const getRole=(codice)=>{
        if(codice === 1) return "Medico"
        else if(codice === 3) return "Terapista"
        else return "Ruolo non trovato"
    }

    const getTabellaMedici=()=>{
        const header = [];
        const headerRow = [];
        header.push(<th key="headerCognome">Cognome</th>)
        header.push(<th key="headerNome">Nome</th>)
        header.push(<th key="headerUsername">Username</th>)
        header.push(<th key="headerRuolo">Ruolo</th>)
        header.push(<th key="headerInfo">Info</th>)
        headerRow.push(<tr key="headerRow">{header}</tr>)

        const body=[]
        var contatore = 0;
        var primoElemento = (recordPerPagina * pagina) - recordPerPagina
        var ultimoElemento = recordPerPagina * pagina
        
        props.medici.forEach(medico=>{
            var checkFiltri = verificaFiltri(medico,nominativo,ruoloId,gruppoId);
            if(checkFiltri){
                if(contatore >= primoElemento && contatore < ultimoElemento){
                    const row = [];
                    row.push(<td key={"cognome"+medico.id}>{medico.cognome}</td>)
                    row.push(<td key={"nome"+medico.id}>{medico.nome}</td>)
                    row.push(<td key={"username"+medico.id}>{medico.username}</td>)
                    row.push(<td key={"ruolo"+medico.id}>{getRole(medico.ruolo)}</td>)
                    row.push(<td key={"info"+medico.id} role="button" onClick={()=>props.setMedico(medico)}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </td>)

                    body.push(<tr key={"row"+medico.id}>{row}</tr>)
                }//pagination
                contatore++;
            }
        })

        if(numeroElementi !== contatore) setNumeroElementi(contatore)

        return( 
            contatore > 0 ?
            <div>
                <Table striped hover bordered style={{textAlign:"center"}}>
                    <thead>{headerRow}</thead>
                    <tbody>{body}</tbody>
                </Table>
                <Pagination totaleRecord={numeroElementi} recordPerPagina={recordPerPagina} setRecordPerPagina={(num)=>setRecordPerPagina(num)} setPagina={(value)=>setPagina(value)} pagina={pagina}/>
            </div>
            :
            <Alert variant="danger">Nessun utente trovato con i parametri di ricerca inseriti</Alert>
        )
    }
    
    return <div>
                <FiltriTabella nominativo={nominativo} setNominativo={(valore)=>setNominativo(valore)} setRuoloId={(id)=>setRuoloId(id)} setGruppoId={(id)=>setGruppoId(id)}/>
                <br/>
                {getTabellaMedici()}
    </div>
}