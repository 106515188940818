import React,{useState,useContext} from 'react'
import { Viewer } from '../../visualizzazioneComponents'
import { Alert,Row,Col,Button } from 'react-bootstrap'
import { AdminContext } from '../../../context/AdminContext'
import ViewNewPassword from './ViewNewPassword';
import ConfirmComponent from '../Components/ConfirmComponent';
export default function Credenziali(props){

    const [sure,setSure] = useState(false);
    const [vediModalConfirm,setVediModalConfirm] = useState(false);
    //chiamata api
    const {resetPassword} = useContext(AdminContext);
    const [loading,setLoading] = useState(false);
    const [errore,setErrore] = useState(false);
    const [messaggio,setMessaggio] = useState(false);

    const verificaSicurezza = () =>{
        if(!sure) setVediModalConfirm(true)
    }

    const sceltaConfirm = (scelta) =>{
        if(scelta === true) {
            setVediModalConfirm(false)
            setSure(true)
            doResetPassword()
        }else setVediModalConfirm(false)
    }

    const doResetPassword = async () =>{
        setErrore(false);
        if(!loading){
            setLoading(true);
            var richiesta = {
                idUtente : props.utente.id
            }
            try{
                await resetPassword(richiesta).then(function(){
                    visualizzaRisposta();
                })
            }catch(error){
                console.log(error)
                setErrore(true);
                if(error.response !== undefined) setMessaggio(",tipo errore :"+error.response.data)
                
            }
        }
    }

    const visualizzaRisposta = () =>{
        setLoading(false);
        setErrore(false);
        //setMessaggio(password);
    }

    return(
        <div>
            <Viewer testo="Username" valore={props.utente.username}/>
            <hr/>
            <Row>
                <Col>
                    <Alert variant="info">Si desidera effettuare il reset della password ?</Alert>
                </Col>
                <Col>
                    <Button variant="success" style={{width:"100%"}} onClick={()=>verificaSicurezza()}>RESET PASSWORD</Button>
                </Col>
            </Row>
            <ConfirmComponent vediModalConfirm={vediModalConfirm} setVediModalConfirm={(valore)=>setVediModalConfirm(valore)} 
            nominativo={props.nominativo} sceltaConfirm={(value)=>sceltaConfirm(value)} messaggio="Sicuro di voler effetturare il reset della password "/>
            <ViewNewPassword sure={sure} setSure={()=>setSure(false)} loading={loading} errore={errore} messaggio={messaggio} />
            <hr/>
        </div>
    )
}