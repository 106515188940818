import React,{useState} from 'react'
import {Table} from 'react-bootstrap'
import {faPlus} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../../../components/BasicComponents/Pagination'
import './TabellaTraining.css'

export default function TabellaTraining(props){
    const [pagina,setPagina]=useState(1);
    const recordPerPagina=5;


    const getBody=()=>{
        var rows=[]
        var cont=0
        var date = []
        props.training.forEach((element)=>{date.push(element.oraInizio);})
        date.sort()
        var ordinamentoDate=[]
        var trainingOrdinato = []
        for(var i=date.length-1 ; i>=0 ; i-- ){
            var index = i;
            ordinamentoDate.push(date[index]);
        }
        ordinamentoDate.forEach((data)=>{
            props.training.forEach((element)=>{
                if(data===element.oraInizio) trainingOrdinato.push(element)
            })
        })
        
        trainingOrdinato.forEach((train)=>{
            if(cont>=(recordPerPagina*pagina)-recordPerPagina && cont < recordPerPagina*pagina){
                var row=[]
                //DATA DEL TRAINING
                var giorno =  new Date(train.oraInizio).getDate();
                if(giorno<10) giorno = '0'+giorno
                var mese =  new Date(train.oraInizio).getMonth()+1;
                if(mese<10) mese = '0'+mese
                var anno =  new Date(train.oraInizio).getFullYear();
                var data = giorno+"/"+mese+"/"+anno;
                //ORA INIZIO TRAINING
                var ora = new Date(train.oraInizio).getHours();
                if(ora<10) ora = '0'+ora
                var minuti  = new Date(train.oraInizio).getMinutes();
                if(minuti<10) minuti = '0'+minuti
                var secondi  = new Date(train.oraInizio).getSeconds();
                if(secondi<10) secondi = '0'+secondi
                var orainizio = ora+":"+minuti+":"+secondi
                //ORA FINE TRAININF
                ora = new Date(train.oraFine).getHours();
                if(ora<10) ora = '0'+ora
                minuti  = new Date(train.oraFine).getMinutes();
                if(minuti<10) minuti = '0'+minuti
                secondi  = new Date(train.oraFine).getSeconds();
                if(secondi<10) secondi = '0'+secondi
                var orafine = ora+":"+minuti+":"+secondi
                //CALCOLO DURATA
                var durataSecondi = train.durata%60
                var durataMinuti = (train.durata - durataSecondi)/60
                var messaggioDurata = durataMinuti +"m "+durataSecondi+"s"

                row.push(<td key={cont+"par0"}>{train.titoloScript}</td>)
                row.push(<td key={cont+"par1"}>{data}</td>)
                row.push(<td key={cont+"par2"}>{orainizio}</td>)
                row.push(<td key={cont+"par3"}>{orafine}</td>)
                row.push(<td key={cont+"par4"}>{messaggioDurata}</td>)
                
                row.push(<td key={cont+"par5"} role="button" onClick={()=>props.setTrainingInfo(train)} style={{textAlign:"center"}}>
                    <FontAwesomeIcon icon={faPlus}/>
                    </td>)

                rows.push(<tr key={cont+"row"}>{row}</tr>)
            }
            cont++
        })

        return(
            <>
                <Table striped hover bordered responsive className="tabella">
                    <thead>
                        <tr key="Header">
                            <th key="H0">SCRIPT</th>
                            <th key="H1">DATA</th>
                            <th key="H2">ORA INIZIO</th>
                            <th key="H3">ORA FINE</th>
                            <th key="H4">DURATA</th>
                            <th key="H5">INFO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
                <Pagination recordPerPagina={recordPerPagina} cambiaVisualizzazione={false} totaleRecord={props.training.length} setPagina={(numero)=>setPagina(numero)}/>
            </>
        )

    }


    return (
        <>{getBody()}</>
    )
}