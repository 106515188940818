import React from 'react'
import {Row,Col} from 'react-bootstrap'
import InfoSessione from './InfoSessione'
import './VediInfoSessione.css'
export default function VediInfoSessione(props){
    
    const getElencoSessioni=()=>{
        const elenco=[]
        var cont=0
        props.TrainingScelto.elencoSessioni.forEach((sessione)=>{
            cont++
            var secondi = sessione.durata%60
            var minuti = (sessione.durata-secondi)/60
            var messaggioDurata = " "+minuti+" minuti e "+secondi+" secondi"

            elenco.push(<InfoSessione key={cont} numeroSessione={cont} pause={sessione.numPause} durata={messaggioDurata}/>)
        })

        return elenco
    }

    const getData=()=>{
        var giorno = new Date(props.TrainingScelto.oraInizio).getDate()
        var mese = new Date(props.TrainingScelto.oraInizio).getMonth()+1
        var anno = new Date(props.TrainingScelto.oraInizio).getFullYear()
        return giorno+"/"+mese+"/"+anno
    }

    const getOra=(data)=>{
        var secondi = new Date(data).getSeconds()
        var minuti = new Date(data).getMinutes()
        var ora = new Date(data).getHours()
        return ora+":"+minuti+":"+secondi
    }

    const getDurata=()=>{
        var secondi = props.TrainingScelto.durata%60
        var minuti = (props.TrainingScelto.durata-secondi)/60
        return minuti+" minuti "+secondi+" secondi"
    }

    return(
    <div className="containerInfo">
        <h5>
        <br/>
        <Row>
            <Col xs="6">
                TITOLO : 
            </Col>
            <Col>
                {props.TrainingScelto.titoloScript}
            </Col>
        </Row>
        <br/>
        <Row>
            <Col xs="6">
                DATA TRAINING:
            </Col>
            <Col>
            {getData()}
            </Col>
        </Row>
        <br/>
        <Row>
            <Col xs="6">
                ORA INIZIO : 
            </Col>
            <Col>
                {getOra(props.TrainingScelto.oraInizio)}
            </Col>
        </Row>
        <br/>
        <Row>
            <Col xs="6">
                ORA FINE : 
            </Col>
            <Col>
                {getOra(props.TrainingScelto.oraFine)}
            </Col>
        </Row>
        <br/>
        <Row>
            <Col xs="6">
                DURATA : 
            </Col>
            <Col>
                {getDurata()}
            </Col>
        </Row>
        </h5>
        <br/>
        {getElencoSessioni()}
    </div>
    )
}