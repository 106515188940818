import React from 'react'
import {Button,Row,Col} from 'react-bootstrap'
import FineTraining from '../../StaticComponents/FineTrainingModal'

export default function VimeoController(props){

    const stileButton = {width:"100%"}
    
    const configButtonPlayStop = {
        variant : props.playing ? "danger" : "primary" ,
        testo : props.playing ? "PAUSA" : "RIPRODUCI" ,
        disabled : props.fine ? true : false,
        onClick : () => props.funzione(),
        style : stileButton
    }

    const configButtonRestart = {
        variant : "success",
        testo : "RESTART VIDEO",
        disabled : props.fine ? true : false,
        onClick : ()=>props.restart(),
        style : stileButton
    }

    const configButtonRipeti = {
        variant : "info",
        testo : "RIPETI",
        disabled : props.fine ? true : false,
        onClick : ()=>props.ripeti(),
        style : stileButton
    }


return(
    <div style={{marginLeft:"2.5%",width:"95%"}}>
        <Row>
            <Col>
                <Button {...configButtonPlayStop}>
                    {configButtonPlayStop.testo}
                </Button>
            </Col>
            <Col>
                <Button {...configButtonRipeti}>
                    {configButtonRipeti.testo}
                </Button>
            </Col>
            <Col>
                <Button {...configButtonRestart}>
                    {configButtonRestart.testo}
                </Button>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
            </Col>
            <Col />
            <Col>
                <FineTraining setFineTraining={()=>props.fineTraining()} />
            </Col>
        </Row>
    </div>
)

}