import React,{useState} from 'react'
import YTVideo from '../VisualizzazioneScript/YTVideo/YTVideo'
import {Row,Col,Button,Alert,Table} from 'react-bootstrap'
import {faTimesCircle , faEdit} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisualizzazioneDistrattori from '../InserimentoDistrattori/VisualizzazioneDistrattori'
import './ModalInfoScript.css'
import VimeoPlayer from '../../../VimeoComponents/Player/VimeoComponent'

export default function MadalInfoScript(props){
    const [numeroParole,setNumeroParole]=useState(0)
    const [numeroFrasi,setNumeroFrasi]=useState(0)
    const [numeroSillabe,setNumeroSillabe]=useState(0)
    const [vediBody,setVediBody]=useState(null)

    const getTestoScipt = () =>{
        var body=[]
        var contaParole=0
        var contaSillabe=0
        var contatoreFrasiPrincipali=0
        if(props.script.elencoFrasi.length>0){
            //estrarre gli id frase distinct
            var elencoFrasiPrincipali=[]
            props.script.elencoFrasi.forEach((elemento)=>{
                if(elemento.tipo===1) {
                    contatoreFrasiPrincipali=contatoreFrasiPrincipali+1
                    elencoFrasiPrincipali.push(elemento)
                    contaParole=contaParole+elemento.n_parole;
                    contaSillabe=contaSillabe+elemento.n_sillabe;
                }
            })
            if(elencoFrasiPrincipali.length>0){
                elencoFrasiPrincipali.forEach((frasePrincipale)=>{
                    var elencoDistrattori=[]
                    props.script.elencoFrasi.forEach((elemento)=>{
                        if(elemento.tipo===2 && elemento.idFrase===frasePrincipale.idFrase) elencoDistrattori.push(elemento.frase)
                    })
                    body.push(<VisualizzazioneDistrattori key={frasePrincipale.idFrase} testo={frasePrincipale.frase} distrattori={elencoDistrattori}/>)
                })
            }
            
            if(numeroParole===0){
                setNumeroParole(contaParole);
                setNumeroFrasi(contatoreFrasiPrincipali);
                setNumeroSillabe(contaSillabe);
            }
            if(vediBody===null) setVediBody(body)

        }else{
            if(vediBody===null) setVediBody(<Alert variant="danger">errore nel caricamento del testo dello script</Alert>)
        }
    }

    return(
        <>
            <div className="iconaChiudiModalInfoScript">
                <FontAwesomeIcon role="button" onClick={()=>props.fine()} icon={faTimesCircle}/>
            </div>
            <br/>
            {props.script.visibile===true || props.sezione==="Libreria"?
            <div>
                <Row>
                    <Col>
                {/*TITOLO*/}
                <Row>
                    <Col md="3" xs="5">
                        <h4>Titolo</h4>
                    </Col>
                    <Col md="9" xs="7">
                        <h4><b>{props.script.titoloScript}</b></h4>
                    </Col>
                </Row>
            {/* TOPIC*/}
                <Row>
                    <Col md="3" xs="5">
                    <h4>Topic</h4>
                    </Col>
                    <Col md="9" xs="7">
                    <h4><b>{props.script.topic}</b></h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="3" xs="5">
                    <h4>Severità</h4>
                    </Col>
                    <Col md="9" xs="7">
                    <h4><b>{props.script.severita}</b></h4>
                    </Col>
                </Row>
                <br/>
                <h4>Testo</h4>
                <br/>
                <h5>{vediBody===null ? getTestoScipt():vediBody}</h5>
                </Col>
                <Col xs="12" className="divider"/>
                <Col>
                <div style={props.sezione==="Libreria" ? {} : {display:"none"}}>
                    <br/><br/>
                    <Button onClick={()=>props.modificaScript()}>
                        MODIFICA SCRIPT<FontAwesomeIcon icon={faEdit} style={{fontSize:"2,5vw",marginLeft:"5px"}}/>
                    </Button>
                    <br/><br/>
                </div>
                <div style={{border:"1px solid black"}}>
                    <Alert variant="info">CARATTERISTICHE DELLO SCRIPT</Alert>
                    <Table striped>
                        <tbody>
                            <tr key="frasiInfo">
                                <td key="nomeFrasiInfo">NUMERO FRASI</td>
                                <td key="valoreFrasiInfo">{numeroFrasi}</td>
                            </tr>
                            <tr key="paroleInfo">
                                <td key="nomeParoleInfo">NUMERO PAROLE TOTALI</td>
                                <td key="valoreParoleInfo">{numeroParole}</td>
                            </tr>
                            <tr key="lunghezzaInfo">
                                <td key="nomeLunghezzaInfo">MEDIA NUMERO SILLABE PER FRASE</td>
                                <td key="valoreLunghezzaInfo">{(numeroSillabe/numeroFrasi).toFixed(0)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <br/>
                    <VimeoPlayer url={props.script.urlVideo} />
                    </Col>
                </Row>
            </div>
            :
            <Alert variant="danger">
                Visualizzazione dello script non disponibile.<bt/>Contatta l'amministratore per ottenere l'accesso allo script.
            </Alert>
            }
            <br/><br/>
            <Row>
                <Col/>
                <Col md="3">
                <Button variant="danger" onClick={()=>props.fine()} className="buttonChiudiModal">FINE</Button>
                </Col>
            </Row>
        </>
    )
}