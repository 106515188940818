import React,{useState}from 'react'
//fontAwesome
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal'
import {Button,Alert} from 'react-bootstrap'
//schede di valutazione
import ArruolamentoPaziente from './SchedeDiValutazione/DatiPrimaVisita'
import ParametriDiEloquio from './SchedeDiValutazione/ParametriDiEloquio'
//Payload
import PayloadUploadSchede from '../../PayloadMenu/PayloadUploadSchede'
import {AuthConsumer} from '../../../../authentication'
import './PopOverSchede.css'
//configurazione modal
Modal.setAppElement('#root')
//modal component
export default function PopOver(props){
const[modalIsOpen,setModalIsOpen]=useState(true)

const[focusMenu,setFocusMenu]=useState(0)
const[inizializzato,setInizializzato]=useState(false)
const[salvaScheda,setSalvaScheda]=useState(0);

function fine(){
    setModalIsOpen(false)
    props.funzione(true)
}

const salvataggioEseguito = () =>{
    alert('Salvataggio eseguito consuccesso')
    setSalvaScheda(0)
}


return(
    <Modal isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)} shouldCloseOnOverlayClick={false} >
        <FontAwesomeIcon style={{fontSize:"40px",marginLeft:"95%",color:"red"}} role="button" onClick={fine} icon={faTimesCircle}/>
        {salvaScheda === 0 ?
            <>
            {inizializzato===false ?
                <PayloadUploadSchede idPaziente={props.Paziente.ID} api="getSchede" initDone={()=>setInizializzato(true)}/>
                :
                <>
            {focusMenu===0? 
                <>
                    <br/><br/>
                    <Alert variant="info">
                        Schede di valutazione del paziente con id : <b>{props.Paziente.idPaziente}</b>
                    </Alert>
                    <br/>
                    <Alert variant="success" role="button" onClick={()=>setFocusMenu(1)} className="linkScheda">
                        <b>Dati prima visita</b>
                    </Alert>
                    <br/>
                    <Alert variant="success" role="button" onClick={()=>setFocusMenu(2)} className="linkScheda" >
                        <b>Parametri di eloquio</b>
                    </Alert>
                    <Button variant="danger" onClick={fine} className="buttonSchede">FINE</Button>
                </>:null}
            {focusMenu===1?
                <ArruolamentoPaziente paziente={props.Paziente} schede={AuthConsumer._currentValue.schedeDiValutazione} salvaScheda={(scheda)=>setSalvaScheda(scheda)} indietro={()=>setFocusMenu(0)}/>
                :null}
            {focusMenu===2?
            <ParametriDiEloquio paziente={props.Paziente} schede={AuthConsumer._currentValue.schedeDiValutazione} salvaScheda={(scheda)=>setSalvaScheda(scheda)} indietro={()=>setFocusMenu(0)}/>
                :null}
            </>
            }
            </>
            :
            <PayloadUploadSchede idPaziente={props.Paziente.ID} api="saveScheda" scheda={salvaScheda} initDone={()=>salvataggioEseguito()}/>
        }
    </Modal>
)

}