import React from 'react'
import {Button,Row,Col} from 'react-bootstrap'
import Popup from 'reactjs-popup'
const Modal = (props) => (
    <Popup 
    trigger={<Button variant="danger" style={{width:"100%"}} className="buttonNewLine">FINE TRAINING</Button>} 
    modal
    >
     {close=>(<div style={{backgroundColor:"white",textAlign:"center",position:"relative",marginLeft:"5%",marginRight:"5%",border:"2px solid red"}}>
          <br/><br/><br/>
        <h2>SEI SICURO DI VOLER CONCLUDERE IL TRAINING?</h2>
        <br/><br/>
        <Row>
            <Col md="2"/>
            <Col md="3">
                <Button variant="success" name="accetta" style={{width:"100%"}} onClick={()=>{props.setFineTraining();close();}}>
                    SI
                </Button>
            </Col>
            <Col md="2"/>
            <Col md="3">
                <Button variant="danger" name="rifiuta" style={{width:"100%"}} onClick={()=>close()}>
                    NO
                </Button>
            </Col>
            <Col md="2"/>
        </Row>
        <br/><br/><br/>
      </div>)}
    </Popup>
  );
  export default Modal