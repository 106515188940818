import React from 'react'
import HomeDottore from '../pages/HomeDottore'
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
import Login from '../pages/Login'
import HomePaziente from '../pages/HomePaziente'
import Admin from '../pages/Admin'
import NotFoundPage from '../pages/NotFound' 

export default function Navigation(){
return(
<Router>
        <Switch>
          {/*Pagina per il login*/}
          <Route exact path="/" component={Login}/>
          {/*Interfaccia Dottore*/}
          <Route exact path="/HomeDottore" component={HomeDottore}/>
          {/*Pagine per gli utenti Pazienti*/}
          <Route exact path="/HomePaziente" component={HomePaziente}/>
          {/*Pagina per l'amministrazione delle utenze*/}
          <Route exact path="/Admin" component={Admin}/>
          {/*Page not found*/}
          <Route path="*" component={NotFoundPage} />
        </Switch>
</Router>
)
}