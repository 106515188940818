import React from 'react'
import {Alert,Row,Col} from 'react-bootstrap'
import './CampoRiepilogoPaziente.css'

export default function CampoRiepilogoPazienti(props){
    return(<>
        <Alert>
            <Row>
                <Col style={{textAlign:"center"}}>
                    {props.nome}
                </Col>
                <Col xs="12" className="breaker"/>
                <Col style={{textAlign:"center"}} className="valore">
                    {props.valore}
                </Col>
            </Row>
        </Alert>
        <hr/>
        </>
    )
}