import React from 'react'
import {Button,Alert} from 'react-bootstrap'
import TabellaMedici from './TabellaMedici'
import { AdminConsumer } from '../../../context/AdminContext'

export default function GestisciMedici(props){

    const getTabellaMedici = () =>{
        const MEDICI = [];
        if(AdminConsumer._currentValue.utenti !== null){
            AdminConsumer._currentValue.utenti.forEach(utente=>{
                if(utente.user.ruolo !== 2) {
                    const gruppi = []
                    AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
                        var membriGruppo = gruppo.membri
                        membriGruppo.forEach(membro=>{
                            if(Number(membro) === utente.user.id) gruppi.push(gruppo.id)
                        })
                    })
                    MEDICI.push({
                        ...utente.user,
                        idGruppi:gruppi
                    })
                }
            })

            return <TabellaMedici medici={MEDICI} {...props} />

        }else{
            return <Alert variant="danger">Nessun medico presente tra le utenze del centro</Alert>
        }
    }

    return(
        <div>
            {getTabellaMedici()}
            <br/><br/>
            <Button variant="danger" onClick={props.setSceltaMenu} style={{width:"20%",marginLeft:"80%"}}>INDIETRO</Button>
        </div>
    )
}