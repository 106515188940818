import React from 'react';
import { Container } from 'react-bootstrap'
import CollegamentiMenu from "../../../StaticComponents/CollegamentiMenu"
import { AuthConsumer } from '../../../../authentication';
export default function MenuDottore(props){

    return(
        <Container>
            <div>
                {
                    AuthConsumer._currentValue.role === 1 ? 
                <CollegamentiMenu pagina={1} testo="Nuovo Paziente" sezione="Medico" setInterfaccia={()=>props.setInterfaccia(1)}/>
                : <><br/><br/></>
                }
                <CollegamentiMenu pagina={2} testo="Gestione pazienti" sezione="Medico" setInterfaccia={()=>props.setInterfaccia(2)}/>
                <CollegamentiMenu pagina={3} testo="Libreria script" sezione="Medico" setInterfaccia={()=>props.setInterfaccia(3)}/>
            </div>
        </Container>
        );
}