import React,{useState} from 'react'
import { Modifier } from '../../visualizzazioneComponents'
import { Alert,Button,Dropdown ,Row,Col, Form ,InputGroup} from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'
import PagionationDropdown from '../../GestioneGruppi/TabellaGruppi/PaginationDropdown'
import FormDropdownSearch from '../../../components/FormComponents/FormDropdownSearch'
import Provincie from '../../../Interfaccia-Medico/Components-Medico/Menu/FormRegistrazione/Provincie'
import ConfermaDati from './ConfermaDati'
import FormRadioButton from '../../../components/FormComponents/FormRadioButton'

const INITIAL_STATE = {
    idPaziente:"",
    protocollo:null,
    gruppo:0,
    nascita:"",
    residenza:"",
    annoNascita:0,
    sesso:null
}

export default function FormMedicoTerapista(props){

    const stileButton = {width:"20%",marginLeft:"80%"}
    const [dati,setDati]=useState(INITIAL_STATE);
    const [nomeGruppo,setNomeGruppo] = useState("Seleziona gruppo");
    const [filtroDrop,setFiltroDrop] = useState("");
    //Protocollo
    const [protocolloText,setProtocolloText] = useState("Seleziona protocollo")
    const [testoSesso,setTestoSesso] = useState("")
    //dropdown pagination
    const [pagina,setPagina] = useState(1)
    const recordPerPagina = 5
    const [vediRecap,setVediRecap] = useState(false)
    const [objectRecap,setObjectRecap] = useState(null)

    const selezionaGruppo = (selezionato) =>{
        setNomeGruppo(selezionato.nome)
        setDati({...dati,gruppo:selezionato})
    }

    const getGestioneGruppi = () =>{
        var gruppiEsiste = true;
        const body = []
        var contatore = 0
        if(AdminConsumer._currentValue.gruppi !== null){
            if(AdminConsumer._currentValue.gruppi.length > 0){
                AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
                    
                    if(filtroDrop === "" || gruppo.nome.toUpperCase().indexOf(filtroDrop.toUpperCase())>-1){

                        body.push(<Dropdown.Item key={gruppo.id} id={gruppo.id} onClick={()=>selezionaGruppo(gruppo)} >{gruppo.nome}</Dropdown.Item>)
                        contatore++    
                    }
                })
                gruppiEsiste = true
                if(body.length === 0) body.push(<Alert variant="danger">Nessun gruppo trova con i parametri inseriti</Alert>)

            }else gruppiEsiste = false
        }else gruppiEsiste = false

        if(gruppiEsiste) {
            return (<Row>
                    <Col md="6" xs="12">
                        <Alert variant="info"><b>Seleziona gruppo</b></Alert>
                    </Col>
                    <Col md="6" xs="12">
                        <Dropdown>
                            <Dropdown.Toggle style={{width:"100%"}}>{nomeGruppo}</Dropdown.Toggle>
                            <Dropdown.Menu style={{width:"100%"}}>
                                <Dropdown.Header>
                                    <Form.Control type="text" onChange={(event)=>setFiltroDrop(event.target.value)} placeholder="Cerca gruppo"/>
                                </Dropdown.Header>
                                <Dropdown.Divider/>
                                {body}
                                <Dropdown.Divider/>
                                <PagionationDropdown pagina={pagina} setPagina={(pag)=>setPagina(pag)} recordPerPagina={recordPerPagina} totaleRecord={contatore}/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>)
        }
        else return <Alert variant="danger">Nessun gruppo trovato</Alert>
    }

    const selezionaProtocollo = (event) =>{
        var nome = event.target.name
        var id = event.target.id
        setProtocolloText(nome)
        setDati({...dati,protocollo:id})
    }

    const impostaProvincie = (provincia,sezione) =>{
        if(sezione === "nascita") setDati({...dati,nascita:provincia})
        if(sezione === "residenza") setDati({...dati,residenza:provincia})
    }

    const checkValidoAnno = () =>{
        var eta = new Date().getFullYear() - dati.annoNascita
        if(eta > 85 || eta < 30) return false
        else return true
    }

    const checkInvio = () =>{
        var errorePresente = false;
        var messaggioErrore = "Errore nei seguenti campi :\n"
        if(dati.protocollo === null){
            errorePresente = true;
            messaggioErrore += "- Seleziona il protocollo\n"
        }
        if(dati.idPaziente.length === 0) {
            errorePresente = true;
            messaggioErrore += "- Inserisci l'ID Paziente\n"
        }
        if(dati.sesso === null){
            errorePresente = true;
            messaggioErrore += " - Seleziona il Sesso\m"
        }
        if(dati.gruppo === 0){
            errorePresente = true;
            messaggioErrore += "- Seleziona il gruppo a cui associarlo\n"
        }
        if(!checkValidoAnno()){
            errorePresente = true;
            messaggioErrore += "- Anno di Nascita (L'età deve essere compresa tra un minimo di 30 anni ed un massimo di 85 anni)\n"
        }
        if(dati.nascita === ""){
            errorePresente = true;
            messaggioErrore += "- Provincia di Nascita\n"
        }
        if(dati.residenza === ""){
            errorePresente = true;
            messaggioErrore += "- Provincia di residenza\n"
        }


        if(errorePresente) alert(messaggioErrore)
        else invio()
    }

    const modificaSesso = (nome) =>{
        var codice = 0
        if(nome ==="Maschio") codice = 1
        else if(nome ==="Femmina") codice = 2
        else if(nome ==="Altro") codice = 0
        setTestoSesso(nome)
        setDati({...dati,sesso:codice})
    }

    const invio = () =>{
        var object = {
            ...dati,
            ruolo:props.ruolo
        }
        setObjectRecap(object)
        setVediRecap(true)
    }

    return (
        <div>
            <FormDropdownSearch testo="Protocollo" funzione={(event)=>selezionaProtocollo(event)} opzioni={AdminConsumer._currentValue.protocolli} valore={protocolloText} valido={5} />
            <br/>
            <Modifier b testo="ID Paziente" id="idPaziente" valore={dati.idPaziente} valido={dati.idPaziente !== ""} funzione={(event)=>setDati({...dati,idPaziente:event.target.value})} place="ID Paziente"/>
            <br/>
            <FormRadioButton testo="Sesso" genere={true} opzioni={["Maschio","Femmina","Altro"]} funzione={(id)=>modificaSesso(id)} valore={testoSesso} ignoreValid={true} />
            <br/>
            <Row>
                <Col md="6" xs="12">
                    <Alert variant="info"><b>Provincia di nascita</b></Alert>
                </Col>
                <Col md="6" xs="12">
                    <Provincie impostazioneProvincia={impostaProvincie} sezione="nascita" provinciaSelezionata={dati.nascita} stile={{width:"100%"}}/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md="6" xs="12">
                    <Alert variant="info"><b>Provincia di residenza</b></Alert>
                </Col>
                <Col md="6" xs="12">
                    <Provincie impostazioneProvincia={impostaProvincie} sezione="residenza" provinciaSelezionata={dati.residenza} stile={{width:"100%"}}/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md="6" xs="12">
                    <Alert variant="info"><b>Anno di nascita</b></Alert>
                </Col>
                <Col md="6" xs="12">
                    <Form.Control type="number" placeholder="Anno di nascita" min={1930} max={new Date().getFullYear()} onChange={(event)=>setDati({...dati,annoNascita:event.target.value})} style={checkValidoAnno() ? {border:"1px solid green"} : {border:"1px solid red"}}/>
                </Col>
            </Row>
            <br/>
            <Alert variant="primary"><b>Associazione gruppo</b></Alert>
            <br/>
            {getGestioneGruppi()}
            <br/>
            <Button variant="success" onClick={()=>checkInvio()} style={stileButton}>INVIO</Button>
            {vediRecap === true ?
            <ConfermaDati {...props} vediRecap={vediRecap} setVediRecap={(valore)=>setVediRecap(valore)} {...props} protocolloText={protocolloText} sessoText={testoSesso} objectRecap={objectRecap}/>
            : null}
        </div>

    )
}