import React from 'react'
import HomeAdmin from '../Interfaccia-Admin/HomeAdmin'
import { AuthConsumer } from '../authentication'
import Unauthorized from './Unauthorized'

export default function Admin(){
    return(
       AuthConsumer._currentValue.role === 0 ?
        <HomeAdmin/>
        :
        <Unauthorized />
    )
}