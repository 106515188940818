import React from 'react'
import {Button,Alert} from 'react-bootstrap'
import TabellaPazienti from './TabellaPazienti'
import { AdminConsumer } from '../../../context/AdminContext'

export default function GestisciPazienti(props){

    const getTabellaPazienti = () =>{
        const PAZIENTI = [];
        if(AdminConsumer._currentValue.utenti !== null){
            AdminConsumer._currentValue.utenti.forEach(utente=>{
                if(utente.user.ruolo === 2) {
                    var gruppoPaziente = null
                    AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
                        var membriGruppo = gruppo.membri
                        membriGruppo.forEach(membro=>{
                            if(Number(membro) === utente.user.id) gruppoPaziente = gruppo
                        })
                    })
                    PAZIENTI.push({
                        ...utente,
                        gruppoPaziente
                    })
                }
            })

            return <TabellaPazienti pazienti={PAZIENTI} {...props} />

        }else{
            return <Alert variant="danger">Nessun paziente presente tra le utenze del centro</Alert>
        }
    }

    return(
        <div>
            {getTabellaPazienti()}
            <br/><br/>
            <Button variant="danger" onClick={props.setSceltaMenu} style={{width:"20%",marginLeft:"80%"}}>INDIETRO</Button>
        </div>
    )
}