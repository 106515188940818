import React from 'react'
import {Row,Col,Button} from 'react-bootstrap'
export default function FraseInserita(props){
    return(
        <>
            <Row>
                <Col md="10">
                    {props.frase}
                </Col>
                <Col>
                    <Button variant="danger" onClick={()=>props.elimina(props.posizione)}>
                        ELIMINA
                    </Button>
                </Col>
            </Row>
            <br/>
        </>
    )
}