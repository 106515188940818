import React,{useState} from 'react'
import {Table,Alert} from 'react-bootstrap'
import {faPlus} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../../../components/BasicComponents/Pagination'
import PopOverValutazioni from '../FormValutazionePaziente/PopOverSchede'
import PopOverAttivita from '../AssegnazioneScript/PopOverAttivita'

export default function TabellaPazienti(props){
    const [vediValutazioni,setVediValutazioni]=useState(0)
    const [vediAttivita,setVediAttivita]=useState(0)
    const [numeroTotale,setNumeroTotale]=useState(props.pazienti.length)
    const [currentPage,setCurrentPage]=useState(1)
    const [pazientiPerPage,setPazientiPerPage]=useState(5)

    const stileGetter = {textAlign:"center"}

    const getSesso = (codice) =>{
        var sesso = ""
        switch(codice){
            case 0:{
                sesso = "Altro";
                break;
            }
            case 1:{
                sesso = "Maschio";
                break;
            }
            case 2:{
                sesso = "Femmina";
                break;
            }
        }
        return sesso
    }

    const getBody = () =>{
        var contatoreElementi = 0
        const rowHeader = []
        if(props.vediID===true) rowHeader.push(<th key="H1">ID Paziente</th>);
        if(props.vediAnnoNascita===true) rowHeader.push(<th key="H2">Anno di Nascita</th>);
        if(props.vediLuogoDiNascita===true) rowHeader.push(<th key="H3">Provincia di Nascita</th>);
        if(props.vediLuogoDiResidenza===true) rowHeader.push(<th key="H4">Provincia di Residenza</th>);
        if(props.vediSesso===true) rowHeader.push(<th key="H5">Sesso</th>);
        if(props.vediValutazione===true) rowHeader.push(<th key="H6" style={stileGetter}>Schede di Valutazione</th>);
        if(props.vediGestioneAttivita===true) rowHeader.push(<th key="H7" style={stileGetter}>Gestione Attività</th>);
        const rows = []
        //props.vediID
        var primoElemento = (pazientiPerPage * currentPage) - pazientiPerPage
        var ultimoElemento = pazientiPerPage * currentPage
        props.pazienti.forEach((paziente) => {
            //TODO: aggiungere filtro nome,cognome o  cf
            var idPazienteUpper = paziente.idPaziente.toUpperCase();
            var labelUpper = props.label_cf.toUpperCase();
            if( idPazienteUpper.indexOf(labelUpper)>-1)
            {
                //controllo se inserire nel body
                if(contatoreElementi>=primoElemento && contatoreElementi<ultimoElemento){
                    var row = []
                    if(props.vediID===true) row.push(<td key={"B1E"+paziente.idPaziente}>{paziente.idPaziente}</td>);
                    if(props.vediAnnoNascita===true) row.push(<td key={"B2E"+paziente.idPaziente}>{paziente.annoNascita}</td>);
                    if(props.vediLuogoDiNascita===true) row.push(<td key={"B3E"+paziente.idPaziente}>{paziente.provinciaNascita}</td>);
                    if(props.vediLuogoDiResidenza===true) row.push(<td key={"B4E"+paziente.idPaziente}>{paziente.provinciaResidenza}</td>);
                    if(props.vediSesso===true) row.push(<td key={"B5E"+paziente.idPaziente}>{getSesso(paziente.sesso)}</td>);
                    if(props.vediValutazione===true) row.push(
                                                        <td key={"B6E"+paziente.idPaziente} role="button" onClick={()=>setVediValutazioni(paziente)} style={stileGetter}>
                                                            <FontAwesomeIcon icon={faPlus}/>
                                                        </td>)
                    if(props.vediGestioneAttivita===true) row.push(
                                                        <td key={"B7E"+paziente.idPaziente} role="button" onClick={()=>setVediAttivita(paziente)} style={stileGetter}>
                                                            <FontAwesomeIcon icon={faPlus}/>    
                                                        </td>)
                    rows.push(<tr key={paziente.idPaziente+"r"}>{row}</tr>)
                }//filtro pagination
                contatoreElementi++
            }//filtro nome,cognome,cf
        });
        if(contatoreElementi!==numeroTotale) { setNumeroTotale(contatoreElementi) }
        if(contatoreElementi > 0)   {
            return (
            <>
            <Table striped hover bordered responsive>
                <thead><tr key="H">{rowHeader}</tr></thead>
                <tbody>{rows}</tbody>
            </Table>
            <Pagination recordPerPagina={pazientiPerPage} setRecordPerPagina={(num)=>setPazientiPerPage(num)} totaleRecord={numeroTotale} setPagina={(newPage)=>setCurrentPage(newPage)}/>
            </>
            )
        }else{
            return (<Alert variant="danger">Nessun paziente tovato per la ricerca :<b>{props.label_cf}</b></Alert>)
        }

    
    }

    return (<>
            {getBody()}
            {vediValutazioni===0?null:<PopOverValutazioni Paziente={vediValutazioni} funzione={()=>setVediValutazioni(0)}/>}
            {vediAttivita===0?null:<PopOverAttivita Paziente={vediAttivita} funzione={()=>setVediAttivita(0)}/>}
        </>)
}