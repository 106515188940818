import React,{useState} from 'react'
import {Button,Alert} from 'react-bootstrap'
import DistrattoreFrase from './DistrattoreFrase'
export default function RaccoltaFrasiScript(props){
    const ButtonStyle={marginLeft:"80%",width:"20%"}
    const [elencoDistrattori,setElencoDistrattori]=useState(null)
    const [elencoSillabe,setElencoSillabe]=useState(null)

    const aggiornaElencoDistrattori = (posizione,distrattori) =>{
        var elencoAttuale=[]
        var cont=0
        elencoDistrattori.forEach((elenco)=>{
            if(cont===posizione) elencoAttuale.push(distrattori)
            else elencoAttuale.push(elenco) 
            cont++
        })
        if(elencoDistrattori!==elencoAttuale) setElencoDistrattori(elencoAttuale)
    }

    const aggiornaElencoSillabe = (posizione,numero)=>{
        var elencoAttuale=[]
        var cont=0
        elencoSillabe.forEach((numeroSillabe)=>{//range sillabe 1-50
            if(cont===posizione && numero!=="") {
               elencoAttuale.push(numero)
            }
            else {
                elencoAttuale.push(numeroSillabe)
            }
            cont++
        })
        setElencoSillabe(elencoAttuale)
    }

    const getFrasiScript = () =>{
        const body=[]
        var testo = props.testoScript[0]
        var frasi = testo.split("\n")
        const distrattoriInit=[]
        const sillabeInit=[]
        var cont=0
        frasi.forEach((frase)=>{
            frase=frase.trim()
            if(frase){
                body.push(<DistrattoreFrase key={cont} frase={frase} posizione={cont} 
                    aggiornaElencoDistrattori={(posizione,distrattori)=>aggiornaElencoDistrattori(posizione,distrattori)}
                    aggiornaElencoSillabe={(posizione,numero)=>aggiornaElencoSillabe(posizione,numero)}
                    />)
                distrattoriInit.push("")
                sillabeInit.push(1)
                cont++
            }
        })
        if(elencoDistrattori===null) {//inizializzazione elenchi distrattori e sillabe
            setElencoDistrattori(distrattoriInit)
            setElencoSillabe(sillabeInit)
        }
        return <>{body}</>
    }

    const invio = () =>{
        var messaggio="Descrizione script:"
        for(var i=0;i<props.elencoFrasi.length;i++){
            var elenco=elencoDistrattori[i]
            var numeroSillabeFrase=elencoSillabe[i]
            var messaggioFrase="\nFrase "+(i+1)+" '"+props.elencoFrasi[i]+"' ("+numeroSillabeFrase+" sillabe)"
            var messaggioDistrattori="\nElenco frasi distrattore associate:"
            if(elenco){
                elenco.forEach((fraseDistrattore)=>{
                    messaggioDistrattori=messaggioDistrattori+"\n-"+fraseDistrattore
                })
            }
            if(messaggioDistrattori!=="\nElenco frasi distrattore associate:") messaggioFrase=messaggioFrase+messaggioDistrattori
            else messaggioFrase=messaggioFrase+"\nNessun distrattore inserito"
            messaggio=messaggio+messaggioFrase
        }
        props.invio(elencoDistrattori,elencoSillabe)//posso inviargli l'elenco dei distrattori e l'elenco delle sillabe
    }

    return(
        <>
            <br/>
            <Alert variant="info">
                <strong>INSERIMENTO DISTRATTORI</strong>
            </Alert>
            <br/>
            {getFrasiScript()}
            <Button variant="success" onClick={()=>invio()} style={ButtonStyle}>
                INVIO
            </Button>
            <br/><br/>
            <Button variant="danger" onClick={props.modificaScript} style={ButtonStyle}>
                INDIETRO
            </Button>
        </>
    )
}