import React from 'react'
import { Modal,Button,Row,Col,Container } from 'react-bootstrap'
export default function ConfirmComponent(props){

    const buttonStyle={width:"100%"}

    return(
        <Modal size="lg" show={props.vediModalConfirm} onHide={()=>props.setVediModalConfirm(false)} backdrop="static">
            <Container>
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Conferma
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.messaggio}<b>{props.nominativo}</b> ?
                        <br/><br/>
                        <Row>
                            <Col md="5">
                                <Button variant="success" onClick={()=>props.sceltaConfirm(true)} style={buttonStyle}>SI</Button>
                            </Col>
                            <Col />
                            <Col md="5">
                                <Button variant="danger" onClick={()=>props.sceltaConfirm(false)} style={buttonStyle}>NO</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            </Container>
        </Modal>
        )
}