import React from 'react'
import {Alert} from 'react-bootstrap'
//import gestione del calnedario
import ElencoDate from './ElencoDate'
export default function ComposizioneCalendario(props){
    return(
        <>
            <Alert variant="success">
                        Sessioni di training del paziente con id : <strong>{props.Paziente.idPaziente}</strong>
            </Alert>
            <ElencoDate/>
        </>
    )
}