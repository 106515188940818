import React from 'react'
import {Alert,Row,Col} from 'react-bootstrap'

export default function FormRadioButton(props){
    const stileValido=props.clOp?{border:"1px solid #23ff00"} : {border:"1px solid #23ff00",width:"50%"}
    const stileNonValido=props.clOp? {border:"1px solid red"} : {border:"1px solid red",width:"50%"}

    const getOpzioni = () =>{
        const scelte=[]
        props.opzioni.forEach((opzione)=>{
            scelte.push(
            <div key={opzione} style={props.genere?{width:"33%"}:{width:"50%"}}>
                <input type="radio" id={opzione} checked={props.valore===opzione?true:false} onChange={(event)=>props.funzione(event.target.id)}/>
                {opzione}
            </div>
        )})
        return <Row>{scelte}</Row>}
    
    return(
        <Row>
            <Col>
                <Alert variant="info" className={props.clAl}>
                    <b>{props.testo}</b>
                </Alert>
            </Col>
            {props.break ? <Col xs="12" className="breaker"/> : null}
            <Col>
                <Alert style={props.ignoreValid? {} : props.valido?stileValido:stileNonValido} className={props.clOp}>
                    {getOpzioni()}
                </Alert>
            </Col>
        </Row>
    )
}