import React,{useState,useContext} from 'react'
import { Modifier } from '../../../../visualizzazioneComponents'
import { Row,Col,Alert,Dropdown,Button } from 'react-bootstrap'
import ConfirmComponent from '../../../../StaticComponents/Components/ConfirmComponent';
import ModalInvio from './ModalInvio';
import { AdminContext } from '../../../../../context/AdminContext';

export default function DatiGenerali(props){

    const [newData,setNewData] = useState(props.medico);
    const [vediInvio,setVediInvio] = useState(false)
    const [vediModalConfirm,setVediModalConfirm] = useState(false)
    const [sure,setSure] = useState(false)
    const [messaggio,setMessaggio] = useState("")
    const [errore,setErrore] = useState(false)
    const [loading,setLoading] = useState(false)

    const {updateUser,getData} = useContext(AdminContext);

    //CONTROLLO DATI
    const getRoleDrop = () =>{
        const body = []
        body.push(<Dropdown.Item key={1} eventKey="1" name={1} id="ruolo" onClick={(event)=>{modifica(event)}} >Medico</Dropdown.Item>)
        body.push(<Dropdown.Item key={3} eventKey="2" name={3} id="ruolo" onClick={(event)=>{modifica(event)}} >Terapista</Dropdown.Item>)
        return <Dropdown style={{width:"100%"}}>
            <Dropdown.Toggle style={{width:"100%"}} >{getTestoRuolo(newData.ruolo)}</Dropdown.Toggle>
            <Dropdown.Menu style={{width:"100%"}}>{body}</Dropdown.Menu>
        </Dropdown>
    }

    const getTestoRuolo = (codice) =>{
        if(codice === 1) return "Medico"
        else if(codice === 3) return "Terapista"
        else return "Ruolo non trovato"
    }

    const modifica = (event) =>{
        const id  = event.target.id;
        const valore = event.target.value;
        const nome = event.target.name;
        var newObject = null
        if(id === "cognome") newObject={...newData,cognome:valore}
        if(id === "nome") newObject={...newData,nome:valore}
        if(id === "ruolo") newObject={...newData,ruolo:Number(nome)}
        if(newObject !== null) {
            setNewData(newObject);
            confronto(newObject);
        }
    }

    const confronto = (newObject) =>{
        var checkNome = true
        if(props.medico.nome === newObject.nome) checkNome = false
        var checkCognome = true
        if(props.medico.cognome === newObject.cognome) checkCognome = false
        var checkRuolo = true
        if(props.medico.ruolo === newObject.ruolo) checkRuolo = false
        var response = checkNome || checkCognome || checkRuolo ? true : false
        //se uguali diventa false
        setVediInvio(response)
    }

    const sceltaConfirm = (scelta) =>{
        if(scelta === true) {
            setVediModalConfirm(false)
            setSure(true)
            doUpdateUser()
        }else setVediModalConfirm(false)
    }

    //API
    const invio = () =>{
        if(sure) doUpdateUser()
        else setVediModalConfirm(true)
    }

    const doUpdateUser =async () =>{
        setLoading(true)
        try{
            updateUser(newData).then(async function(){ doGetData() })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const doGetData =async () =>{
        try{
            getData().then(function(){
                setErrore(false)
                setLoading(false)
            })
        }catch(error){
            erroreChiamata(error)
        }
    } 

    const erroreChiamata = (error) =>{
        setLoading(false);
        setErrore(true);
        if(error.response !== undefined) setMessaggio(",tipo errore :"+error.response.data);
    }

    return(
        <div>
            <Modifier testo="Cognome" valore={newData.cognome} id='cognome' funzione={(event)=>modifica(event)} valido={newData.cognome !== ""}/>
            <hr/>
            <Modifier testo="Nome" valore={newData.nome} id='nome' funzione={(event)=>modifica(event)} valido={newData.nome !== ""}/>
            <hr/>
            <Row>
                <Col>
                    <Alert variant="info">Ruolo</Alert>
                </Col>
                <Col>
                    {getRoleDrop()}
                </Col>
            </Row>
            <hr/>
            {vediInvio ?
            <Button variant="success" onClick={()=>invio()} style={{width:"20%",marginLeft:"80%"}}>INVIO</Button>
            : null}
            <ConfirmComponent vediModalConfirm={vediModalConfirm} setVediModalConfirm={(valore)=>setVediModalConfirm(valore)} 
            nominativo={props.nominativo} sceltaConfirm={(value)=>sceltaConfirm(value)}  messaggio="Sicuro di voler applicare le modifiche ai dati dell'utente " />
            <ModalInvio sure={sure} setSure={()=>setSure(false)} messaggio={messaggio} nominativo={props.nominativo} errore={errore} loading={loading} chiudi={()=>props.chiudi()} />
        </div>
    )
}