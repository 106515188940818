import React,{useState,useContext} from 'react'
import {AuthContext} from '../../../authentication'
import {Alert} from 'react-bootstrap'
import Loader from '../../../Loader/Loader'

export default function PayloadFocusScript(props){

    const [chiamataApi,setChiamataApi]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)

    const { getScript , focusScript } = useContext(AuthContext);

    const doGetFocusScript = async () =>{

        setIsLoading(true);

        var identificatore = {};
        identificatore.idScript = props.idScript;
        try{
            await getScript(identificatore)
            .then(function(){
                setError(false)
                setChiamataApi(true)
                setIsLoading(false)
                props.setScriptAnteprima()
            })
        }catch(error){
            console.log(error)
            setError(true)
            setChiamataApi(true)
            setIsLoading(false)
        }
    }

    return (isLoading === true ?
        <Loader pagina="Med"/>
        :
        error === true ?
            <Alert variant="danger">Errore nel caricamento delle informazioni delloscript</Alert>
            :
            chiamataApi === false ? 
                doGetFocusScript()
                :
                null
    )
}