import React,{useState} from 'react'
import Pagination from '../../components/BasicComponents/Pagination'
import { Table,Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faCheckCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function TabellaScript(props){

    const [pagina,setPagina] = useState(1);
    const [recordPerPagina,setRecordPerPagina] = useState(5);

    const checkFiltriScript = (script) =>{
        var validoTitolo = false;
        if(props.filtroTitolo === "") validoTitolo = true;
        if(script.titolo.toUpperCase().indexOf(props.filtroTitolo.toUpperCase()) > -1) validoTitolo = true;

        var validoGruppo = false;
        if(props.filtroGruppo === null) validoGruppo = true;
        if(props.filtroGruppo === script.idGruppo) validoGruppo = true;

        if(!validoGruppo || !validoTitolo) return false
        else return true
    }

    const getBody = () =>{
        const header = []
        header.push(<th key="htitolo">Titolo</th>)
        header.push(<th key="htopic">Topic</th>)
        header.push(<th key="hlivello">Severita'</th>)
        header.push(<th key="hgruppo">Gruppo</th>)
        header.push(<th key="hvisibile">Visibile</th>)
        header.push(<th key="hInfo">Info</th>)

        var contatore = 0;
        const body = [];
        props.elencoScript.forEach(script=>{
            if(checkFiltriScript(script)){
                var primo = (recordPerPagina * pagina) - recordPerPagina
                var ultimo = recordPerPagina * pagina
                if(contatore >= primo && contatore < ultimo){
                    const row = [];
                    row.push(<td key={"titolo"+script.id}>{script.titolo}</td>)
                    row.push(<td key={"topic"+script.id}>{script.topic}</td>)
                    row.push(<td key={"livello"+script.id}>{script.livello}</td>)
                    row.push(<td key={"gruppo"+script.id}>{script.nomeGruppo}</td>)
                    row.push(<td key={"visibile"+script.id}>{script.visibile===true ? <FontAwesomeIcon style={{color:"green"}} icon={faCheckCircle} /> : <FontAwesomeIcon style={{color:"red"}} icon={faTimesCircle} /> }</td>)
                    row.push(<td key={"info"+script.id} role="button" onClick={()=>props.setScript(script)}><FontAwesomeIcon icon={faInfoCircle}/></td>)
                    body.push(<tr key={"row"+script.id}>{row}</tr>)
                }
                contatore++
            }
        })

        if(contatore > 0){
            return (
            <div>
                <Table striped hover bordered style={{textAlign:"center"}}>
                    <thead><tr key="header">{header}</tr></thead>
                    <tbody>{body}</tbody>
                </Table>
                <br/>
                <Pagination totaleRecord={contatore} recordPerPagina={recordPerPagina} setPagina={(pag)=>setPagina(pag)} setRecordPerPagina={(num)=>setRecordPerPagina(num)} />
            </div>
            )
        }else{
            return <Alert variant="danger">Nessuno script trovato con i parametri di ricerca inseriti</Alert>
        }

    }

    return (<div>
        {getBody()}
    </div>)
}