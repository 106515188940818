import React,{useState} from 'react'
import {Container,ProgressBar} from 'react-bootstrap'
var uploader
var response

export default function VimeoTryUpload(props){
    
    //fase upload del video => salvataggio dati su

    const [uploadLanciato,setUploadLanciato] = useState(false)
    const [percentuale,setPercentuale] = useState(0);
    const [caricamento,setCaricamento] = useState(false);
    const [caricamentoCompletato,setCaricamentoCompletato] = useState(false);
    const [errore,setErrore] = useState(null)
    const [idUltimoVideo,setIdUltimoVideo] = useState(0)

    const onError = (error) =>{
        if(errore !== null){
            setCaricamento(false);
            setCaricamentoCompletato(true);
            setErrore(true);
            setErrore(error);
        }
    }

    const onLoading = (state) =>{ 
        var totale = state.total;
        var caricato = state.loaded;
        var valore = ((caricato / totale) * 100).toFixed(0);
        if(valore !== percentuale) setPercentuale(valore)
    }

    const onComplete = (idVideo) =>{
        getVideoUrl(idVideo);
    }

    const inFile = () =>{
        setUploadLanciato(true)
        setCaricamento(true)
        //Da prendere dal context
        const client_token = "2d0e7748c8edf7768d35650cda427262";

        //elaborazione video
        //var file = document.getElementById("fileVideo").files[0]
        var file = props.fileVideo
        //se non funziona in prod nel require = ' vimeo-upload '
        var VimeoUpload = require('./npmModified/VimeoUpload');
        uploader = new VimeoUpload({
            file:file,
            token:client_token,
            name:props.titolo,
            description:props.descrizione,
            private:true,
            onProgress:onLoading,
            onError:onError,
            onComplete:onComplete
        });
        uploader.upload();
    }

    const getVideoUrl =async (idVideo) =>{
        setCaricamento(true)
        const uriVideo = '/videos/'+idVideo
        const path = 'users/127807038'+uriVideo
        //Parametri di accesso alla api da prendere dal context
        const client_id = "2b6c4a179fc818cc125001430b7cff403c8c0072";
        const client_secret = "5AENkYx0eKkL/w+cv73aMmcDPyBV3JD2XCngrqy/b8QVNdTkNs3dAJFhVz0X7rX6IODrErKi1fquxCOvJSHYu3lXJSLCRo7QTwd4jJIW8Gdgt+Wozr9y7fLh65cPTdN5"
        const client_token = "2d0e7748c8edf7768d35650cda427262";
        //Chiamata a vimeo
        var Vimeo = require('vimeo').Vimeo;
        var client = new Vimeo(client_id,client_secret,client_token);
        client.request({
            path : path
        },function(error,json){
            if(error !== null){
                console.log('errore = '+error);
            }else{
                if(json!==undefined && json!== null){
                    setIdUltimoVideo(json.link);
                    setCaricamentoCompletato(true);
                    setCaricamento(false);
                }
            }
        })
    }

    return (
    <Container>
        {caricamento ? 
        <>
            <h3 style={{textAlign:"center"}}>
                {percentuale < 100 ? 
                    <> Uploading del video in corso... </> 
                    : 
                    <>Elaborazione video in corso...</>
                }
            </h3>
            <br/>
                {percentuale < 100 ?
                    <ProgressBar now={percentuale} label={percentuale+"%"} />
                    :
                    <div>
                        <ProgressBar now={percentuale} animated={true} />
                        <br/>
                        <b>Nota</b>:L'elaborazione del video puo' richiedere diversi minuti,si prega di <b>non</b> interrompere la connessione   
                    </div>
            }
            
            <br/>      
        </>
            :
            caricamentoCompletato ? 
                !errore ? 
                    props.uploadEseguito(idUltimoVideo)
                    :
                    props.erroreUpload() 
            :
            uploadLanciato ? null : inFile() 
        }
    </Container>)
}
//Link video : <a href={idUltimoVideo}>{idUltimoVideo}</a>