import React from 'react'
import {Button,Row,Col} from 'react-bootstrap'

export default function VimeoController(props){

    const stileButton = {width:"100%"}
    
    const configButtonPlayStop = {
        variant : props.playing ? "danger" : "success" ,
        testo : props.playing ? "PAUSA" : "RIPRODUCI" ,
        onClick : () => props.funzione(),
        style : stileButton
    }

    const configButtonRestart = {
        variant : "primary",
        testo : "RIAVVIA",
        onClick : ()=>props.restart(),
        style : stileButton
    }
/*
    const configButtonRipeti = {
        variant : "info",
        testo : "RIPETI",
        onClick : ()=>props.ripeti(),
        style : stileButton
    }
*/

return(
    <div style={{marginLeft:"2.5%",width:"95%"}}>
        <Row>
            <Col>
                <Button {...configButtonPlayStop}>
                    {configButtonPlayStop.testo}
                </Button>
            </Col>
            <Col>
                <Button {...configButtonRestart}>
                    {configButtonRestart.testo}
                </Button>
            </Col>
        </Row>
    </div>
)

}