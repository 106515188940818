import axios from 'axios'

const apiConfig = {
    development:'http://localhost:8080/',
    production:'https://afasia-rest-test.cbim.it/'
};

const env =process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL});

//GESTIONE ACCESSO
const authenticate = (email,password) => {
    const url = 'api/users/login'; 
    return instance.post(url,null,{auth:{username:email,password}});
};


const getUserInfo = (jwt) =>{
    const url = 'api/users/get-user-info';
    const config = {
        "headers": {
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }
    return instance.post(url,jwt,config);
}

const modificaPassword = (richiesta) =>{
    const url = 'api/users/modifica-password'
    const config ={
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }
    return instance.post(url,richiesta,config);
}

//PARTE MEDICO
//GESTIONE PAZIENTI
const registraPaziente = (paziente)=>{
    const url = 'api/pazienti/save-paziente'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,paziente,config)
}

const getPazienti = () =>{
    const url = 'api/pazienti/get-pazienti?idPaziente=0';
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.get(url,config)
}

//GESTIONE TRAINING
const salvaTraining = (training) =>{
    const url = 'api/training/save-training'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,training,config)
}

const getTraining = (idPaziente) =>{
    const url = 'api/training/get-training'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,idPaziente,config)
}

//GETTER DELLO SCRIPT CHE DEVE ESEGUIRE IL PAZIENTE
const getScriptTraining = () =>{
    const url = 'api/training/get-script-training'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.get(url,config)
}

//GESTIONE SCHEDE DI VALUTAZIONE
const getSchede = (idPaziente) =>{
    const url = 'api/schede-di-valutazione/get-schede'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }
    return instance.post(url,idPaziente,config)
}

const salvaScheda = (scheda) =>{
    const url = 'api/schede-di-valutazione/save-scheda'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,scheda,config)
}

//GESTIONE PROTOCOLLI
const getProtocolli = () =>{
    const url = 'api/get-protocolli'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }
    return instance.get(url,config)
}

//GESTIONE SCRIPT
const salvaScript = (script) =>{
    const url = 'api/saveScript'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,script,config)
}

const aggiornaScript = (script,id) =>{
    const url = 'api/saveScript?idScript='+id;
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,script,config)
}

const getAllScript = () =>{

    const url = 'api/get-all-script'
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.get(url,config)
}

const getScript = (idScript) =>{
    const url = 'api/getScript'
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,idScript,config)

}

const assegnaScript = (assegnazione) =>{
    const url = 'api/assegna-script'
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,assegnazione,config)
}

const getStoricoScript = (idpaziente) =>{
    const url = 'api/get-storico-script'
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,idpaziente,config)
}

const getScriptPaziente = (idpaziente) =>{
    const url = 'api/get-script-paziente'
    const config = {
        "headers":{
            "Authorization":axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,idpaziente,config)
}

export default {
    authenticate,
    getUserInfo,
    modificaPassword,
    registraPaziente,
    getPazienti,
    getProtocolli,
    salvaTraining,
    getTraining,
    getScriptTraining,
    salvaScheda,
    getSchede,
    salvaScript,
    getAllScript,
    getScript,
    assegnaScript,
    getStoricoScript,
    getScriptPaziente,
    aggiornaScript
}