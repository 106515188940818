import React,{useState} from 'react'
import {Row,Col} from 'react-bootstrap'
import { faAngleDoubleLeft ,faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Pagination.css'

function PaginationFunction(props){
    const [page,setPage]=useState(1)
    const [nPerPage,setNPerPage]=useState(props.recordPerPagina)
    /*
    PROPS:
    1-totaleRecord -> numero totale degli elementi
    2-recordPerPagina -> numero di record che visualizzo per ogni pagina
    3-setPagina (function) ->funzione per settare la pagina
                             prototipo: {(numeroDellaPagina)=>setNumeroDellaPagina(numeroDellaPagina)}
    4-setNPerPage (function) ->funzione per settare il numero di elementi da visualizzare per pagina
                             prototipo: {(numeroPazientiPerPage)=>setPazientiPerPage(numeroPazientiPerpage)}
    */
const numeroPagina=()=>{
    var ultimo = Math.ceil(props.totaleRecord / props.recordPerPagina)
    if(page*props.recordPerPagina > props.totaleRecord) {
        var ultimaPagina = Math.ceil(props.totaleRecord/props.recordPerPagina)
        if(ultimaPagina!==page){
            props.setPagina(ultimaPagina)
            setPage(ultimaPagina)
        }
    }
    const body=[]
    var cont=0
    body.push(<li key="pg" className="page-item" ><button className="page-link" disabled>pagina</button></li>);
    body.push(<li key="firstPage" className="page-item">
        <button className="page-link" onClick={()=>specialePagina(1)}
        disabled={page===1?true:false} style={page===1?{backgroundColor:"#C0C0C0"}:{}}
        >
            <FontAwesomeIcon icon={faAngleDoubleLeft}/>
        </button>
        </li>);
    for(let i=1;i<=Math.ceil(props.totaleRecord / props.recordPerPagina);i++){
        cont++
        body.push(
        <li key={i} className="page-item">
            <button name={i} onClick={onClick} className="page-link" 
            style={
                cont<page-1 && cont<ultimo-2 ?{display:'none'}
                :
                    cont>page+1 && cont>3 ?{display:'none'}
                    :
                    page===cont?{textAlign:"center",backgroundColor:"#C0C0C0"}:{textAlign:"center"}}
            >   {i} </button>
        </li>);
    }

    body.push(<li key="lastPage" className="page-item">
        <button className="page-link" onClick={()=>specialePagina(Math.ceil(props.totaleRecord/props.recordPerPagina))} 
        disabled={page===ultimo?true:false} style={page===ultimo?{backgroundColor:"#C0C0C0"}:{}}>
            <FontAwesomeIcon icon={faAngleDoubleRight}/>
        </button>
        </li>);
    return body
}

const numeroElementi=()=>{
    const body=[]
    if(props.totaleRecord>=5){
        body.push(<li key="pg" className="page-item" ><button className="page-link" disabled>elementi per pagina</button></li>);
        body.push(<li key={"+3"} className="page-item">
                    <button name={"3"} onClick={cambiaNElementi} className="page-link" style={nPerPage===3?{textAlign:"center",backgroundColor:"#C0C0C0"}:{textAlign:"center"}}>3</button>
                    </li>)
        body.push(<li key={"+5"} className="page-item">
                    <button name={"5"} onClick={cambiaNElementi} className="page-link" style={nPerPage===5?{textAlign:"center",backgroundColor:"#C0C0C0"}:{textAlign:"center"}}>5</button>
                    </li>);}
    if(props.totaleRecord>=10){
        body.push(<li key={"+10"} className="page-item">
                    <button name={"10"} onClick={cambiaNElementi} className="page-link" style={nPerPage===10?{textAlign:"center",backgroundColor:"#C0C0C0"}:{textAlign:"center"}}>10</button>
                    </li>);}
    return body
}

const onClick=(event)=>{
    var int=parseInt(event.target.name)
    setPage(int)
    props.setPagina(event.target.name)
}

const specialePagina=(x)=>{
    setPage(x)
    props.setPagina(x)
}
const cambiaNElementi=(event)=>{
    var int=parseInt(event.target.name)
    setNPerPage(int)
    props.setRecordPerPagina(int)
}
    
    return(
        <div>
            <div className="pagMobile">
                <ul className="pagination">{numeroPagina()}</ul>
                <br/>
                <ul className="pagination">
                    {props.cambiaVisualizzazione === false ?null : numeroElementi()}
                </ul>
            </div>
            <Row className="pagPc">
                <Col xs="6">
                    <ul className="pagination">
                        {numeroPagina()}   
                    </ul>
                </Col>
                <Col xs="6">
                    <ul className="pagination" style={{marginLeft:'50%'}}>
                        {props.cambiaVisualizzazione === false ?null : numeroElementi()}
                    </ul>
                </Col>
            </Row>
        </div>
    )
}
export default PaginationFunction