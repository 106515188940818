import React,{useState} from 'react'
import TabellaGruppi from './TabellaGruppi/TabellaGruppi'
import { Button } from 'react-bootstrap';
import InfoGruppo from './InfoGruppo/InfoGruppo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

export default function GestioneGruppi(props){

    const [gruppoInfo,setGruppoInfo] = useState(0);

    const creaGruppo = () =>{
        var newGroup = {
            id:"new",
            idMembri:[],
            membri:[],
            nome:"",
            numeroMedici:0,
            numeroPazienti:0,
            numeroScript:0,
            numeroTerapisti:0,
            isLimbo:false
        }
        setGruppoInfo(newGroup)
    }

    return(
    <div>
        {gruppoInfo === 0 ?
            <TabellaGruppi vediInfo={(gruppo)=>setGruppoInfo(gruppo)} />
            :
            <InfoGruppo gruppo={gruppoInfo} chiudi={()=>setGruppoInfo(0)} />
        }
        <br/>
        <Button variant="success" onClick={()=>creaGruppo()} style={{width:"20%"}} >
            <b>CREA NUOVO GRUPPO&emsp;<FontAwesomeIcon icon={faPlusCircle}/></b>
        </Button>
        <br/><br/>
        <Button variant="danger" onClick={()=>props.setSceltaMenu()} style={{width:"20%",marginLeft:"80%"}}>INDIETRO</Button>
    </div>)
    
}