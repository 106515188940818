import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const AuthContext = React.createContext();

const INITIAL_STATE = { 
    isAuth: false,
    username:'',
    data:{},
    info:{},
    gruppi:null,
    credenzialiPaziente:"",
    trainingPaziente:{},
    scriptDelPaziente:{},
    storicoPaziente:{},
    schedeDiValutazione:{},
    role:null,
    protocolli:null,
    Pazienti:null,
    elencoScript:null,
    focusScript:null
  };

class AuthProvider extends Component {
  state = INITIAL_STATE;

  //GESTIONE ACCESSO 
  login = async (username, password) => {
    const { data } = await api.authenticate(username, password);
    this.setState({ 
      isAuth: true,
      data:data,
      username: data.username
    });
    axios.defaults.headers.common['Authorization'] = "Bearer " + data.jwt;
  }

  getUserInfo = async (jwt) => {
    const { data } = await api.getUserInfo(jwt);
    this.setState({
      info:data,
      role:data.role_id,
      gruppi:data.gruppi
    });
  }

  //GESTIONE PAZIENTI
  registraPaziente = async (paziente) =>{
    const { data } = await api.registraPaziente(paziente);
    this.setState({credenzialiPaziente:data});
  }

  getPazienti = async () =>{
    const { data } = await api.getPazienti();
    this.setState({Pazienti:data})
  }

  //PROTOCOLLI
  getProtocolli = async () =>{
    const { data } = await api.getProtocolli();
    this.setState({protocolli:data})
    this.impostaSessionStorage();
  }

  //GESTIONE SCHEDE DI VALUTAZIONE
  salvaScheda = async (scheda) =>{
    const { data } = await api.salvaScheda(scheda);
  }

  getSchede = async (idPaziente) =>{
    const { data } = await api.getSchede(idPaziente);
    this.setState({
      schedeDiValutazione : data
    })
  }
  
  //GESTIONE TRAINING

  getTraining = async (idpaziente) =>{
    const { data } = await api.getTraining(idpaziente);
    this.setState({
      trainingPaziente : data
    })
  }

  //GESTIONE SCRIPT
  salvaScript = async (script) =>{
    const { data } = await api.salvaScript(script)
  }

  aggiornaScript = async (script , idScript) =>{
    const { data } = await api.aggiornaScript(script,idScript)
  }

  getAllScript = async () =>{
    const { data } = await api.getAllScript();
    this.setState({
      elencoScript : data
    })
  }

  getScript = async (idScript) =>{
    const { data } = await api.getScript(idScript);
    this.setState({
      focusScript : data
    })
  }

  assegnaScript = async (assegnazione) =>{
    const { data } = await api.assegnaScript(assegnazione);
  }

  getStoricoScriptPaziente = async (idpaziente) =>{
    const { data } = await api.getStoricoScript(idpaziente);
    this.setState({
      storicoPaziente : data
    })
  }

  getScriptPaziente = async (idpaziente) =>{
    const { data } = await api.getScriptPaziente(idpaziente);
    this.setState({
      scriptDelPaziente : data
    })
  }

  //LOGOUT
  logout = async () => {
    this.setState(INITIAL_STATE);
    axios.defaults.headers.common['Authorization'] = undefined;
    sessionStorage.removeItem('authCont');
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
  }

  //Imposta old context
  refreshContext = (dati) =>{
    this.setState({...dati})
    delete axios.defaults.headers.common['Authorization'];
    axios.defaults.headers.common['Authorization'] = "Bearer " + dati.data.jwt;
    
  }

  //Imposta session storage
  impostaSessionStorage = () =>{
    var oldContext = JSON.stringify(this.state);
    sessionStorage.setItem('authCont',oldContext);
  }

  render() {
    return (
      <AuthContext.Provider
        value={{...this.state , login: this.login , logout: this.logout , getUserInfo:this.getUserInfo ,
          registraPaziente : this.registraPaziente , getPazienti:this.getPazienti ,
          getTraining:this.getTraining , aggiornaScript:this.aggiornaScript , getProtocolli:this.getProtocolli ,
          salvaScript:this.salvaScript , getAllScript:this.getAllScript , getScript:this.getScript , 
          assegnaScript:this.assegnaScript , getStoricoScriptPaziente : this.getStoricoScriptPaziente , 
          getScriptPaziente:this.getScriptPaziente , getSchede : this.getSchede , salvaScheda : this.salvaScheda,refreshContext:this.refreshContext}}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }