import React,{useState} from 'react'
import Modal from 'react-modal'
import {AuthContext} from '../../../../authentication'
import {Alert,Row,Col,Button} from 'react-bootstrap'


Modal.setAppElement('#root')
export default function ModalCreazionePaziente(props){

    const [open,setOpen] = useState(true)
    const [username,setUsername] = useState(null);
    const [password,setPassword] = useState(null);

    const customStyles = {
        content : {
          top                   : '50%',
          with                  : '100%',
          left                  : '50%',
          right                 : 'auto',
          bottom                : 'auto',
          marginRight           : '-50%',
          transform             : 'translate(-50%, -50%)'
        }
    };

    const fine = () => {
        setOpen(false);
        props.fine();
    }

    const elaboraCredenziali = () =>{
        var credenziali = AuthContext._currentValue.credenzialiPaziente.split('$');
        if(username !== credenziali[0]) setUsername(credenziali[0]);
        if(password !== credenziali[1]) setPassword(credenziali[1]);
    }


    return (
        <Modal isOpen={open} style={customStyles} >
            {username === null || password === null ? elaboraCredenziali() : 
            <div>
            <Alert variant="primary" style={{textAlign:"center"}} ><b>Credenziali del paziente</b></Alert>
            <br/>
            <Row>
                <Col xs="12" s="12" md="12">
                    <Alert variant="info">
                        Username
                    </Alert>
                </Col>
                <Col xs="12" s="12" md="12">
                    <Alert >
                        {username}
                    </Alert>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs="12" s="12" md="12">
                    <Alert variant="info">
                        Password
                    </Alert>
                </Col>
                <Col xs="12" s="12" md="12">
                    <Alert >
                        {password}
                    </Alert>
                </Col>
            </Row>
            <br/>
            <br/>
            <Button variant="danger" onClick={()=>fine()} style={{marginLeft:"70%",width:"30%"}}>
                HOME
            </Button>
            </div>
                }
 
        </Modal>
    )
}