import React,{useState} from 'react'
import Modal from 'react-modal'
import {Alert,Button} from 'react-bootstrap'
import VisualizzazioneGruppi from '../../StaticComponents/Gruppi/VisualizzazioneGruppi'
import Credenziali from '../../StaticComponents/ViewNewPassword/Credenziali'

Modal.setAppElement("#root")
export default function InfoPaziente(props){

    const [scelta,setScelta] = useState(2);


    return(
        <Modal isOpen={true} shouldCloseOnOverlayClick={false} >
            <Alert variant="success">Gestione del paziente con ID <b>{props.paziente.user.cognome}</b> </Alert>
            <Alert variant={scelta === 2 ?"warning":"primary"} role="button" onClick={()=>setScelta(2)}>{"Gestione credenziali"}</Alert>
            {scelta === 2 ? <Credenziali utente={props.paziente.user}  nominativo={" dell'utente con ID "+props.paziente.user.cognome}/> : null}
            <br/><br/>
            <Alert variant={scelta === 3 ?"warning":"primary"} role="button" onClick={()=>setScelta(3)}>{"Gruppo associato"}</Alert>
            {scelta === 3 ? <VisualizzazioneGruppi sezione={2} idGruppo={[props.paziente.idGruppo]} nominativo={props.paziente.user.cognome}/> : null }
            <br/><br/>
            <Button variant="danger" style={{width:"20%",marginLeft:"80%"}} onClick={()=>props.chiudi()} >CHIUDI</Button>
        </Modal>
    )

}