import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd,faBars } from '@fortawesome/free-solid-svg-icons';
import {Row,Col,Dropdown,Alert} from 'react-bootstrap'
import {AuthContext,AuthConsumer} from '../../authentication'
import './HeaderDottore.css'

export default function HeaderDottore(props){
    const nome=AuthContext.Consumer._currentValue.info.nominativo//"Username"

    const doLogout = async()=>{
      props.setReindirizza()
    }

    return(
        <Alert variant="primary">
          <Row>
            <Col className="Nominativo">
              <strong>
                {nome}&ensp;
                <FontAwesomeIcon icon={faUserMd} />
              </strong>
              </Col>
              <Col md="3" xs="6" lg="2">  
                <Dropdown>
                  <Dropdown.Toggle className="toggle">MENU &thinsp;<FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item role="button" onClick={()=>props.setInterfaccia(0)}  style={{fontSize:"larger"}}>
                      Home
                    </Dropdown.Item>
                    {AuthConsumer._currentValue.role === 1 ?
                      <Dropdown.Item role="button" onClick={()=>props.setInterfaccia(1)}  style={{fontSize:"larger"}}>
                        Nuovo Paziente
                      </Dropdown.Item>
                      :
                      null
                    }
                    <Dropdown.Item role="button" onClick={()=>props.setInterfaccia(2)}  style={{fontSize:"larger"}}>
                      Gestione Pazienti
                    </Dropdown.Item>
                    <Dropdown.Item role="button" onClick={()=>props.setInterfaccia(3)}  style={{fontSize:"larger"}}>
                      Libreria Script
                    </Dropdown.Item>
                    <Dropdown.Item role="button" onClick={()=>props.setInterfaccia(4)}  style={{fontSize:"larger"}}>
                      Modifica Password
                    </Dropdown.Item>
                    <Dropdown.Item role="button" onClick={()=>doLogout()}  style={{fontSize:"larger"}}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>  
      </Alert> 
    )
}