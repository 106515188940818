import React,{useState,useContext} from 'react'
import { Modal,Button,Row,Col,Container,Alert,Spinner } from 'react-bootstrap'
import { Viewer } from '../../visualizzazioneComponents';
import { AdminContext,AdminConsumer } from '../../../context/AdminContext';
export default function ConfermaDati(props){

    const buttonStyle={width:"100%"}
    const [invioRichiesta,setInvioRichiesta] = useState(null)
    //Api
    const [loading,setLoading] = useState(false);
    const [errore,setErrore] = useState(false);
    const [messaggio,setMessaggio] = useState("");
    const [apiChiamata,setApiChiamata] = useState(false)

    const {saveUser,getData} = useContext(AdminContext);

    const getRecapMedicoTerapista = () =>{
        const gruppi = []
        const utente = props.objectRecap;
        const body = [];
        const bodyGruppi = [];
        body.push(<Alert key="v5" variant="primary">Dati generali</Alert>)
        body.push(<Viewer key="v0" testo="Ruolo" valore={props.testoRuolo}/>)
        body.push(<Viewer key="v1" testo="Cognome" valore={utente.cognome}/>)
        body.push(<Viewer key="v2" testo="Nome" valore={utente.nome}/>)
        if(utente.gruppi.length > 0){
            const bodyElenco = []
            utente.gruppi.forEach(gruppo=>{
                gruppi.push(gruppo.id)
                bodyElenco.push(<li key={gruppo.id}>{gruppo.nome}</li>)
            })
            bodyGruppi.push(<p><Alert variant="primary">Elenco gruppi associati</Alert><ul>{bodyElenco}</ul></p>)
        }

        if(invioRichiesta===null) setInvioRichiesta({...utente,gruppi:gruppi})
        return <>
            {body}
            <br/>
            {bodyGruppi}
        </>

    }

    const getRecapPaziente = () =>{
        const utente = props.objectRecap
        const body=[]
        body.push(<Alert key="v-1" variant="primary">Dati generali</Alert>)
        body.push(<Viewer key="v0" testo="Ruolo" valore={props.testoRuolo}/>)
        body.push(<Viewer key="v1" testo="Protocollo" valore={props.protocolloText}/>)
        body.push(<Viewer key="v2" testo="ID Paziente" valore={utente.idPaziente}/>)
        body.push(<Viewer key="v3" testo="Sesso" valore={props.sessoText}/>)
        body.push(<Viewer key="v4" testo="Provincia di Nascita" valore={utente.nascita}/>)
        body.push(<Viewer key="v5" testo="Provincia di Residenza " valore={utente.residenza}/>)
        body.push(<Viewer key="v6" testo="Anno di nascita" valore={utente.annoNascita}/>)
        body.push(<Viewer key="v7" testo="Gruppo associato" valore={utente.gruppo.nome}/>)

        const gruppoUtente = []
        gruppoUtente.push(utente.gruppo.id)
        if(invioRichiesta===null) setInvioRichiesta({...utente,gruppi:gruppoUtente});
        
        return body
    }

    const invio =async () =>{
        setLoading(true);
        try{
           await saveUser(invioRichiesta).then(async function(){
               await getData().then(function(){
                    setErrore(false)
                    setLoading(false)
                    setApiChiamata(true)
               })
            })
        }catch(error){
            console.log(error)
            if(error.response !== null && error.response !== undefined)  setMessaggio("Errore nel tentativo di salvataggio dell'utenza :"+error.response.data)
            else setMessaggio("Errore nel tentativo di salvataggio dell'utenza.");
            setErrore(true)
            setLoading(false)
        }
    }


    const getCredenziali = () =>{
        const credenziali = AdminConsumer._currentValue.nuovoUtente
        return <div>
            <Viewer  testo="Username" valore={credenziali.username}/>
            <Viewer  testo="Password" valore={credenziali.password}/>
        </div>
    }


    const inserimentoFinito = () =>{
        if(!apiChiamata) props.setVediRecap(false)
        else props.setSceltaMenu()
    }

    return(
        <Modal size="lg" show={props.vediRecap === true? true : false} onHide={()=>props.setVediRecap(false)} backdrop="static">
            <Container>
                <div>
                    <Modal.Header>
                        <Modal.Title>
                            {loading === true?  
                                "Conferma dati inseriti"
                                :
                                errore === true?
                                    "Errore"
                                    :
                                    apiChiamata === true ?
                                        "Utenza creata con successo"
                                        :
                                        "Conferma dati inseriti"
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading === true ? 
                            <Row>
                                <Col md="1" />
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw"}} />
                                </Col>
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col>    
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col md="1" />
                            </Row>
                            :
                            errore === true ? <Alert variant="danger">{messaggio}</Alert>
                                :
                                apiChiamata === true ? getCredenziali()
                                :
                                props.ruolo === 3 || props.ruolo === 1 ? getRecapMedicoTerapista() :getRecapPaziente() }
                        <br/>
                        <hr/>
                        <br/>
                        {!loading ?
                            <Row>
                                <Col md="5" xs="5">
                                    {!errore && !apiChiamata ?
                                        <Button variant="success" onClick={()=>invio()} style={buttonStyle}>INVIO</Button>
                                    :null}
                                </Col>
                                <Col/>
                                <Col md="5" xs="2">
                                    <Button variant="danger" onClick={()=>inserimentoFinito()} style={buttonStyle}>INDIETRO</Button>
                                </Col>
                            </Row>
                            :null}
                    </Modal.Body>
                </div>
            </Container>
        </Modal>
        )
}