import React,{useState} from 'react'
import Registrazione from './RegistrazioneUtente/FormRegistrazione/FormRegistrazione'
import GestioneMedici from './GestioneMedici/TabellaMedicoComponents/GestisciMedici'
import GestionePazienti from './GestionePazienti/TabellaPazienti/GestisciPazienti'
import GestioneScript from './GestioneScript/GestioneScript'
import Header from './StaticComponents/Header'
import {Alert} from 'react-bootstrap'
import { AdminConsumer } from '../context/AdminContext'
import InfoMedico from './GestioneMedici/InfoMedicoComponents/InfoMedico'
import GestioneGruppi from './GestioneGruppi/GestioneGruppi'
import InfoPaziente from './GestionePazienti/InfoPaziente/InfoPaziente'
import InfoScript from './GestioneScript/InfoScript'

export default function Admin(){
    const [sceltaMenu,setSceltaMenu]=useState(0)
    const [logout,setLogout]=useState(false);
    const [medico,setMedico]=useState(null);
    const [paziente,setPaziente]=useState(null);
    const [script,setScript]=useState(null)

    const stileButton = {width:"50%"}

    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            <Header logout={()=>setLogout(true)}/>
            {sceltaMenu===0?
            <div style={{marginTop:"15%"}}>
                <Alert variant="info" role="button" onClick={()=>setSceltaMenu(1)} style={stileButton}>
                    <b>Registrazione Nuovo Utente</b>
                </Alert>
                <br/>
                <Alert variant="info" role="button" onClick={()=>setSceltaMenu(4)} style={stileButton}>
                    <b>Gestione Gruppi</b>
                </Alert>
                <br/>
                <Alert variant="info" role="button" onClick={()=>setSceltaMenu(3)} style={stileButton}>
                    <b>Gestione Medici</b>
                </Alert>
                <br/>
                <Alert variant="info" role="button" onClick={()=>setSceltaMenu(2)} style={stileButton}>
                    <b>Gestione Pazienti</b>
                </Alert>
                <br/>
                <Alert variant="info" role="button" onClick={()=>setSceltaMenu(5)} style={stileButton}>
                    <b>Gestione Script</b>
                </Alert>
            </div>:null}
            <div>
                {sceltaMenu===1?<Registrazione setSceltaMenu={()=>setSceltaMenu(0)}/>:null}
                {sceltaMenu===2?<GestionePazienti setSceltaMenu={()=>setSceltaMenu(0)} setPaziente={(paziente)=>setPaziente(paziente)}/>:null}
                {sceltaMenu===3?<GestioneMedici setMedico={(med)=>setMedico(med)} setSceltaMenu={()=>setSceltaMenu(0)}/>:null}
                {sceltaMenu===4?<GestioneGruppi setSceltaMenu={()=>setSceltaMenu(0)}/> : null}
                {sceltaMenu===5?<GestioneScript setSceltaMenu={()=>setSceltaMenu(0)} setScript={(script)=>setScript(script)}/> : null}
            </div>
            {medico !== null ? <InfoMedico medico={medico} chiudi={()=>setMedico(null)} /> : null}
            {paziente !== null ? <InfoPaziente paziente={paziente} chiudi={()=>setPaziente(null)} /> : null}
            {script !== null ? <InfoScript script={script} chiudi={()=>setScript(null)}/> : null}
        </div>
    )
}