import React,{useContext,useState} from 'react'
import Logo from '../../assets/CUP.png'
import {Row,Col,Dropdown,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom'
import { AuthContext } from '../../authentication';

export default function Header(){
  
  const {logout} = useContext(AuthContext)
  const [redirect,setRedirect] = useState(false)

  const doLogout = () =>{
    logout();
    setRedirect(true);
    /*do{
      var x = sessionStorage.removeItem('authCont');
      console.log(x);
    }while(x!==null)*/
  }

    return(
      redirect ? <Redirect to="/" />
      :
        <Alert style={{width:"100%",position:"relative",backgroundColor:"white"}}>
          <Row>
              <Col md="10" xs="8">
                <img src={Logo} alt="TecnoMed" style={{height:"70px",width:"60%",maxWidth:"250px",minWidth:"170px"}}/>
              </Col>
              <Col xs="4" md="2">
                <Dropdown style={{width:"100%"}}>
                    <Dropdown.Toggle id="dropdown" style={{backgroundColor:"white",color:"black",width:"100%"}}>
                        <FontAwesomeIcon icon={faBars}/>&ensp;MENU
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{width:"100%"}}>
                        <Dropdown.Item as="button" onClick={()=>doLogout()}>
                                Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>  
        </Alert>
    )
}