import React,{useState,useContext} from 'react'
import Loader from '../../../Loader/Loader'
import {Alert} from 'react-bootstrap'
import {AuthContext} from '../../../authentication/index'
export default function UpdateScript(props){

    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)
    const [inizializzato,setInizializzato]=useState(false)

    const {aggiornaScript , getAllScript} = useContext(AuthContext)

    const doUpdate = async () =>{
        setIsLoading(true)
        try{
            await aggiornaScript(props.script,props.idScript)
            .then(function(){
                doGetAllScript()    
            })
        }catch(error){
            console.log(error)
            setError(true);
            setInizializzato(true);
            setIsLoading(false)
        }
    }

    const doGetAllScript = async () =>{
        try{
            await getAllScript()
            .then(function(){
                setError(false);
                setInizializzato(true);
                setIsLoading(false);
                props.modificaEseguita();    
            })
        }catch(error){
            console.log(error)
            setError(true);
            setInizializzato(true);
            setIsLoading(false)
        }
    }

    return (
        <>{
        inizializzato === true ? null
        :
            isLoading === true ? 
                <Loader pagina="Med"/> 
                :
                error === true ?
                    <Alert variant="danger">Errore</Alert>
                    :
                    doUpdate()
        }</>
    )

}
