import React,{useState} from 'react'
import {Badge} from 'react-bootstrap'
export default function InfoSessione(props){
    const [mostraInfo,setMostraInfo]=useState(false)
    return(
    <>
        <h4><Badge variant="primary" style={{color:"white"}} role="button" onClick={()=>setMostraInfo(!mostraInfo)}>Sessione numero{props.numeroSessione}</Badge></h4>
        {mostraInfo?
        <>
        Pause registrate:{props.pause}
        <br/>
        Durata Sessione:{props.durata}
        </>:null}
        
    </>
    )
}