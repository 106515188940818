import { AdminConsumer } from '../../../context/AdminContext';

export const getObjectGroup = (gruppo) =>{
    const USERS = AdminConsumer._currentValue.utenti
    
    var output = {}
    output.id = gruppo.id;
    output.nome = gruppo.nome;
    output.numeroScript = gruppo.numeroScript;
    
    const membriId = gruppo.membri;
    const MEMBRI = [];
    var numeroMedici = 0;
    var numeroTerapisti = 0;
    var numeroPazienti = 0;
    membriId.forEach(id=>{
        USERS.forEach(utente=>{
            if(utente.user.id === id) {
                MEMBRI.push(utente.user);
                if(utente.user.ruolo === 1) numeroMedici++
                else if(utente.user.ruolo === 2) numeroPazienti++
                else if(utente.user.ruolo === 3) numeroTerapisti++
            }
        })
    })
    output.numeroMedici = numeroMedici;
    output.numeroPazienti = numeroPazienti;
    output.numeroTerapisti = numeroTerapisti;
    output.membri = MEMBRI;
    output.idMembri = gruppo.membri;
    output.isLimbo =  gruppo.limbo;

    return output
}

export const checkFiltriGruppo = (gruppo,nome,membri) =>{
    var checkNome = false;
    if(nome === "" ) checkNome = true;
    if(gruppo.nome.toUpperCase().indexOf(nome.toUpperCase()) > -1) checkNome = true;

    var checkMembri = false;
    if(membri.length === 0) checkMembri = true;
    else{
        var checkMembriPresenti = true;
        membri.forEach(membro=>{
            var membroAppartieneGruppo = false;
            gruppo.idMembri.forEach(idMembro=>{
                if(membro.user.id === idMembro) membroAppartieneGruppo = true; 
            })
            if(membroAppartieneGruppo === false) checkMembriPresenti = false
        })
        checkMembri = checkMembriPresenti
    }

    var response = false;
    if(checkNome === true && checkMembri === true) response = true
    
    return response
}

export const raggruppaMembri = (membri) =>{
    const Medici = [];
    const Terapisti = [];
    const Pazienti = [];

    membri.forEach(membro=>{
        const ruolo = membro.ruolo
        if(ruolo === 1) Medici.push(membro)
        else if(ruolo === 2) Pazienti.push(membro)
        else if(ruolo === 3) Terapisti.push(membro)
    })

    var response = {
        medici : Medici,
        terapisti : Terapisti,
        pazienti : Pazienti
    }

    return response
}

export const utenteGiaInGruppo = (idUtente,gruppo) =>{
    var presente = false
    gruppo.forEach(membro=>{
        if(membro.id === idUtente) presente = true
    })
    return presente
}

export const pazienteInAltroGruppo = (idUtente,gruppo) =>{
    var presente = false
    const GRUPPI = AdminConsumer._currentValue.gruppi;
    GRUPPI.forEach(gruppoContext=>{
        if(gruppoContext.id !== gruppo.id) {
           gruppoContext.membri.forEach(membroContext=>{
               if(membroContext === idUtente && !gruppoContext.limbo) presente = true
           })
        }
    })
    return presente
}

export const checkFiltroNominativo = (filtro,utente) =>{
    if(filtro === "") return true
    else{
        var nominativo = ""
        if(utente.ruolo === 2) nominativo = utente.cognome+"-"+utente.nome
        else nominativo = utente.cognome+" "+utente.nome
        if(nominativo.toUpperCase().indexOf(filtro.toUpperCase())>-1) return true
        else return false
    }
}
export const checkDiversitaGruppo = (gruppo,nome,membri,last) =>{
    var diversi = false;
    if(last.done===true){
        if(last.membriBase !== membri) diversi = true
        if(last.nome !== nome && nome !== "") diversi = true
    }else{
        if(membri !== null){
            //Check nome
            if(nome !== "" && nome !== gruppo.nome) diversi = true
            //Check da gruppo a membri
            gruppo.idMembri.forEach(id=>{
                var membroPresente = false
                membri.forEach(membro=>{
                    if(membro.id === id) membroPresente = true
                })
                if(!membroPresente) diversi = true
            })
            //Check da membri a gruppo
            membri.forEach(membro=>{
                var membroPresente = false
                gruppo.idMembri.forEach(id=>{
                    if(membro.id === id) membroPresente = true
                })
                if(!membroPresente) diversi = true
            })
        }
    }
    return diversi
}

export default {
    getObjectGroup,
    checkFiltriGruppo,
    raggruppaMembri,
    utenteGiaInGruppo,
    pazienteInAltroGruppo,
    checkFiltroNominativo,
    checkDiversitaGruppo
}