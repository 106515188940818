import React,{useState} from 'react'
import DatiGenerali from './DatiGenerali'
import VisualizzazioneScript from './VisualizzazioneScript'
import {Button,Alert} from 'react-bootstrap'
import Modal from 'react-modal'

Modal.setAppElement('#root')
export default function InfoScript(props){

    const [vediGenerali,setVediGenerali] = useState(true)
    const [vediVisualizzazione,setVediVisualizzazione] = useState(true);

    return (
    <Modal isOpen={true} shouldCloseOnOverlayClick={false} >
        
        <Alert variant="primary" style={{textAlign:"center"}}><b>{props.script.titolo+" "}</b>{" "+'('+props.script.nomeGruppo+')'}</Alert>
        <br/>
        <hr/>
        <Alert variant="info" role="button" onClick={()=>setVediGenerali(!vediGenerali)}>
            <b>{vediGenerali === true ? "Nascondi ":"Mostra "}Dati Generali Script</b>
        </Alert>
        {vediGenerali ? <DatiGenerali {...props} /> : null}
        <br/>
        <hr/>
        <Alert variant="info" role="button" onClick={()=>setVediVisualizzazione(!vediVisualizzazione)}>
            <b>{vediVisualizzazione === true ? "Nascondi ":"Mostra "}Gestione Visualizzazione</b>
        </Alert>
        {vediVisualizzazione ? <VisualizzazioneScript {...props}/> : null}
        <hr/>
        <br/><br/>
        <Button variant="danger" style={{width:"25%",marginLeft:"75%"}} onClick={()=>props.chiudi()}>CHIUDI</Button>
    </Modal>)
}