import React,{useState} from 'react'
import ReactPlayer from 'react-player'
import VimeoController from './VimeoController'
import './VimeoPlayer.css'

export default function VimeoPlayer(props){

    const INITIAL_TRAINING = {dateInizioTraining:0 , dateFineTraining:0,sessioni:null}
    const INITIAL_SESSION = {inizio:0,pause:0}

    const [playerObject,setPlayerObject] = useState(null);
    const [datiTraining,setDatiTraining] = useState(INITIAL_TRAINING);
    const [datiSessione,setDatiSessione] =useState(INITIAL_SESSION);
    const [numeroSessioni,setNumeroSessioni] = useState(0);
    const [fine,setFine] = useState(false);
    const [playing,setPlaying] = useState(false)

    const getVideoObject = (event) =>{
        //GESTIONE DEL VIDEO TRAMITE L'OGGETTO CREATO DAL PLAYER
        if(playerObject===null) setPlayerObject(event);
        props.setReady();
    }

    const onPlay = () =>{
        //INIZIALIZZAZIONE DATI TRAINING
        if(datiTraining.dateInizioTraining === 0) setDatiTraining({...INITIAL_TRAINING,dateInizioTraining:new Date()})
        //INIZIALIZZAZIONE DATI SESSIONE
        if(datiSessione.inizio === 0) setDatiSessione({...INITIAL_SESSION,inizio:new Date().getTime()})
    }

    const onPause = () =>{
        //PER TENERE CONTO DEL NUMERO DELLE PAUSE
        if(fine === false){
            var pauseAttutali = datiSessione.pause
            pauseAttutali++
            setDatiSessione({...datiSessione,pause:pauseAttutali});
        }
    }

    const restart = () =>{
        //PER FAR RIPARTIRE IL VIDEO
        //ELABORAZIONE SESSIONE
        const newArraySession = []
        if(datiTraining.sessioni !== null) datiTraining.sessioni.forEach(sessione=>{newArraySession.push(sessione)})
        var dataFine = new Date().getTime();
        var durataSessione = getDurataSecondi(datiSessione.inizio,dataFine)
        var sessioneReturn = {numPause:datiSessione.pause,durata:durataSessione}
        newArraySession.push(sessioneReturn);

        const newObjectTraining = {
            dateInizioTraining:datiTraining.dateInizioTraining , 
            dateFineTraining:datiTraining.dateFineTraining,
            sessioni:newArraySession
        }
        aggiornaDati(newObjectTraining)
        var nuovoNumero = numeroSessioni+1
        setNumeroSessioni(nuovoNumero);
        if(playerObject !== null) playerObject.seekTo(0);
    }

    const getDurataSecondi = (dataInizio,dataFine) =>{
        var inizio = (dataInizio / 1000).toFixed(0)
        var fine = (dataFine / 1000).toFixed(0)
        var risultato = fine - inizio
        return risultato
    }

    const aggiornaDati = (objTraining) =>{
        setDatiTraining(objTraining);
        var nuovaSessione = {
            inizio:new Date().getTime(),
            pause:0
        }
        setDatiSessione({...nuovaSessione});
    }

    const ripeti = () =>{
        //FAR TORNARE INDIETRO IL VIDEO (secondiIndietro)
        const secondiIndietro = 5
        var secondoAttuale = playerObject.getCurrentTime()
        if(secondoAttuale > secondiIndietro ){
            var nuovoSecondo = secondoAttuale - secondiIndietro;
            playerObject.seekTo(nuovoSecondo);
        }else{
            playerObject.seekTo(0);
        }
    }

    const fineTraining = () =>{
        const newArraySession = []
        if(datiTraining.sessioni !== null) datiTraining.sessioni.forEach(sessione=>newArraySession.push(sessione))
        var dataFineSessione = new Date().getTime();
        var durataSessione = getDurataSecondi(datiSessione.inizio,dataFineSessione)
        var sessioneReturn = {numPause:datiSessione.pause,durata:durataSessione}
        newArraySession.push(sessioneReturn);
        var dataFineTraining = new Date();
        var durataTraining = getDurataSecondi(datiTraining.dateInizioTraining.getTime(),dataFineTraining.getTime())
        const newObjectTraining = {
            oraInizio:datiTraining.dateInizioTraining , 
            oraFine:dataFineTraining,
            durata:durataTraining,
            elencoSessioni:newArraySession,
            idScript:props.idScript
        }
        setFine(true)
        props.fineTraining(newObjectTraining)
    }

    return(
        <div>
            <div className='embed-container'>
                <ReactPlayer url={props.url}  
                controls={false} playing={playing} 
                onPause={()=>onPause()} onPlay={()=>onPlay()} onReady={(event)=>getVideoObject(event)}
                onError={()=>props.setErrore()} loop={true} />
                <div className="preventAction" />
            </div>
            <br/>
            <VimeoController playing={playing} fine={fine} fineTraining={()=>fineTraining()} 
                funzione={()=>setPlaying(!playing)} restart={()=>restart()} ripeti={()=>ripeti()} />
        </div>
        )
}