import React,{useState} from 'react'
import {Button,Alert} from 'react-bootstrap'
import UpdateScript from '../../PayloadMenu/UpdateScript'

export default function AggiornamentoScript(props){

    const [modificaEseguita,setModificaEseguita]=useState(false)
    const [errore,setErrore]=useState(false)

    const indietro = () =>{
        if(modificaEseguita===false) props.indietro(props.inviaDati)
        else props.fine()
    }
return (
<div>
    {modificaEseguita===true?<Alert variant="success"><b>Aggiornamento dello script eseguito</b></Alert>:null}
    <UpdateScript script={props.inviaDati} idScript={props.idScript} modificaEseguita={()=>setModificaEseguita(true)} setErrore={()=>setErrore(true)}/>
    
    {errore===true || modificaEseguita===true ?
        <Button variant="danger" onClick={()=>indietro()} style={{width:"20%",marginLeft:"75%"}}>CHIUDI</Button>
        :null}
</div>)

} 