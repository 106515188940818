import React from 'react'
import {Table,Alert} from'react-bootstrap'

export default function ListaParametriEloquio(props){
    
    const getBody = () =>{
        const body = []
        var esiste = false
        if (props.schede.parametriEloquio) {
            var parametri = props.schede.parametriEloquio;
            if(parametri.numeroFrasi!==0 && parametri.numeroParole !==0 && parametri.numeroSillabe){
            var parametri = props.schede.parametriEloquio;
            body.push(<tr key="1"><td key="1-1">NUMERO FRASI</td><td key="1-2">{parametri.numeroFrasi}</td></tr>)
            body.push(<tr key="2"><td key="2-1">NUMERO PAROLE TOTALI</td><td key="2-2">{parametri.numeroParole}</td></tr>)
            var mediaParole =parseInt(parametri.numeroParole/parametri.numeroFrasi)
            body.push(<tr key="3"><td key="3-1">LUNGHEZZA MEDIA FRASI</td><td>{mediaParole}</td></tr>)
            var mediaSillabe =parseInt(parametri.numeroSillabe/parametri.numeroFrasi)
            body.push(<tr key="3"><td key="3-1">NUMERO SILLABE PER FRASE</td><td>{mediaSillabe}</td></tr>)
            esiste = true
        }
        } 
        if (esiste === true) {
            return (
                <div style={{border:"1px solid black"}}>
                    <Alert variant="info">Parametri di eloquio del paziente con ID : <b>{props.Paziente.idPaziente}</b></Alert>
                    <Table striped>
                        <tbody>
                            {body}
                        </tbody>
                    </Table>
                </div>
                )
        }else{
            return (
                <Alert variant="info">I parametri di eloquio del paziente con ID : <b>{props.Paziente.idPaziente}</b> <u>NON</u> sono stati ancora definiti</Alert>
            )
        }
    }


    return <>{getBody()}</>
}