import React,{useState,useContext} from 'react'
import Loader from '../../../Loader/Loader'
import {AuthContext} from '../../../authentication'
import {Alert} from 'react-bootstrap'

export default function PayloadUploadSchede(props){

    const [inizializzato,setInizializzato]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [errore,setError]=useState(false)

    const {getSchede,salvaScheda} = useContext(AuthContext)

    const definizione = () =>{
        setIsLoading(true)
        if(props.api === "getSchede")  doGetSchede()
        else if(props.api === "saveScheda") doSalvaScheda()
        else exitError();
    }

    const doSalvaScheda =async () =>{
        try{
            await salvaScheda(props.scheda)
            .then(function(){
                doGetSchede()
            })
        }catch(error){
            console.log(error)
            exitError()
        }
    } 

    const doGetSchede =async () =>{
        var obj = {};
        obj.idPaziente = props.idPaziente;
        try{
            await getSchede(obj)
            .then(function(){
                setError(false);
                setInizializzato(true);
                setIsLoading(false);
                if(props.initDone()) props.initDone()  
            })
        }catch(error){
            console.log(error)
            exitError()
        }
    }

    const exitError = () =>{
        setError(true);
        setInizializzato(true);
        setIsLoading(false);
    }


    return (
        <>{
        inizializzato === true ? null
        :
            isLoading === true ? 
                <Loader pagina="Med"/> 
                :
                errore === true ?
                    <Alert variant="danger">Errore</Alert>
                    :
                    definizione()
        }</>
    )
}