import React,{useState} from 'react'
import {Col,Row} from 'react-bootstrap'
import MenuDottore from '../RaccoltaCollegamenti/MenuDottore'
import LabelRicercaDottore from '../../../StaticComponents/LabelRicercaDottore'
import {AuthConsumer}from '../../../../authentication/'
import ModificaPassword from '../ModificaPassword/ModificaPassword'
import './HomePage.css'

export default function HomePage(props){
    //console.log(AuthConsumer._currentValue.data.jwt)
    return(
        <Row>
            <Col className="menu" xs="7" md="7" lg="6" >
                <MenuDottore setInterfaccia={(selezione)=>props.setInterfaccia(selezione)}/>
            </Col>
            <Col style={{marginTop:"2%"}} xs="5" md="5" lg="6">
                <LabelRicercaDottore/>
            </Col>
            {props.interfaccia === 4 ? <ModificaPassword indietro={()=>props.setInterfaccia(0)} /> : null}
        </Row>
    )
}