import React from 'react'
import {Form,Col,Row,Alert} from 'react-bootstrap'

export default function FormLabel(props){

    const stileValido=props.cl?{border:"1px solid #23ff00"}:{border:"1px solid #23ff00",width:"50%"}
    const stileNonValido=props.cl?{border:"1px solid red"}:{border:"1px solid red",width:"50%"}
    return(
        <Row>
            <Col>
                <Alert variant="info" className="AlertFormLabel">
                    <b>{props.testo}</b>
                </Alert>
            </Col>
            {props.break ? <Col xs="12" className="breaker"/> : null}
            <Col as={Alert}>
                <Form.Control 
                type={props.tipo}
                name={props.nome} 
                placeholder={props.place} 
                onChange={(event)=>props.funzione(event)} 
                value={props.valore}
                className={props.cl}
                style={props.valido?stileValido:stileNonValido}
                readOnly={props.read}
                />
            </Col>
        </Row>
    )
}