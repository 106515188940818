import React from 'react'
import { Modal,Row,Col,Alert,Container,Spinner,Button } from 'react-bootstrap'
export default function ModalInvio(props){

    const close = () =>{
        if(!props.errore) props.chiudi()
        else props.setSure();
    }

    return(
        <Modal size="lg" show={props.sure} onHide={()=>props.setSure()} backdrop="static">
                <Container>
                    {props.loading ?
                    <div>
                        <Modal.Header>
                            <Modal.Title>Caricamento...</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md="1" />
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw"}} />
                                </Col>
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col>    
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col md="1" />
                            </Row>
                        </Modal.Body>
                    </div>
                    :
                    props.errore ? 
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Errore</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="danger">Errore nella richiesta di modifica dell'utenza{props.messaggio}</Alert>
                            </Modal.Body>
                        </div>
                        :
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Modifiche salvate</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="success">Modifiche all'utente {" "+props.nominativo+" "} eseguite con successo</Alert>
                                <Button variant="danger" onClick={()=>close()}>CHIUDI</Button>
                            </Modal.Body>
                        </div>
                    }
                </Container>
            </Modal>
    )
}