import React,{useState,useContext} from 'react'
import {AuthContext} from '../../../authentication'
import {Alert,Button} from 'react-bootstrap'
import Loader from '../../../Loader/Loader'
export default function UploadAssegnazione(props){

    const [chiamataApi,setChiamataApi]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)

    const { assegnaScript , getStoricoScriptPaziente } = useContext(AuthContext);
    /*"dataAssegnazione": "2021-02-04T10:11:54.252Z",
  "idAssegnazione": 0,
  "idPaziente": 0,
  "idScript": 0 */

    const doAssegnaScript =async () =>{
        setIsLoading(true);

        var assegnazione = {};
        assegnazione.idPaziente = props.idPaziente;
        assegnazione.idScript = props.idScript;

        try{
            await assegnaScript(assegnazione)
            .then(function(){
                getterStorico();
            })
        }catch(error){
            console.log(error)
            setError(true)
            setChiamataApi(true)
            setIsLoading(false)
        }
    }

    const getterStorico=async ()=>{
        var identificatore = {};
        identificatore.idpaziente = props.idPaziente;
        try{
            await getStoricoScriptPaziente(identificatore)
            .then(function(){
                setError(false)
                setChiamataApi(true)
                setIsLoading(false)
            })
        }catch(error){
            console.log(error)
            setError(true)
            setChiamataApi(true)
            setIsLoading(false)
        }       
    }

    return (isLoading === true ?
                <Loader pagina="Med"/>
                :
                error === true ?<div>
                    <Alert variant="danger">Errore nell'invio dei dati caricamento degli script</Alert>
                    <Button variant="danger" style={{marginLeft:"75%",width:"20%"}} onClick={()=>props.fine()}>INDIETRO</Button>
                    </div>
                    :
                    chiamataApi === false ? 
                        <>{doAssegnaScript()}</>
                        :
                        <>
                            <Alert variant="success">Invio dati avvenuto con successo</Alert>
                            <Button variant="danger" style={{marginLeft:"75%",width:"20%"}} onClick={()=>props.fine()}>FINE</Button>
                        </>
    )


}