import React from 'react'
import {Col,Row,Alert} from 'react-bootstrap'
import './LoginStyle.css'
import Logo from '../assets/CUP.png'
import LogoCBIM from '../assets/cbim-logo-small.png'
import FunctionalComponent from '../Interfaccia-Login/FunctionalComponent'
export default function Login(){

    return(
            <div className="Sfondo">
                <Row className="RowHeader">
                    <Col md="8">
                        <Alert style={{marginBottom:"0px",width:"100%",position:"fixed",backgroundColor:"white"}}>
                            <img src={Logo} alt="TecnoMed" className="ImgTecno"/>
                        </Alert>
                    </Col>
                </Row>
                <div className="containerF">
                    <FunctionalComponent />
                </div>
                <div className="rowContainer">
                    <div className="ImgContainer">
                        <a href="http://www.cbim.it/" >
                            <img src={LogoCBIM} alt="C.B.I.M." className="LogoCbim"/>
                        </a>
                    </div>
                </div>
            </div>
        )
}