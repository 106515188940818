import React,{useState} from 'react'
import Modal from 'react-modal'
import {Alert,Button} from 'react-bootstrap'
import DatiGenerali from './SubMenu/DatiGenerali/DatiGenerali'
import Credenziali from '../../StaticComponents/ViewNewPassword/Credenziali'
import VisualizzazioneGruppi from '../../StaticComponents/Gruppi/VisualizzazioneGruppi'

Modal.setAppElement("#root")
export default function InfoMedico(props){

    const [scelta,setScelta] = useState(1);

    const getText = () =>{
        var testo
        if(props.medico.ruolo === 1) testo = 'medico'
        else if(props.medico.ruolo === 3) testo = 'terapista'

        return testo
    }

    return(
        <Modal isOpen={true} shouldCloseOnOverlayClick={false} >
            <Alert variant="success">Gestione {" del "+getText()+" "}<b>{props.medico.cognome+" "+ props.medico.nome}</b> </Alert>
            <Alert variant={scelta === 1 ?"warning":"primary"} role="button" onClick={()=>setScelta(1)} >{"Gestione dati"}</Alert>
            {scelta === 1 ? <DatiGenerali {...props} nominativo={props.medico.cognome+" "+ props.medico.nome} chiudi={()=>props.chiudi()}/> : null}
            <br/><br/>
            <Alert variant={scelta === 2 ?"warning":"primary"} role="button" onClick={()=>setScelta(2)}>{"Gestione credenziali"}</Alert>
            {scelta === 2 ? <Credenziali {...props} utente={props.medico} nominativo={"dell'utente "+props.medico.cognome+" "+ props.medico.nome}/> : null}
            <br/><br/>
            <Alert variant={scelta === 3 ?"warning":"primary"} role="button" onClick={()=>setScelta(3)}>{"Elenco dei gruppi"}</Alert>
            {scelta === 3 ? <VisualizzazioneGruppi sezione={1} {...props} nominativo={props.medico.cognome+" "+ props.medico.nome}/> : null }
            <br/><br/>
            <Button variant="danger" style={{width:"20%",marginLeft:"80%"}} onClick={()=>props.chiudi()} >CHIUDI</Button>
        </Modal>
    )

}
