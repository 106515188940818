import React,{useState} from 'react'
import {faPlus} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../../../components/BasicComponents/Pagination'
import {Table,Alert} from 'react-bootstrap'

export default function ListaScript(props){
    const [recordPerPagina,setRecordPerPagina]=useState(3)
    const [pagina,setPagina]=useState(1)

    function componiTabella(){
        const rows = []
        if(props.elencoScript.length===0) return <Alert variant="danger">Nessuno script trovato</Alert>
        else{
            var contatore = 0
            props.elencoScript.forEach((script)=>{
            var titoloUpper = script.titolo.toUpperCase()
            var filtroUpper = props.filtroTitolo.toUpperCase()
            if(titoloUpper.indexOf(filtroUpper) > -1 || filtroUpper===""){
            if(script.severita === props.filtroSeverita || props.filtroSeverita === 'Visualizza Tutti' || props.filtroSeverita === ""){
            if(script.topic === props.filtroTopic || props.filtroTopic === 'Visualizza Tutti' || props.filtroTopic === ""){
                var primo =recordPerPagina*pagina-recordPerPagina
                var ultimo =recordPerPagina*pagina
                //filtri
            if(contatore >=primo  && contatore<ultimo){
                //pagination
            var row = []
            row.push(<td key={script.idscript+"Titolo"}>{script.titolo}</td>)
            row.push(<td key={script.idscript+"Topic"}>{script.topic}</td>)
            row.push(<td key={script.idscript+"Severita"}>{script.severita}</td>)
            row.push(<td key={script.idscript+"Selec"} role="button" onClick={()=>props.setScriptAnteprima(script)} style={{textAlign:"center"}}><FontAwesomeIcon icon={faPlus}/></td>)
            rows.push(<tr key={script.idscript+"Row"}>{row}</tr>)
             }//pagination
             contatore++
            }//topic
            }//severita
            }//titolo
            })

        if(contatore > 0){
            return( 
                    <div>
                    <Table striped bordered hover className="tabellaScript">
                        <thead>
                            <tr key="header">
                                <th key="header-titolo">TITOLO</th>
                                <th key="header-topic">TOPIC</th>
                                <th key="header-severita">SEVERITA'</th>
                                <th key="header-anteprima" style={{textAlign:"center"}}>VEDI ANTEPRIMA</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    <Pagination recordPerPagina={recordPerPagina} setRecordPerPagina={(num)=>setRecordPerPagina(num)} 
                                totaleRecord={contatore} setPagina={(valore)=>setPagina(valore)}/>
                    </div>
                )
        }else{
            return <Alert variant="danger">Nessuno script trovato con i parametri di ricerca inseriti</Alert>
        }
        }//else elenco non filtrato .length > 0
    }

    return(
        <>
            {componiTabella()}
        </>
    )
}