import React,{useContext,useState} from 'react'
import Loader from '../../../Loader/Loader'
import {Alert} from 'react-bootstrap'
import {PazienteContext} from '../../../context/PazienteContext'

export default function InvioDati(props){

    const {salvaTraining} = useContext(PazienteContext)

    const [inLoading,setInLoading]=useState(false)
    const [errore,setErrore]=useState(false)
    const [invioRiuscito,setInvioRiuscito]=useState(false)
    const [inSalvataggio,setInSalvataggio]=useState(false)

    const doSalvaTraining =async ()=>{
        if(inSalvataggio===false){
            //console.log(props.datiTraining)
            setInSalvataggio(true)
            setInLoading(true)
            try{
                await salvaTraining(props.datiTraining)
                .then(
                    setInLoading(false),
                    setErrore(false),
                    setInvioRiuscito(true)
                )
            }catch(error){
                console.log(error)
                setInLoading(false)
                setErrore(true)
            }
        }
    }
    
    return (
        <div><br/><br/>
            {
                inLoading===true ? <><Loader pagina="Paz"/></> :
                    errore===true ? <>
                    <Alert variant="danger">Errore nell'invio dei dati registrati</Alert>
                    </> : 
                        invioRiuscito===true ? <><Alert variant="success"><b>L'invio dei dati è stato eseguito con successo.</b></Alert></> :
                            doSalvaTraining()
            }
        </div>
    )

}