import React,{useState} from 'react'
import {Row,Col,Alert,Button} from 'react-bootstrap'
import {AuthConsumer} from '../../authentication'

import TabellaTraining from '../../Interfaccia-Medico/Components-Medico/Menu/TabellaTraining/TabellaTraining'
import VediInfoSessione from '../InfoSessioni/VediInfoSessione'
import Calendario from './Calendar'
import './Style.css'

export default function ElencoDate(props){
    const [trainingInfo,setTrainingInfo]=useState(0)
    const [date,setDate]=useState(0)

    const inizializzaDate = () =>{
        var dateTraining=[]
        AuthConsumer._currentValue.trainingPaziente.forEach((train)=>{
            var giorno=new Date(train.oraInizio).getDate();
            var mese=new Date(train.oraInizio).getMonth()+1;
            var anno=new Date(train.oraInizio).getFullYear();
            var dataTraining = mese + '/'+ giorno + '/' + anno
            dateTraining.push(dataTraining);
        })
        if(date === 0) setDate(dateTraining)
    }
    
        return(
            <>
            {AuthConsumer._currentValue.trainingPaziente.length===0?
            <Alert variant="danger">Nessun training registrato</Alert>:
            <Row>
                <div className="tabellaTraining">
                    <TabellaTraining training={AuthConsumer._currentValue.trainingPaziente} setTrainingInfo={(training)=>setTrainingInfo(training)}/>
                    <br/>
                </div>
                <div className="info">
                
                    {trainingInfo===0?
                            date===0?
                                inizializzaDate() 
                                : 
                                <Calendario date={date} pagina={props.pagina}/>
                        :
                            <>
                                <VediInfoSessione TrainingScelto={trainingInfo}/>
                                <br/>
                                <Button variant="warning" onClick={()=>setTrainingInfo(0)} className="buttonInfoTraining">
                                    CHIUDI INFO TRAINING
                                </Button>
                            </>
                    }
                </div>   
            </Row>
            }
            </>
        );
}