import React,{useState} from 'react'
import {Alert,Button,Row,Col, Form} from 'react-bootstrap'
import FraseInserita from './FraseInserita'
export default function DistrattoreFrase(props){//props.posizione chiave
    const [fraseInInserimento,setFraseInInserimento]=useState("")
    const [vediDistrattori,setVediDistrattori]=useState(false)
    const [frasiInserite,setFrasiInserite]=useState(null)
    const [nSillabe,setNSillabe]=useState(1)

    const aggiornaFraseInInserimento = (valore) =>{
        setFraseInInserimento(valore)
    }

    const aggiornaSillabe = (event) =>{
        var valore = event.target.value
        var numero=parseInt(valore)
        if(numero>0 && numero<51) {
            setNSillabe(numero)
            if(valore!=="") props.aggiornaElencoSillabe(props.posizione,valore)
        }
        if(valore!=="" && (numero>50 || numero<=0))alert("Il valore "+valore+" non e' valido.\nRange di valiita' : 1 -> 50")
        if(valore==="") setNSillabe("")
    }

    const aggiungiDistrattore = () =>{
        var elenco=[]
        if(fraseInInserimento!==""){
            if(frasiInserite!==null) frasiInserite.forEach((frase)=>elenco.push(frase))
            elenco.push(fraseInInserimento)
            setFrasiInserite(elenco)
            props.aggiornaElencoDistrattori(props.posizione,elenco)
            setFraseInInserimento("")
            getFrasiInserite()
        }
    }
    
    const eliminaDistrattore = (posizione) =>{
        var newArray=[]
        var cont=1
        frasiInserite.forEach((frase)=>{
            if(cont!==posizione) newArray.push(frase)
            cont=cont+1
        })
        setFrasiInserite(newArray)
        props.aggiornaElencoDistrattori(props.posizione,newArray)
    }

    const getFrasiInserite = () =>{
        const body=[]
        let cont=0
        if(frasiInserite!==null){
            const elencoFrasi=[]
            frasiInserite.forEach((frase)=>elencoFrasi.push(frase))
            elencoFrasi.forEach((frase)=>{
                cont=cont+1
                body.push(
                    <div key={cont}>
                        <FraseInserita frase={frase} posizione={cont} elimina={(posizione)=>eliminaDistrattore(posizione)}/>
                    </div>)
            })
        }//if(frasi!==null)

        if(frasiInserite===null || frasiInserite.length<3){
            body.push(
                <Row key={"label"+props.posizione}>
                    <Col md="10">
                        <Form.Control type="text" onChange={(event)=>aggiornaFraseInInserimento(event.target.value)} value={fraseInInserimento}/>
                    </Col>
                    <Col>
                        <Button variant="success" onClick={()=>aggiungiDistrattore()}>
                            AGGIUNGI
                        </Button>
                    </Col>
                </Row>)
        }
        return <>{body}</>
    }

    return(
        <div>
           <Alert variant="info">
                <Row>
                    <Col xs="12" sm="12" md="6" lg="7" >
                        {props.frase}
                    </Col>
                    <Col xs="12" sm="6" md="3" lg="3" >
                        N Sillabe <input type="number" placeholder="Inserisci il numero di sillabe" onChange={(event)=>aggiornaSillabe(event)} value={nSillabe}/>
                    </Col>
                    <Col xs="12" sm="6" md="3" lg="2" >
                        <Button onClick={()=>setVediDistrattori(!vediDistrattori)} style={{width:"100%"}}>
                            {vediDistrattori?<>NASCONDI</>:<>VEDI</>} DISTRATTORI
                        </Button>
                    </Col>
                </Row>
            </Alert>
            {vediDistrattori?
            <>
            {getFrasiInserite()}
            <br/><br/>
            </>
            :null
            }
        </div>
    )
}