import React,{useState} from 'react'
import {Alert,Button,Form,Row,Col} from 'react-bootstrap'
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RaccoltaFrasiDistrattore from './RaccoltaFrasiDistrattore'

export default function FrasePrincipale(props){

    /*parametri da passare nelle props
      variabili : indice , distrattori , testoFrasePrincipale
      funzioni : eliminaFrase - aggiornaFrase ; eliminaDistrattore - aggiungiDistrattore ; 
    */

    const [testo,setTesto]=useState(props.frasePrincipale.frase)
    const [valido,setValido]=useState(true)
    const [numeroSillabe,setNumeroSillabe]=useState(props.frasePrincipale.n_sillabe);
    const [vediDistrattori,setVediDistrattori]=useState(false)

  const cambiaTestoPrincipale = (event) =>{
    var valore = event.target.value
    if(valore !== "" && valore !== testo){ 
      if(!valido) setValido(true)
    }else setValido(false);
    
    setTesto(valore);
    //chiamata funzione cambia frase princ
    props.modificaFrasePrincipale(props.frasePrincipale.id , valore);
  }

  const cambiaNumeroSillabe = (event) =>{
    var valore = event.target.value
    var int = parseInt(valore)
    if(int<0 || int>30) alert("Valore non valido,range di validita' : 1 -> 30")
    if(valore==="") {
      setNumeroSillabe("")
      props.modificaNumeroSillabe(props.frasePrincipale.id , -1)
    }
    else if(int>0 && int<=30) {
      setNumeroSillabe(int)
      props.modificaNumeroSillabe(props.frasePrincipale.id , int)
    }
  }

    const errore={border:"2px solid red"}
    const idoneo={}

    return <div>
    <Alert variant="info">
      <Row>
        <Col md="6" sm="8" className="first">
          <Form.Control 
            type="text" 
            style={valido===true?idoneo:errore} 
            defaultValue={props.frasePrincipale.frase} 
            onChange={(e)=>cambiaTestoPrincipale(e)} 
            />
        </Col>
        <Col md="2" sm="4" className="childFirst">
          <Form.Control 
            type="number"
            min={1}
            max={30}
            style={numeroSillabe>0&&numeroSillabe<31?idoneo:errore} 
            defaultValue={props.frasePrincipale.n_sillabe} 
            onChange={(e)=>cambiaNumeroSillabe(e)} 
            value={numeroSillabe}
            />
        </Col>
        <Col md="2" sm="6" xs="5" className="childTwo">
          <Button
            variant="danger" 
            onClick={()=>props.eliminaFrasePrincipale(props.frasePrincipale.idFrase)}>
              ELIMINA
              <FontAwesomeIcon icon={faTrash} style={{marginLeft:"2px"}}/>
          </Button>
        </Col>
        <Col md="2" sm="6" xs="7" className="childTwo">
          <Button
            onClick={()=>setVediDistrattori(!vediDistrattori)}>
              {vediDistrattori===true?<>NASCONDI DISTRATTORI</>:<>VEDI DISTRATTORI</>}
          </Button>
        </Col>
      </Row>
    </Alert>
    {vediDistrattori===true?<RaccoltaFrasiDistrattore {...props}/>:null}
    <br/>
    </div>
}