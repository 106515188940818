import React from 'react'
import {Alert, Button,Col,Row,Form,Table,Popover,OverlayTrigger} from 'react-bootstrap'
import DropDown from'../../../../components/BasicComponents/DropdownButton'
import {Topic} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import {Severita} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RaccoltaFrasiScript from '../InserimentoDistrattori/RaccoltaFrasiScript'
import ListaParametriEloquio from '../TabellaScript/ListaParametriEloquio'
import SalvataggioScript from '../AssegnazioneScript/SalvataggioScript'
import './Style.css'

export default class CreazioneNuovoScript extends React.Component{
    constructor(props){
        super(props)
        this.state={
            paziente:null,
            titolo:"",
            severitaMalattia:"",
            argomento:"",
            script:"",
            idVideo:"",
            file:null,
            nomeFileVideo:"",
            numeroFrasi:0,
            numeroParole:0,
            lunghezzaMedia:0,
            validoTitolo:true,
            validoIdVideo:true,
            validoTopic:true,
            validoSeveritaMalattia:true,
            validoScript:true,
            vediInserimentoDistrattori:false,
            elencoFrasi:null,
            salvaScript:null
        }
    }//constructor()
    
    returnHome = () =>{
        if(this.props.sezione==="GestioneAttivita"){
            this.props.chiudi()
        }else{
        this.props.setInterfaccia()
        }
    }
    
    onChange = (event) =>{
        const {id} = event.target
        const {value} = event.target
        //se e' Argomento
        if([id]=="setArgomento") {
            this.setState({argomento:[value]})
        }
        //se e' Script
        if([id]=="script"){
            if(this.state.numeroParole===0) this.setState({numeroParole:1})
            if(this.state.numeroFrasi===0) this.setState({numeroFrasi:1})
            this.setState({script:[value]})
            this.controlloCarattere(event)
        }
        if([id]=="idVideo"){
            var fileObject = document.getElementById('idVideo').files[0]
            this.setState({idVideo:[value]})
            var getName = event.target.value.split('')
            var FileName =""
            getName.forEach(char=>{
                if(char!=="\\") {
                    if(char!==":") FileName=FileName+char
                }else FileName = ""
            })
            this.setState({nomeFileVideo:FileName , file:fileObject})
        }
    }//onChange()

    onKeyDown=(event)=>{
        if (event.keyCode === 8) {
            this.setState({script:event.target.value})
            this.onChange(event)
        }
    }//onKeyDown()
    
    controlloCarattere = (event) =>{
        var newArray=[]
        var contaParole=0
        var contaFrasi=0
        const scriptInserito=[]
        const contatoreFrasi=[]
        scriptInserito.push(event.target.value)
        //contatore delle frasi
        contatoreFrasi.push(scriptInserito[0].split('\n'))
        contatoreFrasi[0].forEach(element=>{
            element=element.trim()
            if(element) {
                contaFrasi=contaFrasi+1
                newArray.push(element)
            }
        })
        //contatore delle parole
        var conta = require('wordcount')
        contaParole = conta(event.target.value)
        const numeroParoleDaAssegnare = contaParole
        const numeroFrasiDaAssegnare = contaFrasi
        this.setState({numeroParole:numeroParoleDaAssegnare})
        this.setState({numeroFrasi:numeroFrasiDaAssegnare})
        var lunghezzaMediaFrasi = contaParole/contaFrasi
        this.setState({lunghezzaMedia:lunghezzaMediaFrasi.toFixed(1)})
        this.setState({elencoFrasi:newArray})
    }//controlloCarattere()

    cambiaSeverita = (valore) =>{this.setState({severitaMalattia:valore})}
    cambiaTopic = (valore) =>{this.setState({argomento:valore})}
    componentDidMount(){ if(this.props.sezione==="GestioneAttivita") this.setState({paziente:this.props.Paziente}) }

    controlloDatiScript = () =>{
        var messaggio="Errore nell'invio.\nCampi non compilati:\n"
        if(this.state.titolo === "") {
            messaggio =messaggio+"-Titolo dello script\n"
            this.setState({validoTitolo:false})
        }else this.setState({validoTitolo:true})
        if(this.state.idVideo === "") {
            messaggio =messaggio+"-ID del video\n"
            this.setState({validoIdVideo:false})
        }else this.setState({validoIdVideo:true})
        if(this.state.argomento === "") {
            messaggio =messaggio+"-Topic\n"
            this.setState({validoTopic:false})
        }else this.setState({validoTopic:true})
        if(this.state.severitaMalattia === "") {
            messaggio =messaggio+"-Grado\n"
            this.setState({validoSeveritaMalattia:false})
        }else this.setState({validoSeveritaMalattia:true})
        if(this.state.numeroParole === 0) {
            messaggio=messaggio+"-Script\n"
            this.setState({validoScript:false})
        }else this.setState({validoScript:true})
        if(messaggio!=="Errore nell'invio.\nCampi non compilati:\n") alert(messaggio)
        else this.setState({vediInserimentoDistrattori:true})
    }//controlloDatiScript()

    invio = (distrattori,sillabe) =>{
        var script = {}
        script.titolo = this.state.titolo
        script.severita = this.state.severitaMalattia
        script.topic = this.state.argomento
        script.id_paziente = this.props.Paziente.ID
        var elencoFrasiInserite = []
        var contatore = 0
        this.state.elencoFrasi.forEach((frase)=>{
            var frasiDistrattori = distrattori[contatore]
            var fr = {}
            fr.testo = frase
            fr.sillabe = sillabe[contatore]
            var elencoDistrattori = []
            if (frasiDistrattori.length>0){
            frasiDistrattori.forEach((frDist)=>{
                    if (frDist!==""){
                        elencoDistrattori.push(frDist);
                    }
                })
            }
                elencoFrasiInserite.push(fr);
            fr.distrattori = elencoDistrattori;
            contatore=contatore + 1
        })
        script.frasi = elencoFrasiInserite
        
        //NB:---NON--- E' DA CONVERTIRE IN STRING 
        //var json = JSON.stringify(script)
        var json = script
        this.setState({salvaScript:json})

    }

    render(){
        const stileDropdown={width:"100%"}
        const stileDropdownError={width:"100%",border:"1px solid red"}
        const stileTextAreaScript={textAlign:"center"}
        const stileTextAreaScriptError={textAlign:"center",border:"1px solid red"}
        const stileLabelIdVideo={width:"100%",height:"35px",backgroundColor:"#007BFF",color:"white",textAlign:"center",borderRadius:"3px"}
        const stileLabelIdVideoError={width:"100%",height:"35px",border:"1px solid red",backgroundColor:"#007BFF",color:"white",textAlign:"center",borderRadius:"3px"}
        const stileLabelTitolo={width:"100%"}
        const stileLabelTitoloError={width:"100%",border:"1px solid red"}
        const popoverTopic = (
            <Popover id="popover-basic">
              <Popover.Content>
                <strong>Argomento principale dello script</strong>
              </Popover.Content>
            </Popover>
          );
        const popoverTesto = (
            <Popover id="popover-basic">
              <Popover.Content>
              <strong>Nota:</strong><br/>La suddivisione delle frasi avviene tramite il carattere <br/>"a capo / invio"<br/>
              </Popover.Content>
            </Popover>
          );
    
    return(
            this.state.salvaScript === null ?
            <div>
            {this.state.vediInserimentoDistrattori===true?
                <RaccoltaFrasiScript testoScript={this.state.script} modificaScript={()=>this.setState({vediInserimentoDistrattori:false})} 
                invio={(distrattori,sillabe)=>this.invio(distrattori,sillabe)} elencoFrasi={this.state.elencoFrasi}/>:
                <>
                    <br/>
                    <Row>
                        <Col s="12" md="8">
                            <Row>
                                <Col md="6" className="alertInfo">
                                    <Alert variant="info">
                                        <strong>INSERIRE IL TITOLO</strong>
                                    </Alert>
                                </Col>
                                <Col md="1"/>
                                <Col md="4">
                                    <Form.Control type="text" placeholder="inserire il titolo" id="titolo" value={this.state.titolo}
                                    onChange={(event)=>this.setState({titolo:event.target.value})} style={this.state.validoTitolo?stileLabelTitolo:stileLabelTitoloError}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="alertInfo">
                                    <Alert variant="info">
                                        <Row>
                                            <Col xs="10" s="8" md="10"><strong>INSERIRE IL TOPIC</strong></Col>
                                            <Col xs="2" s="4" md="2">
                                                <OverlayTrigger placement="right" overlay={popoverTopic}>
                                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                                <Col md="1"/>
                                <Col md="4">
                                    <DropDown testoPredefinito={this.state.argomento===""?"--- Seleziona Topic ---":this.state.argomento} opzioni={Topic} funzione={this.cambiaTopic} 
                                    stile={this.state.validoTopic?stileDropdown:stileDropdownError} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="alertInfo">
                                    <Alert variant="info" ><strong>GRADO SEVERITA' MALATTIA</strong></Alert>
                                </Col>
                                <Col md="1"/>
                                <Col md="4">
                                    <DropDown testoPredefinito={this.state.severitaMalattia===""?"--- Seleziona Grado ---":this.state.severitaMalattia} opzioni={Severita} funzione={this.cambiaSeverita} 
                                    stile={this.state.validoSeveritaMalattia?stileDropdown:stileDropdownError} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="alertInfo">
                                    <Alert variant="info"><strong>SELEZIONA VIDEO</strong></Alert>
                                </Col>
                                <Col md="1"/>
                                <Col md="4">
                                    <Form.Label htmlFor="idVideo" style={this.state.validoIdVideo?stileLabelIdVideo:stileLabelIdVideoError}>
                                        <span style={{verticalAlign:"middle"}}>
                                            {this.state.idVideo===""?<>Seleziona video</>:<>{this.state.nomeFileVideo}</>}
                                        </span>
                                    </Form.Label>
                                    <input type="file" accept="video/*" id="idVideo" placeholder="Inserisci l'id del video" style={{display:"none"}} onChange={this.onChange}/>
                                </Col>
                            </Row>        
                        </Col>
                        <Col s="8" md="4" className="tabella">
                            <ListaParametriEloquio Paziente={this.props.Paziente} schede={this.props.schede}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4" className="alertInfo">
                            <Alert variant="info">
                                <Row>
                                    <Col xs="10" s="8" md="10"><strong>INSERIMENTO SCRIPT</strong></Col>
                                    <Col xs="2" s="4" md="2">
                                    <OverlayTrigger placement="right" overlay={popoverTesto}>
                                            <FontAwesomeIcon icon={faInfoCircle}/>
                                    </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8">
                            <textarea id="script" style={this.state.validoScript?stileTextAreaScript:stileTextAreaScriptError} name="script" rows="4" 
                                className="textArea" 
                                placeholder="inserire lo script" 
                                onChange={this.onChange} 
                                onKeyDown={this.onKeyDown}
                                value={this.state.script}
                            />
                        </Col>
                        <Col md="4" className="tabella">
                        <div style={{border:"1px solid black"}}>
                            <Alert variant="info">PARAMETRI DI ELOQUIO DELLO SCRIPT</Alert>
                            <Table striped>
                                <tbody>
                                    <tr key="frasi">
                                        <td key="nomeFrasi">NUMERO FRASI</td>
                                        <td key="valoreFrasi">{this.state.numeroFrasi}</td>
                                    </tr>
                                    <tr key="parole">
                                        <td key="nomeParole">NUMERO PAROLE</td>
                                        <td key="valoreParole">{this.state.numeroParole}</td>
                                    </tr>
                                    <tr key="lunghezza">
                                        <td key="nomeLunghezza">LUNGHEZZA MEDIA FRASI</td>
                                        <td key="valoreLunghezza">{this.state.lunghezzaMedia}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <br/>
                        <Button variant="success" style={{width:"100%"}} onClick={this.controlloDatiScript}>INVIO</Button>
                        <br/><br/>
                        <Button variant="danger" onClick={this.returnHome} style={{width:"100%"}}>CHIUDI</Button>
                        </Col><br/>
                    </Row>
                </>
                }
            </div>
            :
            <SalvataggioScript file={this.state.file} script={this.state.salvaScript} idpaziente={this.props.Paziente.ID} fine={()=>this.setState({salvaScript:null})}/>
            )
}}