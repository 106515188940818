import React,{useState} from 'react'
import { Modifier } from '../../visualizzazioneComponents'
import { Alert,Button,InputGroup ,Row,Col, Form } from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'
import ConfermaDati from './ConfermaDati'

const INITIAL_STATE = {
    nome:"",
    cognome:"",
    gruppi:[]
}

export default function FormMedicoTerapista(props){

    const stileButton = {width:"20%",marginLeft:"80%"}
    const [dati,setDati]=useState(INITIAL_STATE)
    const [vediRecap,setVediRecap] = useState(false)
    const [objectRecap,setObjectRecap]=useState(null)

    const modificaCheck = (event,gruppoIN) =>{
        var rimosso = false;
        const newArray = []
        if(gruppoIN.limbo === true) newArray.push(gruppoIN)
        else{
            dati.gruppi.forEach(gruppo=>{
                if(gruppo.id === gruppoIN.id) rimosso = true
                else if(gruppo.limbo === false) newArray.push(gruppo)
            })
            if(!rimosso) newArray.push(gruppoIN);
        }
        setDati({...dati,gruppi:newArray})
    }

    const controllaCheck = (idGruppo) =>{
        var checked = false
        dati.gruppi.forEach(gruppo=>{
            if(idGruppo === gruppo.id) checked= true
        })
        return checked
    }

    const getGestioneGruppi = () =>{
        var gruppiEsiste = true;
        const body = []
        if(AdminConsumer._currentValue.gruppi !== null){
            if(AdminConsumer._currentValue.gruppi.length > 0){
                AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
                    body.push(
                    <InputGroup as={Col} md="3">
                        <InputGroup.Prepend>
                            <InputGroup.Checkbox checked={controllaCheck(gruppo.id)} onChange={(event)=>modificaCheck(event,gruppo)} />
                        </InputGroup.Prepend>
                        <Form.Control defaultValue={gruppo.nome}  readOnly={true}/>
                    </InputGroup>
                  )
                })
                gruppiEsiste = true
            }else gruppiEsiste = false
        }else gruppiEsiste = false

        if(gruppiEsiste) return <Row>{body}</Row>
        else return <Alert variant="danger">Nessun gruppo trovato</Alert>
    }

    const checkInvio = () =>{
        var errorePresente = false;
        var messaggioErrore = "Errore nei seguenti campi :\n"
        if(dati.cognome.length == 0) {
            errorePresente = true;
            messaggioErrore += "- Cognome\n"
        }
        if(dati.nome.length == 0) {
            errorePresente = true;
            messaggioErrore += "- Nome\n"
        }
        if(errorePresente) alert(messaggioErrore)
        else invio()
    }

    const invio = () =>{
        var object = {
            ...dati,
            ruolo:props.ruolo
        }
        setObjectRecap(object)
        setVediRecap(true)
    }

    return (
        <div>
            <Modifier testo="Cognome" id="cognome" valore={dati.cognome} valido={dati.cognome !== ""} funzione={(event)=>setDati({...dati,cognome:event.target.value})} place="Cognome"/>
            <Modifier testo="Nome" id="nome" valore={dati.nome} valido={dati.nome !== ""} funzione={(event)=>setDati({...dati,nome:event.target.value})} place="Nome"/>
            <br/>
            <Alert variant="primary">Associazione gruppi</Alert>
            <br/>
            {getGestioneGruppi()}
            <br/><br/>
            <Button variant="success" onClick={()=>checkInvio()} style={stileButton}>INVIO</Button>
            {vediRecap === true ?
            <ConfermaDati {...props} vediRecap={vediRecap} setVediRecap={(valore)=>setVediRecap(valore)} {...props} objectRecap={objectRecap}/>
            :null  }
        </div>

    )
}