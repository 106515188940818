import React,{useContext} from 'react'
import { Modal,Row,Col,Alert,Container,Spinner } from 'react-bootstrap'
import { AdminConsumer,AdminContext } from '../../../context/AdminContext'
export default function ViewNewPassword(props){

    const {cancellaPassword} = useContext(AdminContext);

    const close = () =>{
        cancellaPassword();
        props.setSure();
    }

    return(
        <Modal size="lg" show={props.sure} onHide={()=>props.setSure()} backdrop="static">
                <Container fluid>
                    {props.loading ?
                    <div>
                        <Modal.Header>
                            <Modal.Title>Caricamento...</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md="1" />
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw"}} />
                                </Col>
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col>    
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col md="1" />
                            </Row>
                        </Modal.Body>
                    </div>
                    :
                    props.errore ? 
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Errore</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="danger">Errore nella richiesta di modifica della password{props.messaggio}</Alert>
                            </Modal.Body>
                        </div>
                        :
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Password modificata</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="success">Modifica della password eseguita con successo.<br/>Nuova password :<b>{"  "+AdminConsumer._currentValue.nuovaPassword}</b> </Alert>
                            </Modal.Body>
                        </div>
                    }
                </Container>
            </Modal>
    )
}