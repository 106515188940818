import React,{useState} from 'react'
import {Button , Form , Row , Col} from 'react-bootstrap'
import FraseDistrattore from './FraseDistrattore'

export default function RaccoltaFrasiDistrattore(props){

    /*props: 
    variabili : elencoDistrattori[] , indice della frasePrincipale 
    funzioni: elimina distrattore , aggiungiDistrattore
    */
   const [fraseInInserimento,setFraseInInserimento]=useState("");

    const getBody = () =>{
        const body = []
        if(props.distrattori.length>0){
            var cont=0
            props.distrattori.forEach(fr => {
                body.push(<FraseDistrattore key={cont} testo={fr.frase} principale={fr.idFrase} proprio={cont} elimina={()=>props.eliminaDistrattore(fr.id)}/>)
                cont++
            });
        }

        return body
    }

    const aggiungi = () =>{
        props.aggiungiFrase(props.frasePrincipale.idFrase,fraseInInserimento);
        setFraseInInserimento("");
    }

    return (
    <>
        {getBody()}
        <div>
        <Row style={props.distrattori.length === 3 ? {display:'none'}:{}}>
            <Col xs="8" s="8" md="10">
                <Form.Control type="text" onChange={(event)=>setFraseInInserimento(event.target.value)} value={fraseInInserimento}/> 
            </Col>
            <Col xs="4" s="4" md="2">
                <Button variant="success" style={fraseInInserimento.length>0?{}:{display:'none'}} onClick={()=>aggiungi()}>
                    AGGIUNGI
                </Button>
            </Col>
        </Row>
        </div>
    </>)
}