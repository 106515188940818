import React,{useState}from 'react'

import Modal from 'react-modal'
import {Button,Alert} from 'react-bootstrap'
import CreazioneNuovoScript from '../SelezioneMenu/CreazioneNuovoScript'
import ElencoScriptAssegnabili from './ElencoScriptAssegnabili'
import ComposizioneCalendario from '../../../../components/CalendarioTraining/ComposizioneCalendario'
import InfoScript from '../InfoScript/ModalInfoScript'
import PayloadGestioneAttivita from '../../PayloadMenu/PayloadGestioneAttivita'
import UploadAssegnazione from '../../PayloadMenu/UploadAssegnazione'
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthConsumer} from '../../../../authentication'
import './PopOverAttivita.css'
Modal.setAppElement('#root')

export default function PopOverVisualizza(props){
    const[modalIsOpen,setModalIsOpen]=useState(true);
    const[inizializzato,setInizializzato]=useState(false)
    
    const[creazioneNuovoScript,setCreazioneNuovoScript]=useState(false)
    const[scriptSelezionato,setScriptSelezionato]=useState(null)
    const[ultimoScript,setUltimoScript]=useState(null)
    const[idScriptInvio,setIdScriptInvio]=useState(null)
    const[invioDati,setInvioDati]=useState(false)

    const[infoScript,setInfoScript]=useState(0)

function fine(){
    setModalIsOpen(false)
    props.funzione(true)
}

const confermaSelezione = () =>{
    setIdScriptInvio(scriptSelezionato.idScript)
   setInvioDati(true)
}


return(
    <Modal isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)} shouldCloseOnOverlayClick={false} htmlOpenClassName="MoadalContainer" >
        {invioDati === true?
        <UploadAssegnazione idPaziente={props.Paziente.ID} idScript={scriptSelezionato.idScript} fine={()=>setInvioDati(false)}/>
        :
        inizializzato===false ?
            <PayloadGestioneAttivita idpaziente={props.Paziente.ID} inizializzazioneConclusa={()=>setInizializzato(true)}/>
            :
            creazioneNuovoScript === true ?
                <CreazioneNuovoScript sezione="GestioneAttivita" schede={AuthConsumer._currentValue.schedeDiValutazione} chiudi={()=>setCreazioneNuovoScript(false)} Paziente={props.Paziente}/>:
                    infoScript!==0?
                    <InfoScript script={infoScript} fine={()=>setInfoScript(0)}/>
                    :
                    <div>
                        <FontAwesomeIcon style={{fontSize:"5%",marginLeft:"95%",color:"red"}} role="button" onClick={fine} icon={faTimesCircle}/>
                        <br/>
                        <Alert variant="success">
                            Gestione attività del paziente con id : <strong>{props.Paziente.idPaziente}</strong>
                        </Alert>
                        <ElencoScriptAssegnabili  creaScript={()=>setCreazioneNuovoScript(true)}
                        idPaziente={props.Paziente.id} nominativo={props.Paziente.idPaziente} 
                        setScriptSelezionato={(script)=>setScriptSelezionato(script)} infoScript={(script)=>setInfoScript(script)}
                        setUltimoScript={(script)=>setUltimoScript(script)}
                        />
                        {ultimoScript===null && scriptSelezionato!==null || ultimoScript!==null && ultimoScript.idScript !== scriptSelezionato.idScript?
                            <Button variant="success" onClick={confermaSelezione} className="buttonAssegna">
                                ASSEGNA
                            </Button>
                        :null}
                        <br/><br/>
                        <ComposizioneCalendario Paziente={props.Paziente}/>
                        <Button onClick={fine} variant="danger" className="buttonFine">
                            FINE
                        </Button>
                    </div>   
        }
    </Modal>
)

}