import React from 'react'
import {Col,Alert,Row} from 'react-bootstrap'
export default function RadioButtonTrueFalse(props){
    return(
        <>
        <Row>
            <Col md="4">
                <Alert variant="info">{props.testo}</Alert>
            </Col>
            <Col xs="12" className="divider"/>
            <Col md="2" style={{marginTop:"1vw"}}>
                <input type="radio" value="SI" name={props.nome} checked={props.checked} onChange={props.funzione}/>SI
            </Col>    
            <Col md="2" style={{marginTop:"1vw"}}>
                <input type="radio" value="NO" name={props.nome} checked={!props.checked} onChange={props.funzione}/>NO
            </Col>
        </Row>
        <hr/>
        </>
    )
}