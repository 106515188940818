import React from 'react'
import {Row,Col,Alert} from 'react-bootstrap'
//import component
import Dropdown from '../BasicComponents/DropdownButton'
export default function FormDropdown(props){
    return(
        <Row>
            <Col>
                <Alert variant="info">
                   <b>{props.testo}</b>
                </Alert>
            </Col>
            <Col as={Alert}>
                <Dropdown testoPredefinito={props.testoDropdown} opzioni={props.opzioni} funzione={(value)=>props.funzione(value)} cl={props.cl} stile={props.cl?{}:{width:"50%"}}/>
            </Col>
        </Row>
    )
}