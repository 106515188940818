import React,{useState} from 'react'
import {Alert,Form,Row,Col,Dropdown} from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'

export default function FiltriTabella(props){

    const [ruoloNome,setRuoloNome] = useState("Seleziona Ruolo");
    const [gruppoNome,setGruppoNome] = useState("Seleziona Gruppo");


    //Filtro Ruoli
    const getNomeRuolo = (codice) =>{
        if(codice === 1) return "Medico"
        else if(codice === 3) return "Terapista"
        else return "Ruolo non trovato"
    }

    const selezionaRuolo = (codice) =>{
        props.setRuoloId(codice);
        if(codice !== null ) setRuoloNome(getNomeRuolo(codice));
        else setRuoloNome("Seleziona Ruolo");
    }

    const getDropRuoli = () =>{
        const body=[];
        body.push(<Dropdown.Item key={0} onClick={()=>{selezionaRuolo(null)}} >Visualizza tutti</Dropdown.Item>);
        body.push(<Dropdown.Item key={1} onClick={()=>{selezionaRuolo(1)}} >Medico</Dropdown.Item>)
        body.push(<Dropdown.Item key={3} onClick={()=>{selezionaRuolo(3)}} >Terapista</Dropdown.Item>)

        return getDropBase(ruoloNome,body)
    }

    //Filtro Gruppi
    const selezionaGruppo = (id,nome) =>{
            props.setGruppoId(id);
            setGruppoNome(nome);
    }

    const getDropGruppi = () =>{
        const body=[];
        body.push(<Dropdown.Item key={0} onClick={()=>{selezionaGruppo(null,"Seleziona Gruppo")}}  >Visualizza tutti</Dropdown.Item>);
        const GRUPPI = AdminConsumer._currentValue.gruppi
        if(GRUPPI !== null){
            GRUPPI.forEach(gruppo=>{
                body.push(<Dropdown.Item key={gruppo.id} onClick={()=>{selezionaGruppo(gruppo.id,gruppo.nome)}} >{gruppo.nome}</Dropdown.Item>);
            })
        }
        return getDropBase(gruppoNome,body)
    }

    //Container dropdown
    const getDropBase = (valore,opzioni) =>{
        return(
            <Dropdown style={{width:"100%"}}>
                <Dropdown.Toggle style={{width:"100%"}}>{valore}</Dropdown.Toggle>
                <Dropdown.Menu style={{width:"100% "}}>{opzioni}</Dropdown.Menu>
            </Dropdown>
        )
    }


    return(
        <Alert variant="info">
            <Row>
                <Col>
                    <Form.Control value={props.nominativo} onChange={(event)=>props.setNominativo(event.target.value)} placeholder="Cognome e nome / Username" />
                </Col>
                <Col>
                    {getDropRuoli()}
                </Col>
                <Col>
                    {getDropGruppi()}
                </Col>
            </Row>
        </Alert>
    )
}