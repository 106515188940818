import React from 'react'
import {Row,Button,Col} from 'react-bootstrap'

export default function InfoButton(props){

    return (
        <Row className="rowIstruzione">
            <Col className="colIstruzioneButton" lg="3" md="4" s="12" xs="12">
                <Button variant={props.pulsante.colore}  className="buttonIstruzione">
                    {props.pulsante.testo}
                </Button>
            </Col>
            <Col className="colIstruzioneDescrizione"  lg="9" md="8" s="12" xs="12">
                {props.descrizione}
            </Col>
        </Row>
    )
}