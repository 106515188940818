import React,{useState,useContext} from 'react'
import Modal from 'react-modal'
import {Modifier} from '../../visualizzazioneComponents'
import Util from '../Util'
import GestoreRuolo from './GestoreRuolo'
import ConfirmComponent from '../../StaticComponents/Components/ConfirmComponent'
import AggiornaGruppoApi from './AggiornaGruppoApi'
import { Button,Alert } from 'react-bootstrap'
import { AdminContext } from '../../../context/AdminContext'

Modal.setAppElement('#root')
export default function InfoGruppo(props){

    const [refresh,setRefresh] = useState({nome:null,membriBase:null,done:false})
    const [nome,setNome] = useState(props.gruppo.nome)
    const [membri,setMembri] = useState(null)
    const [membriBase,setMembriBase] = useState(null)
    const [vediModalConfirm,setVediModalConfirm] = useState(false)
    //chiamata api
    const {getData,updateGroup} = useContext(AdminContext);
    const [sure,setSure] = useState(false)
    const [loading,setLoading] = useState(false)
    const [errore,setErrore] = useState(false)
    const [messaggioErrore,setMessaggioErrore] = useState("")

    const getElencoMembri = () =>{
        const toSort = membri === null ? props.gruppo.membri : membri
        toSort.sort((a,b)=>sortUsers(a,b));
        
        var objectOrdered = Util.raggruppaMembri(toSort);
        setMembriBase(toSort)
        setMembri(objectOrdered)
    }

    const rimuovi = (idUtente,ruolo) =>{
        const newMembriBase = []
        const newArray = []
        var oldArray = []
        if(ruolo === 1) oldArray = membri.medici;
        if(ruolo === 2) oldArray = membri.pazienti;
        if(ruolo === 3) oldArray = membri.terapisti;
        
        oldArray.forEach(membro=>{
            if(membro.id !== idUtente) newArray.push(membro)
        })

        membriBase.forEach(membro=>{
            if(membro.id !== idUtente) newMembriBase.push(membro)
        })

        var objectSave = {}
        if(ruolo === 1) objectSave = {...membri,medici:newArray}
        if(ruolo === 2) objectSave = {...membri,pazienti:newArray}
        if(ruolo === 3) objectSave = {...membri,terapisti:newArray}

        setMembriBase(newMembriBase)
        setMembri(objectSave)
    }

    const aggiungi = (utente) =>{
        const toSort = [];
        membriBase.forEach(membro=>toSort.push(membro))
        toSort.push(utente.user);
        toSort.sort((a,b)=>sortUsers(a,b));
        
        var objectOrdered = Util.raggruppaMembri(toSort);
        setMembriBase(toSort)
        setMembri(objectOrdered)
    }

    const sortUsers = (a,b) =>{
        var NominativoA = ""
        if(a.ruolo === 2 ) NominativoA = a.cognome + "-"+a.nome 
        else NominativoA = a.cognome + " "+a.nome
        NominativoA.toUpperCase();
            
        var NominativoB = ""
        if(b.ruolo === 2 ) NominativoB = b.cognome + "-"+b.nome 
        else NominativoB = b.cognome + " "+b.nome
        NominativoB.toUpperCase();
        
        if(NominativoA < NominativoB) return -1
        else return 1
    }

    const sceltaConfirm = (scelta) =>{
        if(scelta === true) {
            setSure(true)
            doChiamata()
        }
        setVediModalConfirm(false)
    }

    const doChiamata = async () =>{
        setLoading(true)
        const idMembri=[]
        membriBase.forEach(membro=>{idMembri.push(membro.id)})
        var objectUpdate = {
            idGruppo:props.gruppo.id,
            nome:nome,
            membri:idMembri
        }
        
        try{
            await updateGroup(objectUpdate).then(async function(){
                doGetData()
            })
        }catch(error){
            setLoading(false);
            setErrore(true);
            if(error.response !== undefined) setMessaggioErrore(error.response.data);
        }
    }

    const doGetData = async () =>{
        try{
            await getData().then(function(){
                var objectRefresh = {
                    nome:nome,
                    membriBase:membriBase,
                    done:true
                }
                setRefresh({...objectRefresh})
                setLoading(false)
                setErrore(false)
            })
        }catch(error){
            setLoading(false);
            setErrore(true);
            setMessaggioErrore(error.response.data);
        }
    }

    return (<Modal isOpen={true} shouldCloseOnOverlayClick={false} >
        <Alert variant="success">Gestione del gruppo <b>{props.gruppo.nome}</b></Alert>
        <Alert variant="primary">Gestione <b>dati</b></Alert>
        <Modifier testo="Nome gruppo" valore={nome} id='nome' funzione={(event)=>setNome(event.target.value)} valido={nome !== ""}/>
        <br/>
        <Alert variant="primary">Gestione <b>membri</b></Alert>
        {membri === null ? getElencoMembri() : 
        <div>
            <GestoreRuolo ruolo={1} textRuolo="Medici" utenti={membri.medici} rimuovi={(idUtente)=>rimuovi(idUtente,1)} gruppo={props.gruppo} aggiungi={(utente)=>aggiungi(utente)}/>
            <GestoreRuolo ruolo={3} textRuolo="Terapisti" utenti={membri.terapisti} rimuovi={(idUtente)=>rimuovi(idUtente,3)} gruppo={props.gruppo} aggiungi={(utente)=>aggiungi(utente)}/>
            <GestoreRuolo ruolo={2} textRuolo="Pazienti" utenti={membri.pazienti} rimuovi={(idUtente)=>rimuovi(idUtente,2)} gruppo={props.gruppo} aggiungi={(utente)=>aggiungi(utente)}/>
        </div>
        }
        {Util.checkDiversitaGruppo(props.gruppo,nome,membriBase,refresh) ? 
            <div>
                <br/>
                <Button variant="success" onClick={()=>setVediModalConfirm(true)} style={{width:"20%",marginLeft:"80%"}}>INVIO</Button> 
                <br/><br/>
            </div>
            : null}
        <ConfirmComponent vediModalConfirm={vediModalConfirm} setVediModalConfirm={(valore)=>setVediModalConfirm(valore)} 
        nominativo={props.gruppo.nome} sceltaConfirm={(value)=>sceltaConfirm(value)} messaggio="Sicuro di voler salvare le modifiche al gruppo "/>
        <AggiornaGruppoApi sure={sure} setSure={()=>setSure(false)} loading={loading} tipo={props.gruppo.id==="new"?1:0} errore={errore} messaggioErrore={messaggioErrore}/>
        <Button variant="danger" style={{width:"20%",marginLeft:"80%"}} onClick={()=>props.chiudi()}>INDIETRO</Button>
    </Modal>)
}
