import axios from 'axios'

const apiConfig = {
    development:'http://localhost:8080/',
    production:'https://afasia-rest-test.cbim.it/'
};

const env =process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL});


//GETTER UTENZE
const getDati = () =>{
    const url = 'api/admin/get-data'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.get(url,config)
}

//RESET PASSWORD
const resetPassword = (idUtente) =>{
    const url = 'api/admin/reset-password'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,idUtente,config)
}

//CREATE USER
const saveUser =async (user) =>{
    const url = 'api/admin/save-user'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,user,config)
}

//UPDATE USER
const updateUser =async (user) =>{
    const url = 'api/admin/update-user'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,user,config)
}

//UPDATE GRUPPO
const updateGroup =async (richiesta) =>{
    const url = 'api/admin/update-group'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,richiesta,config)
}

//MODIFICA VISUALIZZAZIONE SCRIPT GRUPPO
const updateVisualizzazione = async (richiesta) =>{
    const url = 'api/admin/update-script-access'
    const config = {
        "headers":{
            "Authorization": axios.defaults.headers.common['Authorization']
        }
    }

    return instance.post(url,richiesta,config)
}

export default {
    getDati,
    resetPassword,
    saveUser,
    updateUser,
    updateGroup,
    updateVisualizzazione
}