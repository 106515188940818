import React,{useState} from 'react'
import { Alert,ListGroup,Row,Col,Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TabellaAggiungi from './TabellaAggiungi';

export default function GestoreRuolo(props){

    const [vedi,setVedi] = useState(true);
    const [mostraTabellaAggiungi,setMostraTabellaAggiungi] = useState(false)

    const getBody = () =>{
        const body = []
        props.utenti.forEach(utente=>{
            var nominativo = props.ruolo === 2 ? utente.cognome + "-" + utente.nome : utente.cognome + " " + utente.nome
            body.push(
            <ListGroup.Item key={utente.id}>
                <Row>
                    <Col>{nominativo}</Col>
                    <Col md="2" lg="2" xs="4">
                        {props.gruppo.isLimbo === false ?
                        <Button variant="danger" style={{width:"100%"}} onClick={()=>props.rimuovi(utente.id)}>RIMUOVI</Button>
                        : null}
                    </Col>
                </Row>
            </ListGroup.Item>)
        })

        return <div>
            {body}
            <br/>
            {props.gruppo.isLimbo === false ?
            <div style={{textAlign:"center"}}>
                <Button variant={mostraTabellaAggiungi ? "danger" :"success"} style={{color:"white"}} onClick={()=>setMostraTabellaAggiungi(!mostraTabellaAggiungi)}>
                    <b>{mostraTabellaAggiungi ? "CHIUDI" : "AGGIUNGI"}&emsp;<FontAwesomeIcon icon={mostraTabellaAggiungi ? faTimesCircle : faPlusCircle}/></b>
                </Button>
            </div>
            :
            null
            }
            </div>
    }

    return (<div>
        <Alert variant="info" role="button" onClick={()=>setVedi(!vedi)}><b>{vedi===true ? "Nascondi " : "Mostra " }{props.textRuolo}</b></Alert>
        {vedi === true ? getBody() : null}
        {vedi === true && mostraTabellaAggiungi === true ? <TabellaAggiungi {...props}/>: null}
        <br/><br/>
    </div>)

}