import React from 'react'
import { AdminConsumer } from '../../../context/AdminContext'
import GruppoViewer from './GruppoViewer';

export default function VisualizzazioneGruppi(props){

    const estraiUtentiGruppo = (membri) =>{
        const UTENTI = AdminConsumer._currentValue.utenti;
        const utenzaMembri = []
        membri.forEach(membro=>{
            UTENTI.forEach(utente=>{
                if(membro === utente.user.id) utenzaMembri.push(utente)
            })
        })
        
        return utenzaMembri
    }

    const estraiGruppo = (idGruppo) =>{
        var gruppoEstratto
        const GRUPPI = AdminConsumer._currentValue.gruppi;
        GRUPPI.forEach(gruppo=>{
            if(gruppo.id === idGruppo) gruppoEstratto = gruppo
        })
        return gruppoEstratto
    }

    const getBody = () =>{
        const body = []
        const idGruppi = props.sezione === 1 ? props.medico.idGruppi : props.idGruppo;
        idGruppi.forEach(id=>{
            var infoGruppo = estraiGruppo(id);
            const membriGruppo = infoGruppo.membri
            var infoUtenti = estraiUtentiGruppo(membriGruppo);
            body.push(<GruppoViewer key={'gruppo'+id} gruppo={infoGruppo} utenti={infoUtenti} />)
        })

        return body
    }

    return getBody()
}