import React,{useState} from 'react'
import { Button,Card } from 'react-bootstrap'

export default function GruppoViewer(props){
    const [vediGruppo,setVediGruppo] = useState(false);

    const getBody = () =>{
        const MEDICI = []
        const TERAPISTI = []
        const PAZIENTI = []
        props.utenti.forEach(utente=>{
            if(utente.user.ruolo === 1) MEDICI.push(utente)
            else if(utente.user.ruolo === 3) TERAPISTI.push(utente)
            else PAZIENTI.push(utente)
        })

        return (<Card.Text>
            <ul>
                <li key="numeroMedici">Numero Medici : {MEDICI.length}</li>
                <li key="numeroTerapisti">Numero Terapisti : {TERAPISTI.length}</li>
                <li key="numeroPazienti">Numero Pazienti : {PAZIENTI.length}</li>
                <li key="numeroScript">Numero script creati : {props.gruppo.numeroScript}</li>
            </ul>
        </Card.Text>)
    }

    return (
        <div>
            <Card style={{width:"50%"}}>
                <Card.Header>
                    <Button variant="info" style={{width:"100%"}} onClick={()=>setVediGruppo(!vediGruppo)}>Gruppo <b>{props.gruppo.nome}</b></Button>
                </Card.Header>
                {vediGruppo ? getBody() : null}
            </Card>
            <br/>
        </div>
    )

}