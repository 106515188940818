import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Alert,Row,Col } from 'react-bootstrap'
import './CollegamentiMenu.css'

export default function CollegamentiMenu(props){
    return(
        <Alert
        variant="primary"
        role="button"
        style={{width:"100%",height:"3.5%",textAlign:"left"}}
    >
    <Row role="button" onClick={()=>props.setInterfaccia()}>
        <Col xs="12" s="10" md="10" lg="10" >
            <h4>{props.testo}</h4>
        </Col>
        <Col s="2" md="2" lg="2" className="icon">
            <strong style={{textAlign:"end"}}>
                <h4><FontAwesomeIcon icon={faAngleRight}/></h4>
            </strong>
        </Col>
    </Row>
    </Alert>
    )
}