import React,{useState} from 'react'
import { Table,Alert,Form } from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Util from '../Util'
import Pagination from '../../../components/BasicComponents/Pagination'

export default function TabellaAggiungi(props){
    const [filtroNome,setFiltroNome] = useState("");
    const [mostraFiltro,setMostraFiltro] = useState(true)
    //Pagination
    const recordPerPagina = 5
    const [pagina,setPagina] = useState(1);

    const getBody = () =>{
        const header = []
        if(props.ruolo === 2) header.push(<th key={"nominativo"}>Nominativo</th>)
        else {
            header.push(<th key={"cognome"}>Cognome</th>)
            header.push(<th key={"nome"}>Nome</th>)
        }
        header.push(<th key={"info"}>Info</th>)
        header.push(<th key={"seleziona"}>Seleziona</th>)
        
        const body = []
        var contatore = 0;
        var bloccatiFiltro = 0;
        AdminConsumer._currentValue.utenti.forEach(utente=>{
            if(utente.user.ruolo === props.ruolo ){
                var verificaPazienteAltroGruppo = utente.user.ruolo === 2 ? Util.pazienteInAltroGruppo(utente.user.id,props.gruppo) : false
                if(!verificaPazienteAltroGruppo){
                    if(!Util.utenteGiaInGruppo(utente.user.id,props.utenti,props.ruolo)){
                        if(Util.checkFiltroNominativo(filtroNome,utente.user)){
                            var primo = pagina * recordPerPagina - recordPerPagina;
                            var ultimo = pagina * recordPerPagina
                            if(contatore >= primo && contatore < ultimo){
                                const row = []
                                if(props.ruolo === 2) row.push(<td key={"nominativo"+utente.user.id}>{utente.user.cognome+"-"+utente.user.nome}</td>)
                                else {
                                    row.push(<td key={"cognome"+utente.user.id}>{utente.user.cognome}</td>)
                                    row.push(<td key={"nome"+utente.user.id}>{utente.user.nome}</td>)
                                }
                                row.push(<td key={"info"+utente.user.id} role="button" onClick={()=>props.aggiungi(utente)}><FontAwesomeIcon icon={faInfoCircle} /></td>)
                                row.push(<td key={"seleziona"+utente.user.id} role="button" onClick={()=>props.aggiungi(utente)}><FontAwesomeIcon icon={faPlusCircle} /></td>)

                                body.push(<tr key={"row"+utente.user.id}>{row}</tr>)
                            }
                            contatore++;
                        }else bloccatiFiltro++
                    }
                }
            }
        })

        if(contatore === 0 && bloccatiFiltro === 0 && mostraFiltro === true) setMostraFiltro(false)
        if(contatore > 0 && mostraFiltro === false) setMostraFiltro(true)
        return (
        contatore > 0 ?
        <div>
            <Table bordered striped hover style={{textAlign:"center"}}>
                <thead>
                    <tr key="header">{header}</tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
            </Table>
            <Pagination totaleRecord={contatore} pagina={pagina} setPagina={(numero)=>setPagina(numero)} recordPerPagina={recordPerPagina} cambiaVisualizzazione={false}/>
        </div>
        :
        bloccatiFiltro === 0 ?
        <Alert variant="danger">{props.ruolo === 2 ? "Tutti i pazienti sono gia' stati assegnati ad un gruppo." : props.ruolo === 1 ? "Nessun Medico trovato." : "Nessun Terapista trovato."}</Alert>
        :
        <Alert variant="danger">{props.ruolo === 1 ? "Nessun Medico " : props.ruolo === 2 ? "Nessun Paziente " : "Nessun Terapista "}trovato con i parametri di ricerca inseriti</Alert>
        )
    }

    return(
    <div>
        <br/>
        <Alert variant="success">Seleziona i {" "+props.textRuolo+" "} che si vogliono aggiungere al gruppo {" "+props.gruppo.nome}</Alert>
        {mostraFiltro === true ?
            <>
                <br/>
                <Form.Control type="text" onChange={(event)=>setFiltroNome(event.target.value)} placeholder="Cerca" style={{width:"50%"}}/>
            </>
            :
            null
        }
        <br/>
        {getBody()}
    </div>)

}