import { Alert, Form ,Button } from 'react-bootstrap'
import React,{useState,useContext} from 'react'
import { PasswordContext } from '../../../context/PasswordContext'
import Modal from 'react-modal'
import Loader from '../../../Loader/Loader'

Modal.setAppElement('#root')
export default function ModificaPassword(props){

    const [vecchiaPassword,setVecchiaPassword] = useState("");
    const [nuovaPassword,setNuovaPassword] = useState("");
    const [repeatPassword,setRepeatPassword] = useState("");
    const [loading,setLoading] = useState(false);
    const [errore,setErrore] = useState(false);
    const [passwordModificata,setPasswordModificata] = useState(false)
    const [messaggioErrore,setMessaggioErrore] = useState("")
    

    const {modificaPassword} = useContext(PasswordContext)

    const bordoErrore = {border:"1px solid red"}
    const bordoValido = {border:"1px solid green"}
    const stileButton = {width:"max(30%,150px)"}

    const checkOld = () =>{
        if(vecchiaPassword === "") return false
        else return true
    }

    const checkNuova = () =>{
        if(nuovaPassword === "") return false
        else if(nuovaPassword.length < 8) return false
        else return true
    }

    const checkRepeat = () =>{
        if(repeatPassword === "" || repeatPassword !== nuovaPassword || nuovaPassword.length < 8) return false
        else return true
    }

    const invio = () =>{
        var validoOld = checkOld()
        if(!validoOld) alert("Attenzione : \nPer procedere alla richiesta di modifica della password occorre inserire la password attualmente in uso")
        else{
            if(nuovaPassword === "")  alert("Attenzione : \nPer procedere alla richiesta di modifica della password occorre compilare il campo 'Nuova password'")
            else if(nuovaPassword.length < 8) alert("Attenzione : \nLa nuova password deve essere composta da almeno 8 caratteri")
            else if(nuovaPassword === vecchiaPassword) alert("Attenzione : \nI campi 'Nuova password' e 'Password attualmente in uso' devono essere diversi")
            else{
                if(repeatPassword === "")  alert("Attenzione : \nPer procedere alla richiesta di modifica della password occorre compilare il campo 'Reinserisci la nuova password'")
                else if(repeatPassword !== nuovaPassword) alert("Attenzione : \nI campi 'Nuova password' e 'Reinserisci la nuova password' devono essere uguali")
                else{
                    var object = {
                        vecchiaPassword : vecchiaPassword,
                        nuovaPassword : nuovaPassword
                    }
                    doModificaPassword(object)
                }
            }
        }
    }

    const doModificaPassword =async (richiesta) =>{
        setLoading(true)
        try{
            await modificaPassword(richiesta).then(function(){
                setErrore(false);
                setLoading(false);
                setPasswordModificata(true);
            })
        }catch(error){
            setErrore(true)
            setLoading(false);
            setPasswordModificata(false);
            setMessaggioErrore(error.response.data)
        }
    }

    return(
        <Modal isOpen={true} shouldCloseOnOverlayClick={false} >
            <div style={{position:"relative",zIndex:"2"}} >
                {
                loading ? <Loader pagina="Paz" />
                    :
                    errore ? <Alert variant="danger">Errore nel processo di modifica della password:
                    <b>{messaggioErrore}</b></Alert>
                    :
                    passwordModificata ? <Alert variant="success">La password è stata modificata con successo</Alert>
                    :
                    <div>
                        <Alert variant="primary">
                            <b>Modifica password</b>
                        </Alert>
                        <br/>
                        <Form>
                            <Alert variant="info">
                                Inserisci la password <b>attualmente</b> in uso
                            </Alert>
                            <Form.Control type="password"  placeholder="Password attualmente in uso" value={vecchiaPassword} onChange={(event)=>setVecchiaPassword(event.target.value)} style={checkOld()?bordoValido:bordoErrore} />
                            <br/>
                            <Alert variant="info">
                                Inserisci una <b>nuova</b> password
                            </Alert>
                            <Form.Control type="password"  placeholder="Nuova password"  value={nuovaPassword} onChange={(event)=>setNuovaPassword(event.target.value)} style={checkNuova()?bordoValido:bordoErrore} />
                            <br/>
                            <Alert variant="info">
                                Reinserisci la <b>nuova</b> password
                            </Alert>
                            <Form.Control type="password"  placeholder="Reinserisci la nuova password"  value={repeatPassword} onChange={(event)=>setRepeatPassword(event.target.value)} style={checkRepeat()?bordoValido:bordoErrore} />
                            <br/><br/>
                            <div style={{display:"flex", justifyContent:"flex-end", width:"100%", padding:"0"}}>
                            <Button variant="success" style={stileButton} onClick={()=>invio()}>INVIO</Button>
                            </div>
                        </Form>
                    </div>
                }
                <br/><br/>
                {!loading ? <div style={{display:"flex", justifyContent:"flex-end", width:"100%", padding:"0"}}>
                    <Button variant="danger" style={stileButton} onClick={()=>props.indietro()}>INDIETRO</Button> 
                    </div> : null}
            </div>
        </Modal>
    )

}