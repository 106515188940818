import React,{useState} from 'react'
import {Table,Alert} from 'react-bootstrap'
import Pagination from '../../../components/BasicComponents/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import FiltriTabella from './FiltriTabella'

export default function TabellaPazienti(props){

    const [filtroId,setFiltroId] = useState("");
    const [filtroGruppo,setFiltroGruppo] = useState(null);
    //pagination
    const [pagina,setPagina]=useState(1);
    const [recordPerPagina,setRecordPerPagina] = useState(5);


    const getTabellaPazienti=()=>{
        
        //Header
        const header = []
        header.push(<th key={"idPazienteHeader"}>ID Paziente</th>);
        header.push(<th key={"usernameHeader"}>Username</th>);
        header.push(<th key={"gruppoHeader"}>Gruppo</th>);
        header.push(<th key={"infoHeader"}>Info</th>);

        //Body
        const PAZIENTI = props.pazienti;
        const rows = [];
        var contatore = 0;
        PAZIENTI.forEach(paziente=>{
            if((filtroId === "" || paziente.user.cognome.toUpperCase().indexOf(filtroId.toUpperCase()) > -1) && (filtroGruppo === null || paziente.gruppoPaziente.id === filtroGruppo )){
                var primo = (recordPerPagina * pagina) - recordPerPagina;
                var ultimo = pagina * recordPerPagina;
                if(contatore >= primo && contatore < ultimo){
                    const row = [];
                    row.push(<td key={"idPaziente"+paziente.datiPaziente.id}>{paziente.datiPaziente.idPaziente}</td>);
                    row.push(<td key={"username"+paziente.datiPaziente.id}>{paziente.user.username}</td>);
                    row.push(<td key={"gruppo"+paziente.datiPaziente.id}>{paziente.gruppoPaziente.nome}</td>);
                    row.push(<td key={"info"+paziente.datiPaziente.id} role="button" onClick={()=>props.setPaziente(paziente)}><FontAwesomeIcon icon={faInfoCircle} /></td>);

                    rows.push(<tr key={"row"+paziente.datiPaziente.id}>{row}</tr>)
                }
                contatore++;
            }
        })
        return contatore === 0 ? 
            filtroGruppo === "" && filtroId === "" ?
                <Alert variant="danger">Nessun paziente presente tra le utenze del centro</Alert>
                :
                <Alert variant="danger">Nessun utente trovato con i parametri di ricerca inseriti</Alert>
        :
        <>
            <Table striped hover bordered style={{textAlign:"center"}}>
                <thead>
                    <tr key="headerRow">{header}</tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
            <Pagination pagina={pagina} setPagina={(numero)=>setPagina(numero)} cambiaVisualizzazione={true} totaleRecord={contatore} recordPerPagina={recordPerPagina} setRecordPerPagina={(numero)=>setRecordPerPagina(numero)}/>
        </>
    }

    return <>
        {props.pazienti!== null && props.pazienti!== undefined ?
            props.pazienti.length > 0 ?
            <div>
                <FiltriTabella filtroId={filtroId} setFiltroId={(valore)=>setFiltroId(valore)} filtroGruppo={filtroGruppo} setFiltroGruppo={(valore)=>setFiltroGruppo(valore)} />
                <br/>
                {getTabellaPazienti()}
            </div>
            :
            null
        :<Alert variant="danger">Nessun paziente presente tra le utenze del centro</Alert>
        }
    </>
}