import React, { Component } from 'react';
import axios from 'axios';

import apiAdmin from '../apiAdmin'

const AdminContext = React.createContext();
//  Admin-CBIM-1
//  4DM1N.4F4S14
const INITIAL_STATE = {
    data:null,
    isAuth:false,
    gruppi:null,
    utenti:null,
    protocolli:null,
    nuovaPassword:null,
    nuovoUtente:null
}

class AdminProvider extends Component {
    state = INITIAL_STATE;
      
    resetState = () =>{
        this.setState({...INITIAL_STATE});
    }

    initState = (auth,data) =>{
        this.setState({isAuth:auth,data:data})
    }

    //Get data
    getData =async () =>{
        const {data} = await apiAdmin.getDati();
        if(data !== undefined){
            this.setState({
                gruppi : data.gruppi,
                utenti : data.utenti,
                protocolli : data.protocolli
            })
        }
    }

    //Reset password

    resetPassword =async (idUtente) =>{
        const {data} = await apiAdmin.resetPassword(idUtente);
        this.setState({nuovaPassword:data.nuovaPassword})
    }

    cancellaPassword = () =>{
        this.setState({nuovaPassword:null})
    }

    //Save user
    saveUser = async (user) =>{
        const {data} = await apiAdmin.saveUser(user)
        this.setState({nuovoUtente:data})
    }

    //Update user

    updateUser =async (user) =>{
        await apiAdmin.updateUser(user)
    } 

    //Update group

    updateGroup =async (richiesta) =>{
        await apiAdmin.updateGroup(richiesta)
    }

    //Update accesso script

    updateVisualizzazione = async (richiesta) =>{
        await apiAdmin.updateVisualizzazione(richiesta)
    }

  render(){
      return(
      <AdminContext.Provider value={{...this.state,resetState:this.resetState,initState:this.initState,getData:this.getData,
        resetPassword:this.resetPassword , cancellaPassword:this.cancellaPassword,
        saveUser:this.saveUser,updateUser:this.updateUser,
        updateGroup:this.updateGroup,updateVisualizzazione:this.updateVisualizzazione
      }}>
          {this.props.children}
      </AdminContext.Provider>
      )
  }

}

const AdminConsumer = AdminContext.Consumer
export { AdminProvider, AdminConsumer, AdminContext }