import React from 'react'
import {Popover,OverlayTrigger} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import './Style.css'
import VimeoComponent from '../VimeoComponents/Player/VimeoComponent'
import { PazienteConsumer} from '../../context/PazienteContext'
//import file json

export default function Attivita(props){

function assemblaTraining(){
const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <b>Vedi la guida per l'utilizzo</b>
      </Popover.Content>
    </Popover>
  );

    return(
        <>
           
            <div style={{marginRight:"10px",marginLeft:"10px"}}>
            <VimeoComponent url={PazienteConsumer._currentValue.scriptTraining.id_video} {...props} />
            </div>
            <p style={{marginTop:"25px"}}> 
                <h4 style={{marginLeft:"20px"}}>
                    <OverlayTrigger placement="right" overlay={popover}>
                        <span role="button" onClick={()=>props.setVediInfo(true)}>
                            GUIDA &nbsp;<FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                    </OverlayTrigger>
                </h4>
            </p>
        </>
    )
}
    return <>{assemblaTraining()}</>
}

/*
<YTVideo videoId={idVideo} setFineAttivita={props.setFineAttivita} setMessaggio={props.setMessaggio} 
    setDatiTraining={(dati)=>props.setDatiTraining(dati)} setAudio={(audio)=>setAudio(audio)} idScript={props.idScript}/>
*/