import React,{useState,useContext} from 'react'
import { Viewer } from '../visualizzazioneComponents'
import { Button } from 'react-bootstrap'
import ConfirmComponent from '../StaticComponents/Components/ConfirmComponent';
import { AdminContext } from '../../context/AdminContext';
import ViewStatoApi from './ViewStatoApi';

export default function VisualizzazioneScript(props){
    const [vediModalConfirm,setVediModalConfirm] = useState(false);
    const [sure,setSure] = useState(false);
    //state chiaata api
    const [loading,setLoading] = useState(false);
    const [errore,setErrore]=useState(false);
    const [messaggioErrore,setMessaggioErrore] = useState("")

    const {updateVisualizzazione,getData} = useContext(AdminContext)

    const invio = () =>{
        setVediModalConfirm(true)
    }

    const sceltaConfirm = (valore) =>{
        setVediModalConfirm(false);
        if(valore === true) {
            setSure(true)
            doModificaVisibilita();
        }
    }

    const doModificaVisibilita = async () =>{
        setErrore(false);
        setMessaggioErrore("");
        setLoading(true);
        var richiesta = {
            idScript:props.script.id,
            idGruppo:props.script.idGruppo
        }
        try{
            await updateVisualizzazione(richiesta).then(async function(){ doGetData(); })
        }catch(error){
            if(error.response !== null && error.response !== undefined) setMessaggioErrore(error.response.data);
            setLoading(false);
            setErrore(true);
        }
    }

    const doGetData = async () =>{
        try{
            await getData().then(function(){
                setLoading(false);
                setErrore(false);
                props.chiudi();
            })
        }catch(error){
            if(error.response !== null && error.response !== undefined) setMessaggioErrore(error.response.data);
            setLoading(false);
            setErrore(true);
        }
    }

    return (
        <div>
            <Viewer testo="Gruppo associato" valore={props.script.nomeGruppo} />
            <Viewer testo="Stato script" valore={props.script.visibile === true ? "Disponibile" : "Nascosto"} />
            <br/>
            <Button variant={props.script.visibile === true ? "danger" : "success"} style={{width:"25%",marginLeft:"75%"}} onClick={()=>invio()}>
                {props.script.visibile === true ? "NASCONDI SCRIPT" : "RENDI DISPONIBILE SCRIPT"}
            </Button>
            <br/><br/>
            <ConfirmComponent vediModalConfirm={vediModalConfirm} setVediModalConfirm={(valore)=>setVediModalConfirm(valore)} 
            nominativo={'" '+props.script.titolo+' "'} sceltaConfirm={(value)=>sceltaConfirm(value)}  
            messaggio={props.script.visibile === true ? "Sicuro di voler nascondere lo script ":"Sicuro di voler rendere disponibile lo script "} />
            <ViewStatoApi sure={sure} setSure={()=>setSure(false)} loading={loading} errore={errore} messaggioErrore={messaggioErrore} nominativo={'" '+props.script.titolo+' "'} chiudi={()=>setSure(false)}/>
        </div>
    )
}