import { AdminConsumer } from "../../../context/AdminContext";

const verificaFiltri = (medico,nominativo,ruolo,gruppo) =>{
    const nome = medico.nome.toUpperCase();
    const cognome = medico.cognome.toUpperCase();

    const COGNOME_NOME = cognome + " " + nome;
    const NOME_COGNOME = nome + " " + cognome;
    const USERNAME = medico.username.toUpperCase();
    const NOMINATIVO = nominativo.toUpperCase();
    const GRUPPI = AdminConsumer._currentValue.gruppi;

    var checkNominativo = false;
    if(COGNOME_NOME.indexOf(NOMINATIVO) > -1) checkNominativo = true;
    if(NOME_COGNOME.indexOf(NOMINATIVO) > -1) checkNominativo = true;
    if(USERNAME.indexOf(NOMINATIVO) > -1) checkNominativo = true;
    if(nominativo === null) checkNominativo = true;

    var checkGruppo = false;
    GRUPPI.forEach(gruppoApi=>{
        if(gruppo === gruppoApi.id){
            gruppoApi.membri.forEach(membro=>{
                if(membro === medico.id) checkGruppo = true
            })
        }
    })
    if(gruppo === null) checkGruppo = true;

    var checkRuolo = false;
    if(ruolo === medico.ruolo) checkRuolo = true;
    if(ruolo === null) checkRuolo = true;

    var check = checkNominativo && checkGruppo && checkRuolo ? true : false
    return check
}

export { verificaFiltri }