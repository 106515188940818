import React,{useState,useContext} from 'react'
import {AuthContext} from '../../../../authentication'
import {Alert,Table} from 'react-bootstrap'
import {faPlus,faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../../../components/BasicComponents/Pagination'

export default function SelezioneScriptDaAssegnare(props){

    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 3;
    const {scriptDelPaziente} = useContext(AuthContext)

    const getBody = () =>{
        var cont =0
        if(scriptDelPaziente.length > 0){
            const rows = []
            scriptDelPaziente.forEach((script) => {
                if(script.visibile){
                if((cont>=recordPerPagina*pagina-recordPerPagina) && (cont<recordPerPagina*pagina)){
                var row = []
                    row.push(<td key={cont+"titoloAssegnazione"}>{script.titoloScript}</td>)
                    row.push(<td key={cont+"topicAssegnazione"}>{script.topic}</td>)
                    row.push(<td key={cont+"infoAssegnazione"} role="button" onClick={()=>props.infoScript(script)} style={{textAlign:"center"}}><FontAwesomeIcon icon={faInfoCircle}/></td>)
                    row.push(<td key={cont+"selezioneAssegnazione"} role="button" onClick={()=>props.cambiaAnteprima(script)} style={{textAlign:"center"}}><FontAwesomeIcon icon={faPlus}/></td>)
                    rows.push(<tr key={cont+"rowAssegnazione"} role="button">{row}</tr>)
                    }
                    cont++
                }
            });

            return(
            <div>
                <Table striped hover bordered>
                    <thead>
                        <tr key="HeaderAssegnazione">
                            <td key="HTitoloAssegnazione">TITOLO</td>
                            <td key="HTopicAssegnazione">TOPIC</td>
                            <td key="HInfoAssegnazione" style={{textAlign:"center"}}>VEDI DETTAGLI</td>
                            <td key="HSelezioneAssegnazione" style={{textAlign:"center"}}>SELEZIONA</td>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
                <Pagination recordPerPagina={recordPerPagina} totaleRecord={cont} cambiaVisualizzazione={false} setPagina={(pag)=>setPagina(pag)}/>
            </div>
            )

        }else{
            return <Alert variant="danger">Nessun script associato al paziente</Alert>
        }
    }

    return <>{getBody()}</>
}