import React,{useState} from 'react'
import TabellaScript from '../TabellaScript/TabellaScript'
import { AdminConsumer } from '../../context/AdminContext'
import {Alert,Button} from 'react-bootstrap'
import FiltriTabella from '../TabellaScript/FiltriTabella'

export default function GestioneScript(props){

    const [filtroTitolo,setFiltroTitolo] = useState("");
    const [idGruppo,setIdGruppo] = useState(null);

    const getScript = () =>{
        if(AdminConsumer._currentValue.gruppi !== null){
            const elencoScript = [];
            AdminConsumer._currentValue.gruppi.forEach(gruppo=>{
                const nomeGruppo = gruppo.nome;
                const idGruppo = gruppo.id;
                const SCRIPT_GRUPPO = gruppo.script;
                SCRIPT_GRUPPO.forEach(script=>{
                    var newObject = {
                        ...script,
                        nomeGruppo:nomeGruppo,
                        idGruppo:idGruppo
                    }
                    elencoScript.push(newObject);
                })
            })

            if(elencoScript.length > 0){
                return (<div>
                    <FiltriTabella selezionaGruppo={(id)=>setIdGruppo(id)} setFiltroTitolo={(valore)=>setFiltroTitolo(valore)}/>
                    <br/>
                    <TabellaScript elencoScript={elencoScript} {...props} filtroTitolo={filtroTitolo} filtroGruppo={idGruppo}/>
                </div>)
            }else{
                return <Alert variant="danger">Nessuno script trovato.</Alert>
            }
        }else{
            return <Alert variant="danger">Nessuno script trovato.</Alert>
        }
        
    }

    return (
    <div>
        {getScript()}
        <br/><br/>
        <Button variant="danger" onClick={props.setSceltaMenu} style={{width:"15%",marginLeft:"80%"}}>INDIETRO</Button>
    </div>)
}