import React,{useState} from 'react'
import { Dropdown,FormControl,Row,Col,Alert } from 'react-bootstrap'
import { AuthConsumer } from '../../authentication';

export default function FormDropdownSearch(props){

    const [filtro,setFiltro] = useState("");

    const stile = {width:"100%"}

    const stileValido = {width:"100%",border:"3px solid #23ff00"}
    const stileErrore = {width:"100%",border:"3px solid red"}

    const getOptions = (valoreFiltro) =>{
        const body = [];
        if(props.opzioni.length > 0){
            var cont = 0;
            props.opzioni.forEach(opzione=>{
                if(valoreFiltro === "" || opzione.nome.toUpperCase().indexOf(valoreFiltro.toUpperCase()) > -1){
                    cont++
                    body.push(
                    <Dropdown.Item key={opzione.id} id={opzione.id} name={opzione.nome} onClick={(event)=>props.funzione(event)}>
                        {opzione.nome}
                    </Dropdown.Item>
                    )
                }
            })
            if(cont === 0) body.push(<Alert variant="danger">La ricerca dei parametri '<b>{valoreFiltro}</b>' non ha prodotto nessun risultato</Alert>)
        }else{
            body.push(<Alert variant="danger">Errore nella comunicazione dei protocolli,si prega di ricaricare la pagina</Alert>)
        }

        return body
    }

    return(
        <Row>
            <Col>
                <Alert variant="info">
                   <b>{props.testo}</b>
                </Alert>
            </Col>
            <Col xs="12" className="breaker"/>
            <Col>
                <Dropdown>
                    <Dropdown.Toggle  style={props.valido === 5 ? stile : props.valido ? stileValido : stileErrore}>{props.valore}</Dropdown.Toggle>
                    <Dropdown.Menu style={stile}>
                        <FormControl type="text" placeholder="Cerca protocollo" value={filtro} onChange={(event)=>setFiltro(event.target.value)} style={{width:"96%",marginLeft:"2%"}} />
                        {getOptions(filtro)}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )

}