import React, { Component } from 'react'
import {Button,Alert,Row,Form,Col} from 'react-bootstrap'
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBox from '../../../../components/BasicComponents/Checkbox'
import TabellaPazienti from '../TabellaPazienti/TabellaPazienti'
import './GestionePazienti.css'
export default class GestionePazienti extends Component{
    constructor(props){
        super(props)
        this.state={
            label_cf:"",
            eventTarget:0,
            vediID:true,//gestione della visione del ID del paziente
            vediAnnoNascita:true,
            vediLuogoDiNascita:true,
            vediLuogoDiResidenza:true,
            vediSesso:true,
            vediValutazione:true,
            vediGestioneAttivita:true,
            pazienti:props.pazienti,
            pazientiPerPage:3,
            totalPazienti:6,//this.props.pazienti.length
            currentPage:1}
    }//constructor

    inputChanged=(event)=>{
        const valore =event.target.value.toLocaleUpperCase()
        this.setState({label_cf:valore})
    }//inputChanged

    setNumeroPazienti=(numero)=>{
        if(numero!=this.state.totalPazienti) this.setState({totalPazienti:numero})
    }
    
    setPagina=(numero)=>{
        if(numero!=this.state.currentPage) this.setState({currentPage:numero})
    }

    cambioFiltri=(event)=>{
        const {name} = event.target
        this.setState(prevState=>({[name]:!prevState[name]}))
    }//cambioFiltri()

    eventTar=(event)=>{this.setState({eventTarget:event.target.id})}//eventTar

render(){
    
        return(
            this.state.pazienti.length > 0?
            <div>
                <Alert className="alertGestione">
                    {/*Selezione del paziente di cui si vuole modiifcare la scheda in base al Codifce Fiscale (CF)*/}
                    <Row>
                        <div className="iconaCercaContainer">
                            <h4 className="iconaCerca"><FontAwesomeIcon icon={faSearch} /></h4>
                        </div> 
                        <div className="labelCerca">
                            <Form.Control type="text-field" placeholder="ID Paziente" style={{width:'100%'}} onChange={this.inputChanged} value={this.state.label_cf===0? "" : this.state.label_cf}/>
                        </div>
                    </Row>
                </Alert>
                <br/>
                <TabellaPazienti {...this.state}/>
                {/*CheckBox Filtri*/}
                <Alert variant='info' style={{color:'black'}}>
                    <Alert className="alertGestione"><h5>Selezione degli elementi che di desidera visualizzare</h5></Alert>
                    <Row>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediAnnoNascita'} check={this.state.vediAnnoNascita} testo={'Anno di nascita'}/>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediLuogoDiNascita'} check={this.state.vediLuogoDiNascita} testo={'Provincia di nascita'}/>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediLuogoDiResidenza'} check={this.state.vediLuogoDiResidenza} testo={'Provincia di residenza'}/>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediSesso'} check={this.state.vediSesso} testo={'Sesso'}/>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediValutazione'} check={this.state.vediValutazione} testo={'Schede di valutazione'}/>
                        <CheckBox funzione={(event)=>this.cambioFiltri(event)} nomeCheck={'vediGestioneAttivita'} check={this.state.vediGestioneAttivita} testo={"Gestione attività"}/>
                        
                    </Row>
                </Alert>
                <br/>
                <Button variant="danger" onClick={()=>this.props.setInterfaccia(0)} className="homeButton">HOME</Button>
            </div>
            :
            <div>
                <br/>
                <Alert variant="danger">Non e' stato trovato nessun paziente tra i gruppi associati</Alert>
                <br/>
                <Button variant="danger" onClick={()=>this.props.setInterfaccia(0)} className="homeButton">HOME</Button>
            </div>
        )}
}
//