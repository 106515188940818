import React from "react"
import {Alert,Row,Col, Button,Form} from 'react-bootstrap'
import RadioButtonTrueFalse from '../../../../../components/BasicComponents/RadioButtonTrueFalse'
import DropdownButton from '../../../../../components/BasicComponents/DropdownButton'
import {TempiEsordioMalattia,Diagnosi,Severita} from '../../../../StaticComponents/ElenchiOpzioniDropdown'
import {getDataEsordio,getGradoAfasia,getDiagnosi} from './Constants/UtilPrimaVisita'
import "./DatiPrimaVisita.css"

export default class DatiPrimaVisita extends React.Component{
constructor(props){
    super(props)
    this.state={
        Paziente:props.paziente,
        DatiPrimaVisita:null,
        diagnosi:"",
        descrizioneDiagnosi:"",
        dataEsordioSintomi:"",
        usoPsicofarmaci:false,
        lesioniFocaliPrecedenti:false,
        malattieNeurologichePsichiatriche:false,
        mmseMaggioreQuindici:false,
        gradoAfasia:"",
        destrimaneAtrofia:false,
        ripetizionePlurisillabicaConservata:false,
        dataArruolamento:"",
        nonRegistrati:false
    }
}

componentDidMount(){
    if(this.props.schede.datiPrimaVisita) {
        var diagnosiConverted = getDiagnosi(this.props.schede.datiPrimaVisita.diagnosi);
        var dataEsordioConverted = getDataEsordio(this.props.schede.datiPrimaVisita.dataEsordio);
        var gradoAfasiaConverted = getGradoAfasia(this.props.schede.datiPrimaVisita.gradoAfasia);
        var dataArruolamento = new Date(this.props.schede.datiPrimaVisita.dataArruolamento); 
        this.setState({
            DatiPrimaVisita:this.props.schede.datiPrimaVisita,
            usoPsicofarmaci:this.props.schede.datiPrimaVisita.usoPsicofarmaci,
            lesioniFocaliPrecedenti:this.props.schede.datiPrimaVisita.lesioniFocali,
            malattieNeurologichePsichiatriche:this.props.schede.datiPrimaVisita.malattieNeurologiche,
            mmseMaggioreQuindici:this.props.schede.datiPrimaVisita.mmse,
            destrimaneAtrofia:this.props.schede.datiPrimaVisita.destrimaneAtrofia,
            ripetizionePlurisillabicaConservata:this.props.schede.datiPrimaVisita.ripetizionePlurisillabica,
            dataArruolamento:dataArruolamento,
            descrizioneDiagnosi:this.props.schede.datiPrimaVisita.descrizioneDiagnosi,
            diagnosi:diagnosiConverted,
            dataEsordioSintomi:dataEsordioConverted,
            gradoAfasia:gradoAfasiaConverted,
            nonRegistrati:false
        })
    }else{
        this.setState({
            diagnosi:"Seleziona Diagnosi",
            dataEsordioSintomi:"Seleziona Data",
            gradoAfasia:"Seleziona Grado Afasia",
            dataArruolamento:new Date(),
            nonRegistrati:true
        })
    }
}

cambioRadio=(event)=>{
    var {name} = event.target
    var value = event.target.value
    if(value==="SI") this.setState({[name]:true})
    else this.setState({[name]:false})
}
aggiornaDescrizione = (event) =>{this.setState({descrizioneDiagnosi:event.target.value})}

invio=()=>{
    var Messaggio = "Errore,campi non inseriti correttamente:"
    if(this.state.diagnosi==="" || this.state.diagnosi==="Seleziona Diagnosi") Messaggio=Messaggio+"\n-Diagnosi"
    if(this.state.dataEsordioSintomi==="" || this.state.dataEsordioSintomi==="Seleziona Data") Messaggio=Messaggio+"\n-Data Esordio Sintomi"
    if(this.state.gradoAfasia==="" || this.state.gradoAfasia==="Seleziona Grado Afasia") Messaggio=Messaggio+"\n-Grado Afasia"
    if(this.state.dataArruolamento==="" || Date.parse(this.state.dataArruolamento)>Date.parse(new Date())) Messaggio=Messaggio+"\n-Data arruolamento"
    if(Messaggio==="Errore,campi non inseriti correttamente:") {
        var giorno = new Date(this.state.dataArruolamento).getDate();
        var mese = new Date(this.state.dataArruolamento).getMonth()+1;
        var anno = new Date(this.state.dataArruolamento).getFullYear();
        var ora = new Date(this.state.dataArruolamento).getHours();
        var minuti = new Date(this.state.dataArruolamento).getMinutes();
        var secondi = new Date(this.state.dataArruolamento).getSeconds();
        var componimentoData = giorno+"-"+mese+"-"+anno+" "+ora+":"+minuti+":"+secondi
        var scheda = {
        tipo : 1,
        idPaziente : this.state.Paziente.ID,
        diagnosi : this.state.diagnosi,
        dataEsordio : this.state.dataEsordioSintomi,
        gradoAfasia : this.state.gradoAfasia,
        descrizioneDiagnosi : this.state.descrizioneDiagnosi,
        dataArruolamento : componimentoData,
        psicofarmaci : this.state.usoPsicofarmaci,
        neurologiche : this.state.malattieNeurologichePsichiatriche,
        mmse : this.state.mmseMaggioreQuindici,
        atrofia : this.state.destrimaneAtrofia,
        ripetizioniPlurisillabiche : this.state.ripetizionePlurisillabicaConservata,
        lesioniFocali : this.state.lesioniFocaliPrecedenti
        }
        this.props.salvaScheda(scheda);
    } 
    else alert(Messaggio)
}

getValueDate = () =>{
    var giorno = new Date(this.state.dataArruolamento).getDate();
    if(giorno<10) giorno="0"+giorno
    var mese = new Date(this.state.dataArruolamento).getMonth()+1;
    if(mese<10) mese="0"+mese
    var anno = new Date(this.state.dataArruolamento).getFullYear();
    var data = anno+"-"+mese+"-"+giorno
    return data
}

render(){
    return(
    <>
        <Alert variant="success" style={{color:"black"}}>
            Dati della prima visita  del paziente con id : <strong>{this.state.Paziente.idPaziente}</strong>
        </Alert>
        <br/>
        {this.state.nonRegistrati===true?
                <Alert variant="danger">
                    I dati della prima visita del paziente con id : <strong>{this.state.Paziente.idPaziente}</strong> non sono ancora stati registrati
                </Alert>:null}
        <br/>
        <Row>
            <Col md="4">
                <Alert variant="info">DIAGNOSI</Alert>
            </Col>
            <Col md="12" className="dividerOPT" />
            <Col md="3">
                <DropdownButton testoPredefinito={this.state.diagnosi} 
                opzioni={Diagnosi} funzione={(valore)=>{this.setState({diagnosi:valore})}} stile={{width:"100%"}}/>
            </Col>
            <Col xs="12" className="dividerTA"/>
            <Col md="4" className="diagnosiDesc">
            <textarea id="DescrizioneDiagnosi" name="DescrizioneDiagnosi" rows="4" cols="60" className="TADiagnosi"
             placeholder="Descrizione Diagnosi" onKeyDown={(event) =>{this.aggiornaDescrizione(event)}} 
             onChange={this.aggiornaDescrizione} value={this.state.descrizioneDiagnosi}/>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col md="4">
                <Alert variant="info">DATA ESORDIO SINTOMI</Alert>
            </Col>
            <Col md="12" className="dividerOPT" />
            <Col md="3" style={{marginTop:"1vw"}}>
                <DropdownButton testoPredefinito={this.state.dataEsordioSintomi} 
                opzioni={TempiEsordioMalattia} funzione={(valore)=>{this.setState({dataEsordioSintomi:valore})}} stile={{width:"100%"}}/>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col md="4">
                <Alert variant="info">DATA DI ARRUOLAMENTO</Alert>
            </Col>
            <Col md="3">
                <Form.Control type="date" onChange={(event)=>{this.setState({dataArruolamento:event.target.value})}} 
                value={this.getValueDate()}/>
            </Col>
        </Row>
        <hr/>
        <RadioButtonTrueFalse testo="USO PSICOFARMACI" nome="usoPsicofarmaci" checked={this.state.usoPsicofarmaci} funzione={this.cambioRadio}/>
        <RadioButtonTrueFalse testo="LESIONI FOCALI PRECEDENTI" nome="lesioniFocaliPrecedenti" checked={this.state.lesioniFocaliPrecedenti} funzione={this.cambioRadio}/>
        <RadioButtonTrueFalse testo="MALATTIE NEUROLOGICHE / PSICHIATRICHE IN ANAMNESI" nome="malattieNeurologichePsichiatriche" checked={this.state.malattieNeurologichePsichiatriche} funzione={this.cambioRadio}/>
        <RadioButtonTrueFalse testo="MMSE &gt; 15" nome="mmseMaggioreQuindici" checked={this.state.mmseMaggioreQuindici} funzione={this.cambioRadio}/>
        <Row>
            <Col md="4">
                <Alert variant="info">GRADO AFASIA</Alert>
            </Col>
            <Col md="12" className="dividerOPT" />
            <Col md="3">
            <DropdownButton testoPredefinito={this.state.gradoAfasia} opzioni={Severita} 
            funzione={(valore)=>{this.setState({gradoAfasia:valore})}} stile={{width:"100%"}}/>
            </Col>
        </Row>
        <hr/>
        <RadioButtonTrueFalse testo="PAZIENTE DESTRIMANE CON ATROFIA" nome="destrimaneAtrofia" checked={this.state.destrimaneAtrofia} funzione={this.cambioRadio}/>
        <RadioButtonTrueFalse testo="RIPETIZIONE PLURISILLABICA CONSERVATA" nome="ripetizionePlurisillabicaConservata" checked={this.state.ripetizionePlurisillabicaConservata} funzione={this.cambioRadio}/>
        <Button variant="success" onClick={this.invio} className="buttonDati">INVIO</Button>
        <br/><br/>
        <Button variant="danger" onClick={()=>this.props.indietro()} className="buttonDati">INDIETRO</Button>
    </>
    )//return
}//render
}//class