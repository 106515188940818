import React from 'react'
import {Form,Alert,Row,Col} from 'react-bootstrap'

export default function Modifier(props){

    const valid = {border:"1px solid green"}
    const notValid = {border:"1px solid red"}
    const bold = { fontWeight: "bold"}
    return (
        <Row>
            <Col md="6" xs="12">
                <Alert variant="info" style={props.b ? bold : null}>
                    {props.testo}
                </Alert>
            </Col>
            <Col md="6" xs="12">
                <Form.Control id={props.id} value={props.valore} style={props.valido ? valid : notValid} onChange={(event)=>props.funzione(event)} placeholder={props.place}/>
            </Col>
        </Row>
    )
}