import React,{useContext,useState} from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {AuthContext} from '../../../../authentication'
import {Table,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopOverAttivita from '../AssegnazioneScript/PopOverAttivita'


export default function TabellaRicerca(props){

    const {Pazienti} = useContext(AuthContext);
    const [vediAttivita,setVediAttivita]=useState(0)

    const getElenco =()=> {
        const body=[]
        if(Pazienti.length>0){
            var cont=0
            Pazienti.forEach(paziente => {
                var pazienteUpper = paziente.idPaziente.toUpperCase();
                var filtroUpper = props.filtro.toUpperCase();
                if( pazienteUpper.indexOf(filtroUpper)>-1){
                var row=[]
                if(cont<5){
                        cont++
                        row.push(<td key={"IdPaziente"+paziente.ID}>{paziente.idPaziente}</td>)
                        row.push(
                            <td key={"Attivita"+paziente.ID} style={{textAlign:"center"}} role="button" onClick={()=>setVediAttivita(paziente)}>
                                    <FontAwesomeIcon icon={faPlus}/>
                            </td>)
                        body.push(<tr key={"Riga"+paziente.ID}>{row}</tr>)
                    }
                }
            });
            if(body.length>0){
                return(
                <Table striped bordered hover id="tabella" >
                    <thead>
                        <tr>
                            <th>ID Paziente</th>
                            <th>Gestione attività</th>
                        </tr>
                    </thead>
                    <tbody>
                        {body}        
                    </tbody>
                </Table>
                )
            }else{
                return <Alert variant="danger">La ricerca dei parametri '<b>{props.filtro}</b>' non ha prodotto nessun risultato.</Alert>
            }
        }else{
            return <Alert variant="danger">Nessun elemento trovato</Alert>
        }
    }

    return(<>
        {getElenco()}
        {vediAttivita===0?null:<PopOverAttivita Paziente={vediAttivita} funzione={()=>setVediAttivita(0)}/>}
    </>
    )
}