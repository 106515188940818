import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import './Style.css'

export default function App(props){
  //parametri Calendar
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth()+1,
    day: new Date().getDate(),
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const dateDeiTraining=[
    // CSS classes 'purpleDay' 'orangeDay' 'yellowDay'  'navyBlueDay'
  ]
  
  function composizione(){
      props.date.forEach((data)=>{
          dateDeiTraining.push({year:new Date(data).getFullYear(),month:new Date(data).getMonth()+1,day:new Date(data).getDate(),className: 'navyBlueDay'})
      });

      return <Calendar value={selectedDay} onChange={setSelectedDay} calendarClassName="responsive-calendar" calendarTodayClassName="orangeDay" shouldHighlightWeekends customDaysClassName={dateDeiTraining}/>
  }
  //estrapolazione 
  return (
    <div style={{width:"100%"}} className="calendar"> 
    {composizione()}
    <br/>
    </div>
  );
};