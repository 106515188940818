import React,{useState} from 'react'
import VimeoPlayer from './VimeoPlayer'
import VimeoController from './VimeoController'
import {Spinner,Alert} from 'react-bootstrap'
import './VimeoComponent.css'
export default function VimeoComponent(props){

    const [playing,setPlaying] = useState(false);
    const [ready,setReady] = useState(false);
    const [restart,setRestart] = useState(false);
    const [ripeti,setRipeti] = useState(false);
    const [errore,setErrore] = useState(false);

    return (
        <div>
            {errore === true ? 
            <Alert variant="danger">Errore nel caricamento del video<br/><b>Nota:</b>L'elaborazione del video può richiedere diversi minuti,si prega di riprovare più tardi</Alert>
            :
            <div>
                <div className={ready ? null : "hide" }>
                    <VimeoPlayer url={props.url} playing={playing} setReady={()=>setReady(true)} restart={restart} restartDone={()=>setRestart(false)} ripeti={ripeti} ripetizioneDone={()=>setRipeti(false)} setErrore={()=>setErrore(true)}/>
                    <br/>
                    <VimeoController playing={playing} funzione={()=>setPlaying(!playing)} restart={()=>setRestart(true)} ripeti={()=>setRipeti(true)} />
                </div>
                <div className={!ready ? "loader" : "hide" }>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    <br/>
                    <h3>Caricamento video in corso...</h3>
                </div>
            </div>
            }
        </div>
    )
}