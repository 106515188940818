import React from 'react'
import {Button,Alert,Form,Row,Col} from 'react-bootstrap'
import FormLabel from '../../../../../components/FormComponents/FormLabel'
import './ParametriDiEloquio.css'
export default class ParametriDiEloquio extends React.Component{
    
    constructor(props){
        super(props)
        this.state={
            paziente:props.paziente,
            parolePerFrase:0,
            numeroFrasi:0,
            sillabePerFrase:0,
            paroleTotali:0,
            sillabeTotali:0,
            nonRegistrati:false
        }
    }

    componentDidMount(){
        if(this.props.schede.parametriEloquio) {
            var frasiTotali = this.props.schede.parametriEloquio.numeroFrasi;
            var paroleTotali = this.props.schede.parametriEloquio.numeroParole;
            var paroleFrase = parseInt(paroleTotali / frasiTotali)
            if((paroleTotali % frasiTotali)>=5) paroleFrase=paroleFrase+1
            var numeroSillabe = this.props.schede.parametriEloquio.numeroSillabe;
            var sillabeFrase = parseInt(numeroSillabe / frasiTotali)
            if((numeroSillabe % frasiTotali)>=5) numeroSillabe=numeroSillabe+1
            if(numeroSillabe===0 && frasiTotali===0 && paroleTotali===0){
                this.setState({
                    parolePerFrase:0,
                    numeroFrasi:0,
                    sillabePerFrase:0,
                    paroleTotali:0,
                    sillabeTotali:0,
                    nonRegistrati:true
                })    
            }else{
                this.setState({
                    parolePerFrase:paroleFrase,
                    numeroFrasi:frasiTotali,
                    sillabePerFrase:sillabeFrase,
                    paroleTotali:paroleTotali,
                    sillabeTotali:numeroSillabe,
                    nonRegistrati:false
                })
            }
        }else{
            this.setState({
                parolePerFrase:0,
                numeroFrasi:0,
                sillabePerFrase:0,
                paroleTotali:0,
                sillabeTotali:0
            })
        }
    }

    invio = () =>{
        var messaggioErrore=""
        
        if(this.state.numeroFrasi < 1 || this.state.numeroFrasi > 15) messaggioErrore+="\n-Numero di frasi per script \nRange valido: 1 - 15" 
        if(this.state.parolePerFrase < 1 || this.state.parolePerFrase > 10) messaggioErrore+="\n-Numero di parole per frase \nRange valido: 1 - 15"
        if(this.state.sillabePerFrase < 1 || this.state.sillabePerFrase > 20) messaggioErrore+="\n-Numero di sillabe \nRange valido: 1 - 20"

        if(messaggioErrore==="") {
            var scheda ={
                tipo:2,
                idPaziente:this.state.paziente.ID,
                numeroFrasi:this.state.numeroFrasi,
                numeroParole:this.state.parolePerFrase*this.state.numeroFrasi,
                numeroSillabe:this.state.numeroFrasi*this.state.sillabePerFrase
            }
            this.props.salvaScheda(scheda)
        }else{
            alert(messaggioErrore)
        }
    }

    render(){
        return(
            <>
                <Alert variant="success">
                    Parametri di eloquio del paziente con id : <b>{this.state.paziente.idPaziente}</b>
                </Alert>
                <br/>
                {this.state.nonRegistrati===true?
                <Alert variant="danger">
                    I parametri di eloquio del paziente con id : <b>{this.state.paziente.idPaziente}</b> non sono ancora stati registrati
                </Alert>:null}
                <br/>
                
                <FormLabel tipo={"number"} testo="Numero di frasi per script" valore={this.state.numeroFrasi} 
                funzione={(event)=>this.setState({numeroFrasi:event.target.value})} nome="Frasi" 
                valido={this.state.numeroFrasi>0 && this.state.numeroFrasi<16} cl="formLabel" break={true} />
                
                <FormLabel tipo={"number"} testo="Numero di parole per frase"  valore={this.state.parolePerFrase} 
                funzione={(event)=>this.setState({parolePerFrase:event.target.value})} nome="Parole" 
                valido={this.state.parolePerFrase>0 && this.state.parolePerFrase<16}  cl="formLabel" break={true}/>
                
                <FormLabel tipo={"number"} testo="Numero di sillabe per frase" valore={this.state.sillabePerFrase} 
                funzione={(event)=>this.setState({sillabePerFrase:event.target.value})} nome="Sillabe" 
                valido={this.state.sillabePerFrase>0 && this.state.sillabePerFrase<21}  cl="formLabel" break={true}/>
                {/*Valori calcolati*/}
                
                <FormLabel tipo={"number"} testo="Numero di parole totali dello script" 
                valore={this.state.parolePerFrase*this.state.numeroFrasi>0?this.state.parolePerFrase*this.state.numeroFrasi:0}  
                valido={this.state.numeroFrasi*this.state.parolePerFrase>0} 
                read={true}  cl="formLabel" break={true}/>
                
                <FormLabel tipo={"number"} testo="Numero di sillabe totali dello script" 
                valore={this.state.sillabePerFrase*this.state.numeroFrasi>0?this.state.sillabePerFrase*this.state.numeroFrasi:0}  
                valido={this.state.numeroFrasi*this.state.sillabePerFrase>0} 
                read={true}  cl="formLabel" break={true}/>
                
                <Button variant="success" className="buttonParametri" onClick={()=>this.invio()}>INVIO</Button>
                <br/><br/>
                <Button variant="danger" className="buttonParametri" onClick={()=>this.props.indietro()}>INDIETRO</Button>
            </>
        )
    }
}