import React from 'react'
import { Modal,Row,Col,Alert,Container,Spinner } from 'react-bootstrap'
export default function AggiornaGruppoApi(props){

    const close = () =>{
        props.setSure();
    }

    return(
        <Modal size="lg" show={props.sure || props.loading} onHide={()=>props.setSure()} backdrop="static">
                <Container>
                    {props.loading ?
                    <div>
                        <Modal.Header>
                            <Modal.Title>Caricamento...</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md="1" />
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw"}} />
                                </Col>
                                <Col>
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col>    
                                    <Spinner animation="grow" variant="primary" style={{width:"5vw",height:"5vw",marginLeft:"0.5vw"}} />
                                </Col>
                                <Col md="1" />
                            </Row>
                        </Modal.Body>
                    </div>
                    :
                    props.errore ? 
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Errore</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="danger">Errore nella richiesta di {props.tipo===1?" salvataggio " : " modifica "} del gruppo{props.messaggioErrore!==""?":"+props.messaggioErrore : null}</Alert>
                            </Modal.Body>
                        </div>
                        :
                        <div>
                            <Modal.Header closeButton onClick={()=>close()}>
                                <Modal.Title>Gruppo {props.tipo===1?" salvato" : " modificato"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert variant="success">{props.tipo===1?"Salvataggio " : "Modifica "} del gruppo eseguita con successo.</Alert>
                            </Modal.Body>
                        </div>
                    }
                </Container>
            </Modal>
    )
}