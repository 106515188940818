import React,{useContext,useState} from 'react'
import CampoRiepilogo from './CampoRiepilogoPaziente'
import ModalCredenzialiPaziente from './ModalCredenzialiPaziente'
import {Button} from 'react-bootstrap'
import {AuthContext} from '../../../../authentication'
import Loader from '../../../../Loader/Loader'
import { Alert } from 'react-bootstrap'

export default function RiepilogoDatiPaziente(props){

    const {registraPaziente,getPazienti,credenzialiPaziente}=useContext(AuthContext)
    const [inLoading,setInLoading]=useState(false)
    const [vediCredenziali,setVediCredenziali] = useState(false)
    const [errore,setErrore] = useState(false);
    const [messaggioErrore,setMessaggioErrore] = useState(null);
    const stileButton={width:"20%",marginLeft:"80%"}

    const invioDati = async () =>{
        setErrore(false)
        setInLoading(true)
        var prov
        if(props.siglaProvincia==="EE") prov = props.nomeStato
        else prov= props.nomeProvincia
        //Creazione oggetto
        var Paziente = {};
            Paziente.identificativoPaziente=props.IDPaziente[0];
            Paziente.idProtocollo=props.idProtocollo[0];
            Paziente.sesso=props.Sesso;
            Paziente.anno_nascita=props.AnnoDiNascita[0];
            Paziente.luogo_nascita=props.nomeProvinciaNascita;
            Paziente.luogo_residenza=props.nomeProvinciaResidenza;
            Paziente.gruppo=props.gruppo;
        //Chiamata api
        try{
            var x = await registraPaziente(Paziente)
            .then(function (){
                getterPazienti();
            })
        }catch(error){
            console.log(error)
            var mes = error.response.data
            //alert('errore nella registrazione,si invita a ricaricare la pagina e riprovare.')
            setInLoading(false)
            setErrore(true)
            setMessaggioErrore(mes)
        }
    }

    const getterPazienti =async () =>{
            try {
                await getPazienti()
                .then(function(){
                    setInLoading(false)
                    setVediCredenziali(true)
                })
                
            }catch(error){
                var mes = error.response.data
                console.log(error)
                setInLoading(false)
                setErrore(true)
                setMessaggioErrore(mes)
            }
        }

    return(
        <div>
            {inLoading===true?<Loader pagina="Med"/>:
            errore ? <Alert variant="danger">{messaggioErrore}</Alert> :
            <>
                <h3 style={{textAlign:"center",letterSpacing:"0.1vw"}}>CONFERMA DATI&ensp; PAZIENTE</h3>
                <br/>
                <CampoRiepilogo nome="Protocollo" valore={props.codiceProtocollo}/>
                <CampoRiepilogo nome="ID Paziente" valore={props.IDPaziente}/>
                <CampoRiepilogo nome="Sesso" valore={props.SessoTesto}/>
                <CampoRiepilogo nome="Provincia di Nascita" valore={props.nomeProvinciaNascita}/>
                <CampoRiepilogo nome="Provincia di Residenza" valore={props.nomeProvinciaResidenza}/>
                <CampoRiepilogo nome="Anno di nascita" valore={props.AnnoDiNascita}/>
                <CampoRiepilogo nome="Équipe" valore={props.testoGruppo}/>
                <br/><br/>
                <Button variant="success" onClick={()=>invioDati()} name="invioDati" style={stileButton}>INVIO</Button>
            </>
            }
            {!inLoading ?
            <div>
                <br/><br/>
                <Button variant="danger" onClick={()=>props.indietro(props.SessoTesto)} name="indietro" style={stileButton}>INDIETRO</Button>
            </div>
            :null}
            {!inLoading && vediCredenziali ? <ModalCredenzialiPaziente fine={()=>props.fine()} /> : null}
        </div>
    )
}