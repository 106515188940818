import React,{useState} from 'react'

import Modal from 'react-modal'
import ModalInfoScript from './ModalInfoScript'
import InterfacciaModificaScript from '../ModificaScript/InterfacciaModificaScript'
import AggiornamentoScript from './AggiornamentoScript'
import {AuthConsumer} from '../../../../authentication'
import './ModalInfoScript.css'

Modal.setAppElement('#root')
export default function ModalContainer(props){
    const[modalIsOpen,setModalIsOpen]=useState(true);
    const[modificaScript,setModificaScript]=useState(false);
    const[inviaDati,setInviaDati]=useState(null);
    const[script,setScript]=useState(AuthConsumer._currentValue.focusScript)
    const[invioEffettuato,setInvioEffettuato]=useState(false);

    const indietro = (script) =>{
        setInviaDati(null)
    }

    return (
        <Modal isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)} shouldCloseOnOverlayClick={false}  htmlOpenClassName="MoadalContainer" >
            {
            inviaDati !== null ?  
            <>
            <AggiornamentoScript inviaDati={inviaDati} idScript={script.idScript} indietro={(script)=>indietro(script)}  fine={()=>props.fine()}/>
            </>
            :
                modificaScript===false ?
                <ModalInfoScript sezione="Libreria" script={script} modificaScript={()=>setModificaScript(true)} fine={()=>props.fine()}/>
                :
                <InterfacciaModificaScript script={script} chiudiModificaScript={()=>setModificaScript(false)} invioDati={(dati)=>setInviaDati(dati)} fine={()=>props.fine()}/>
            }
            
        </Modal>
    )
}