import React,{useState} from 'react'
import { Form,Row,Col,Dropdown } from 'react-bootstrap'
import { AdminConsumer } from '../../../context/AdminContext'

export default function FiltriTabella(props){

    const [gruppoNome,setGruppoNome] = useState("Seleziona Gruppo")

    const selezionaGruppo = (id,nome) =>{
        props.setFiltroGruppo(id);
        setGruppoNome(nome);
    }

    const getDropGruppi = () =>{
        const body=[];
        body.push(<Dropdown.Item key={0} onClick={()=>{selezionaGruppo(null,"Seleziona Gruppo")}}  >Visualizza tutti</Dropdown.Item>);
        const GRUPPI = AdminConsumer._currentValue.gruppi
        if(GRUPPI !== null){
            GRUPPI.forEach(gruppo=>{
                body.push(<Dropdown.Item key={gruppo.id} onClick={()=>{selezionaGruppo(gruppo.id,gruppo.nome)}} >{gruppo.nome}</Dropdown.Item>);
            })
        }
        return getDropBase(gruppoNome,body)
    }

    //Container dropdown
    const getDropBase = (valore,opzioni) =>{
        return(
            <Dropdown style={{width:"100%"}}>
                <Dropdown.Toggle style={{width:"100%"}}>{valore}</Dropdown.Toggle>
                <Dropdown.Menu style={{width:"100% "}}>{opzioni}</Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <Row>
            <Col>
                <Form.Control value={props.filtroId} onChange={(event)=>props.setFiltroId(event.target.value)} placeholder="ID paziente / username"/>
            </Col>
            <Col>
                {getDropGruppi()}
            </Col>
        </Row>
    )

}