import React,{useState} from 'react'
import HeaderPaziente from '../../StaticComponents/HeaderPaziente'
import IstruzioniVideo from '../Modal/IstruzioniVideo'
import {Row,Col,Alert} from 'react-bootstrap'
import DefinizioneAttivita from '../DefinizioneAttivita'
import ModificaPassword from '../ModificaPassword/ModificaPassword'

export default function HomePagePaziente(props){
    
    const [idPaziente,setIdPaziente]=useState("1")
    const [vediInfo,setVediInfo]=useState(false)
    const [modificaPassword,setModificaPassword] = useState(false)
    var controllo=true
    const estraiUtente=()=>{
        

            return(
            <>
                <div style={{position:"absolute",width:"100%"}}>
                    <HeaderPaziente modificaPassword={()=>setModificaPassword(true)} doLogout={()=>props.doLogout()}/>
                </div>
                   {/* {controllo?<BadgeWelcome nome={Utente[0].Cognome+" "+Utente[0].Nome} porzione={"#training"}/>:null}*/}
                <br/>
                <div id="training" style={!modificaPassword?{width:"100%",position:"relative",zIndex:"2",marginTop:"2.5%"}:{display:'none'}}>
                    {controllo?<Row>
                        <Col>
                            <DefinizioneAttivita id={idPaziente} setVediInfo={(value)=>setVediInfo(value)}/>
                        </Col> 
                    </Row>:<>
                    <Alert variant="danger">
                        <strong>
                            <h2>
                                Attenzione:
                            </h2>
                        </strong>
                        L'utente con l'identidicativo #{idPaziente} non e' presente nel sistema
                    </Alert></>}
                    
                </div>
            </>
            )

    }
    return(
        <div style={{marginLeft:"5%",marginRight:"5%"}}>
            {vediInfo?<IstruzioniVideo setVediInfo={()=>setVediInfo(false)}/> : estraiUtente()}
            {modificaPassword ? <ModificaPassword indietro={()=>setModificaPassword(false)} /> : null}
            <br/>{/*
            ID Paziente per prova&ensp;<input type="number" placeholder="ID paziente" onChange={onChange}/>*/}
        </div>
    )
}