import React from 'react'
import {Button, Row,Col,Alert} from 'react-bootstrap'
import { AuthContext } from '../../../../authentication'
import RiepilogoDatiPaziente from '../FormRegistrazione/RiepilogoDatiPaziente'

import Provincie from '../FormRegistrazione/Provincie'
//import form component
import FormLabel from '../../../../components/FormComponents/FormLabel'
import FormRadioButton from '../../../../components/FormComponents/FormRadioButton'
import FormDropdownSearch from '../../../../components/FormComponents/FormDropdownSearch'
import './Style.css'
import './RegistraNuovoPaziente.css'

export default class RegistraNuovoPaziente extends React.Component{
    constructor(props){
        super(props)
        this.state={
            IDPaziente:"",
            codiceProtocollo:"",
            idProtocollo:"",
            Sesso:"",
            SessoTesto:"",
            nomeProvinciaNascita:"",
            nomeProvinciaResidenza:"",
            AnnoDiNascita:"",
            vediRiepilogo:false,
            bordoSesso:null,
            bordoIdPaziente:null,
            bordoCodiceProtocollo:null,
            bordoProvinciaNascita:null,
            bordoProvinciaResidenza:null,
            bordoAnnoDiNascita:null,
            bordoGruppo:null,
            controllato:0,
            gruppo:null,
            testoGruppo:null        
        }
        this.invio=this.invio.bind(this)
    }

    getRadioGroup = () =>{
        if(AuthContext._currentValue.gruppi !== null){
            const body = []
            AuthContext._currentValue.gruppi.forEach(gruppo=>{
                var testoVisualizzato = gruppo.nome+"-"+gruppo.centro
                if(AuthContext._currentValue.gruppi.length === 1 && this.state.gruppo !== gruppo.id) this.setState({gruppo:gruppo.id,testoGruppo:testoVisualizzato})
                body.push(<Col md="4" s="4" xs="4" key={gruppo.id} >
                    <input type="radio" name="group" checked={gruppo.id===this.state.gruppo ? true : false} onChange={()=>this.setState({gruppo:gruppo.id,testoGruppo:testoVisualizzato})} />&nbsp;{testoVisualizzato}
                </Col>)
            })
            return <Row>{body}</Row>
        }
    }

    inputChanged = (event) =>{
        const {value} = event.target
        const {name} = event.target
        this.setState({[name]:[value]})
    }

    selezionaCentro = (event) =>{
        var id = event.target.id;
        var nome = event.target.name;
        this.setState({
            idProtocollo:id,
            codiceProtocollo:nome
        })
    }

    impostazioneProvincia = (nome,sezione) =>{
        if(sezione==="nascita"){
        this.setState({nomeProvinciaNascita:nome})
        }else{
            this.setState({nomeProvinciaResidenza:nome})
        }
    }

    invio=(event)=>{
        var evento = event.target.name
        var contErrori=0
        var messaggioDiErrore="Attenzione,i seguenti campi non sono validi:"
        //ID Paziente
        if(this.state.IDPaziente!==""){
            if(this.state.IDPaziente[0].length===0){
                contErrori++
                messaggioDiErrore=messaggioDiErrore+"\n-ID Paziente"
                this.setState({bordoIdPaziente:false})
            }else this.setState({bordoIdPaziente:true})
        }else {
            this.setState({bordoNome:false})
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-ID Paziente"
        }
        //PROTOCOLLO
        if(this.state.codiceProtocollo!==""){
            if(this.state.codiceProtocollo[0].length===0){
                contErrori++
                messaggioDiErrore=messaggioDiErrore+"\n-Protocollo"
                this.setState({bordoCodiceProtocollo:false})
            }else this.setState({bordoCodiceProtocollo:true})
        }else {
            this.setState({bordoCodiceProtocollo:false})
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-Protocollo"
        }
        //SESSO
        if(this.state.SessoTesto===""){
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-Sesso"
            this.setState({bordoSesso:false})
        }else {
            var codice = 0;
            if(this.state.Sesso === "Maschio") codice = 1
            else if(this.state.Sesso === "Femmina") codice = 2
            else if(this.state.Sesso === "Altro") codice = 0
            this.setState({bordoSesso:true,Sesso:codice}) 
        }
        //PROVINCIA NASCITA
        if(this.state.nomeProvinciaNascita===""){
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-Provincia di nascita"
            this.setState({bordoProvinciaNascita:false})
        }else{
            this.setState({bordoProvinciaNascita:true})
        }
        //PROVINCIA RESIDENZA
        if(this.state.nomeProvinciaResidenza===""){
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-Provincia di residenza"
            this.setState({bordoProvinciaResidenza:false})
        }else{
            this.setState({bordoProvinciaResidenza:true})
        }
        //ANNO DI NASCITA
        if(this.state.AnnoDiNascita!==""){
            var eta = new Date().getFullYear() - this.state.AnnoDiNascita
            if(eta > 85 || eta < 30){
                contErrori++
                messaggioDiErrore=messaggioDiErrore+"\n-Anno di nascita (L'età deve essere compresa tra un minimo di 30 anni ed un massimo di 85 anni)"
                this.setState({bordoAnnoDiNascita:false})
            }else this.setState({bordoAnnoDiNascita:true})
        }else{
            this.setState({bordoAnnoDiNascita:false})
            messaggioDiErrore=messaggioDiErrore+"\n-Anno di nascita"
            contErrori++
        }
        //GRUPPO
        if(this.state.gruppo === null){
            contErrori++
            messaggioDiErrore=messaggioDiErrore+"\n-Selezione équipe"
            this.setState({bordoProvinciaNascita:false})
        }

        if(contErrori>0 && evento==="invio"){
            this.setState({controllato:1})
            alert(messaggioDiErrore)
        }else{
            this.setState({vediRiepilogo:true})
        }
    }

    invioDati=()=>{
        alert("Il paziente "+this.state.Nome+" "+this.state.Cognome+" e' stato registrato correttamente")
    }

    render(){
        const nonDropValido={width:"100%",borderRadius:"2px",border:"3px solid red"}
        const validoDrop={width:"100%",borderRadius:"10px",border:"3px solid #23ff00"}
        const stileButton={width:"30%",marginLeft:"70%"}
    return(
            <div>
            {this.state.vediRiepilogo? 
            <RiepilogoDatiPaziente {...this.state} invioDati={()=>this.invioDati} indietro={(testoSesso)=>this.setState({vediRiepilogo:false,Sesso:testoSesso,SessoTesto:testoSesso})} fine={()=>this.props.setInterfaccia(0)}/>
            :
            <div className="formRegistrazione">
                <h3 style={{textAlign:"center",letterSpacing:"0.1vw"}}>REGISTRAZIONE&ensp; PAZIENTE</h3>
                <br/>
                <hr/>
                    <FormDropdownSearch testo="Protocollo *" funzione={(event)=>this.selezionaCentro(event)} opzioni={AuthContext._currentValue.protocolli} valore={this.state.codiceProtocollo === "" ? "Seleziona Protocollo" : this.state.codiceProtocollo} valido={this.state.bordoCodiceProtocollo} />
                    <hr/>
                    <FormLabel tipo={"text"} cl="formLabel" break={true} testo="ID Paziente *" place={"ID Paziente"} valore={this.state.IDPaziente} funzione={(event)=>this.inputChanged(event)} nome="IDPaziente" valido={this.state.bordoIdPaziente}/>
                    <hr/>
                    <FormRadioButton testo="Sesso *" genere={true} clAl="radioAlert" clOp="radioOption" cl="formLabel" break={true} opzioni={["Maschio","Femmina","Altro"]} funzione={(id)=>this.setState({Sesso:id,SessoTesto:id})} valore={this.state.SessoTesto} valido={this.state.bordoSesso}/>
                    <hr/>
                    <Row>
                        <Col>
                            <Alert variant="info" style={{width:"99%"}}>
                                <b>Provincia di nascita *</b>
                            </Alert>
                        </Col>
                        <Col xs="12" className="breaker"/>
                        <Col> 
                            <Provincie impostazioneProvincia={this.impostazioneProvincia} sezione="nascita" provinciaSelezionata={this.state.nomeProvinciaNascita} stile={this.state.bordoProvinciaNascita?validoDrop:nonDropValido}/>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Alert variant="info" style={{width:"99%"}}>
                                <b>Provincia di residenza *</b>
                            </Alert>
                        </Col>
                        <Col xs="12" className="breaker"/>
                        <Col> 
                            <Provincie impostazioneProvincia={this.impostazioneProvincia} sezione="residenza" provinciaSelezionata={this.state.nomeProvinciaResidenza} stile={this.state.bordoProvinciaResidenza?validoDrop:nonDropValido}/>
                        </Col>
                    </Row>
                    <hr/>
                    <FormLabel tipo={"number"} cl="formLabel" break={true} testo="Anno di nascita *" place={"Anno di nascita"} valore={this.state.AnnoDiNascita} funzione={(event)=>this.inputChanged(event)} nome="AnnoDiNascita" valido={this.state.bordoAnnoDiNascita}/>
                    <hr/>
                    <Row>
                        <Col>
                            <Alert variant="info" style={{width:"99%"}}>
                                <b>Seleziona équipe *</b>
                            </Alert>
                        </Col>
                        <Col xs="12" className="breaker"/>
                        <Col as={Alert}>
                        {this.getRadioGroup()}
                        </Col>
                    </Row>
                    <hr/>
                    <br/><br/>
                    <Button variant="success" name="invio" style={stileButton} onClick={this.invio}>
                        INVIO
                    </Button>
                    <br/><br/>
                    <Button variant="danger" style={stileButton} onClick={()=>this.props.setInterfaccia()}>
                        HOME
                    </Button>
            </div>
             }
            </div>
        )
}
}