import React,{useState,useContext} from 'react'
import {Alert} from 'react-bootstrap'

import Loader from '../../../Loader/Loader'
import {AuthContext} from '../../../authentication/index'

export default function PayloadTraining(props){

    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)
    const [inizializzato,setInizializzato]=useState(false)

    const {getTraining,getStoricoScriptPaziente,getScriptPaziente,getSchede} = useContext(AuthContext)

    const getterTraining = async () =>{
        var identificatore = {};
        identificatore.idpaziente = props.idpaziente;
        setIsLoading(true)
        try{
            await getTraining(identificatore)
            .then(function(){
                getterStorico(identificatore)
            })
        }catch(error){
            console.log(error)
            exitError()
        }
    }

    const getterStorico=async (identificatore)=>{
        try{
            await getStoricoScriptPaziente(identificatore)
            .then(function(){
                getterScriptPaziente(identificatore);
            })
        }catch(error){
            console.log(error)
            exitError()
        }       
    }

    const getterScriptPaziente=async (identificatore)=>{
        try{
            await getScriptPaziente(identificatore)
            .then(function(){
                doGetSchede()
            })
        }catch(error){
            console.log(error)
            exitError()    
        }

    }

    const doGetSchede =async () =>{
        var obj = {};
        obj.idPaziente = props.idpaziente;
        try{
            await getSchede(obj)
            .then(function(){
                setError(false);
                setInizializzato(true);
                setIsLoading(false);
                props.inizializzazioneConclusa()
            })
        }catch(error){
            console.log(error)
            exitError()
        }
    }

    const exitError=()=>{
        setError(true)
        setInizializzato(true)
        setIsLoading(false)
    }

    return (
        <>{
        inizializzato === true ? null
        :
            isLoading === true ? 
                <Loader pagina="Med"/> 
                :
                error === true ?
                    <Alert variant="danger">Errore</Alert>
                    :
                    getterTraining()
        }</>
    )
}