import React,{useState} from 'react'
import {Alert,Table} from 'react-bootstrap'

export default function ParametriDiEloquioInInserimento(props){

    const [numeroFrasi,setNumeroFrasi] = useState(0);
    const [numeroParole,setNumeroParole] = useState(0);

    const getCont = () =>{
        var principali = []
        var contaParole = 0;
        var conta = require('wordcount');
        if(props.elencoFrasi.length>0){
            props.elencoFrasi.forEach((fr)=>{
                if(fr.tipo===1){
                    if(fr.frase.trim()!=="") principali.push(fr.frase);
                    contaParole = contaParole + conta(fr.frase);
                }
            })
            if(numeroFrasi !== principali.length) setNumeroFrasi(principali.length);
            if(numeroParole !== contaParole) setNumeroParole(contaParole);
        }else {
            if(numeroFrasi !== 0) setNumeroFrasi(0);
            if(numeroParole !== 0) setNumeroParole(0);
        }
    }

    return(
        <div style={{border:"1px solid black"}}>
                            {getCont()}
                            <Alert variant="info">PARAMETRI DI ELOQUIO DELLO SCRIPT</Alert>
                            <Table striped>
                                <tbody>
                                    <tr key="frasi">
                                        <td key="nomeFrasi">NUMERO FRASI</td>
                                        <td key="valoreFrasi">{numeroFrasi}</td>
                                    </tr>
                                    <tr key="parole">
                                        <td key="nomeParole">NUMERO PAROLE</td>
                                        <td key="valoreParole">{numeroParole}</td>
                                    </tr>
                                    <tr key="lunghezza">
                                        <td key="nomeLunghezza">LUNGHEZZA MEDIA FRASI</td>
                                        <td key="valoreLunghezza">{numeroParole===0? "0" : (numeroParole/numeroFrasi).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
    )
}