import React from 'react'
import {Alert,Row,Col,Button} from 'react-bootstrap'
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FraseDistrattore(props){

/*props:
variabile : testo , indice(all'interno dell'array distrattore)
funzione
*/

return (
    <Alert>
    <Row>
        <Col md="8">{props.testo}</Col>
        <Col>
            <Button
            variant="danger" 
            onClick={()=>props.elimina()}>
              ELIMINA
              <FontAwesomeIcon icon={faTrash} style={{marginLeft:"2px"}}/>
          </Button>
        </Col>
    </Row>
    </Alert>
    )

}