import React,{useState,useContext} from 'react'
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Alert,Table} from 'react-bootstrap'
import {AuthContext} from '../../authentication'
import Pagination from './Pagination'


export default function StoricoScript(props){
    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 3

    const {storicoPaziente}=useContext(AuthContext);

    const componiElenco=()=>{
        
        if(storicoPaziente.length === 0){
        
            return (<Alert variant="danger">Nessun script assegnato</Alert>)
        
        }else{
            var date = []
            storicoPaziente.forEach((element)=>{
                date.push(element.dataAssegnazione);
            })
            date.sort()
            var ordinamentoDate=[]
            var storicoOrdinato = []
            for(var i=date.length-1 ; i>=0 ; i-- ){
                var index = i;
                ordinamentoDate.push(date[index]);
            }
            ordinamentoDate.forEach((data)=>{
                storicoPaziente.forEach((element)=>{
                    if(data===element.dataAssegnazione) storicoOrdinato.push(element)
                })
            })
            const rows = []
            var cont = 0
            
            storicoOrdinato.forEach((elemento)=>{
                
                if(cont>=(recordPerPagina*pagina)-recordPerPagina && cont < recordPerPagina*pagina){
                    var giorno = new Date(elemento.dataAssegnazione).getDate()
                    var mese = new Date(elemento.dataAssegnazione).getMonth() + 1
                    var anno = new Date(elemento.dataAssegnazione).getFullYear()
                    var data = giorno + "/" + mese + "/" + anno
                    var row=[]
                    row.push(<td key={cont+"TitoloStorico"}>{elemento.visibile ? elemento.titoloScript : "Informazione non disponibile"}</td>)
                    row.push(<td key={cont+"TopicStorico"}>{elemento.visibile ? elemento.topic : "Informazione non disponibile"}</td>)
                    row.push(<td key={cont+"AssociazioneStorico"}>{data}</td>)
                    row.push(<td key={cont+"IconStorico"} role="button" onClick={()=>props.infoScript(elemento)} style={{textAlign:"center"}}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        </td>)

                    rows.push(<tr key={cont+"RowStorico"}>{row}</tr>)
                }
                cont=cont+1
            })

                const headerComponents=[]
                const headerRow=[]
                headerComponents.push(<td key="HeaderTitoloStorico">TITOLO</td>)
                headerComponents.push(<td key="HeaderTopicStorico">TOPIC</td>)
                headerComponents.push(<td key="HeaderAssociazioneStorico">DATA ASSEGNAZIONE</td>)
                headerComponents.push(<td key="HeaderIconStorico" style={{textAlign:"center"}}>VEDI DETTAGLI</td>)     
                headerRow.push(<tr key="HeaderRoeStorico">{headerComponents}</tr>)

                return (
                    <>
                    <Table striped hover bordered>
                        <thead>{headerRow}</thead>
                        <tbody>{rows}</tbody>
                    </Table>
                    <Pagination totaleRecord={storicoPaziente.length} cambiaVisualizzazione={false} recordPerPagina={recordPerPagina} setPagina={(num)=>setPagina(num)}/>
                    </>
                )
        }
    }//componiElenco
return (
    <>{componiElenco()}</>
    )
}
