import { Viewer } from "../visualizzazioneComponents";
import React from 'react'

export default function DatiGenerali(props){

    return(
        <div>
            <Viewer testo="Titolo" valore={props.script.titolo} />
            <Viewer testo="Severita'" valore={props.script.livello} />
            <Viewer testo="Topic" valore={props.script.topic} />
        </div>
    )
}