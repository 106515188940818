import React,{useState,useContext} from 'react'
import {Alert,Button, Form,Row,Col} from 'react-bootstrap'
import { AuthContext } from '../../../../authentication'
import DropdownButton from '../../../../components/BasicComponents/DropdownButton'
import {Severita,Topic} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import ListaScript from '../TabellaScript/ListaScript'
import ModalContainer from '../InfoScript/ModalContainer'
import PayloadFocusScript from '../../PayloadMenu/PayloadFocusScript'
import './VisualizzaScript.css'

export default function VisualizzaScript(props){

    const[severita,setSeverita]=useState("")
    const[topic,setTopic]=useState("")
    const[titolo,setTitolo]=useState("")
    const[scriptAnteprima,setScriptAnteprima]=useState(0)
    const[scriptDaMostrare,setScriptdaMostare]=useState(false)

    const cambioTitolo = (event) =>{setTitolo(event.target.value)}

    const { elencoScript ,focusScript } = useContext(AuthContext)

    const TopicsElenco = []
    TopicsElenco.push("Visualizza Tutti")
    Topic.forEach((topic)=>{TopicsElenco.push(topic)})

    const SeveritaElenco = []
    SeveritaElenco.push("Visualizza Tutti")
    Severita.forEach((grado)=>{SeveritaElenco.push(grado)})

    const getScriptAnteprima=(script)=>{
        setScriptAnteprima(script)
    }

    const impostaScript=()=>{
        setScriptAnteprima(0)
        setScriptdaMostare(true)
    }


        return (
               <div>
                    <Alert style={{backgroundColor:"rgb(74, 218, 213)"}}>
                        <h5>
                        <Row>
                            <Col md="4">
                                <Form.Control type="text" placeholder="Titolo" value={titolo} onChange={(event)=>cambioTitolo(event)}/> 
                            </Col>
                            <Col md="1" style={{textAlign:"end"}}>
                                <b style={{verticalAlign:"middle"}}  className="vediInfo">Topic:</b>
                            </Col>
                            <Col md="3">
                                <DropdownButton cl="drop" testoPredefinito="Seleziona Topic" opzioni={TopicsElenco}  funzione={(topic)=>setTopic(topic)}/>
                            </Col>
                            <Col md="1"  style={{textAlign:"end"}}>
                                <b style={{verticalAlign:"middle"}} className="vediInfo">Severità:</b>
                            </Col>
                            <Col md="3">
                                <DropdownButton cl="drop" testoPredefinito="Seleziona Severità" opzioni={SeveritaElenco} funzione={(severity)=>setSeverita(severity)}/>
                            </Col>
                        </Row>
                        </h5>
                    </Alert>
                    <br/>
                    <ListaScript setScriptAnteprima={(script)=>getScriptAnteprima(script)} elencoScript={elencoScript} filtroTitolo={titolo} filtroSeverita={severita} filtroTopic={topic}/>
                    <br/>
                    {scriptAnteprima!==0?
                    <PayloadFocusScript idScript={scriptAnteprima.idscript} setScriptAnteprima={()=>impostaScript()}/>
                    :
                    null
                    }
                    {scriptDaMostrare===false?null:<ModalContainer fine={()=>setScriptdaMostare(false)}/>}
                    <br/><br/><br/>
                    <Row>
                        <Col md="8"/>
                        <Col>
                                <Button variant="danger" style={{textAlign:"center",width:"100%"}} onClick={()=>props.setInterfaccia()}>
                                    HOME
                                </Button>
                        </Col>
                    </Row>
                </div> )
        
}