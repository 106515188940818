import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const PazienteContext = React.createContext();

const INITIAL_STATE = {
    jwt:null,
    isAuth:false,
    scriptTraining:null
}

class PazienteProvider extends Component {
    state = INITIAL_STATE;

  //SET_STATE
  setStato = (isAuth,jwt) => {
      this.setState({
          isAuth : isAuth,
          jwt:jwt
    })
    //axios.defaults.headers.common['Authorization'] = "Bearer " + jwt;
  }
  
  //GESTIONE TRAINING
  salvaTraining = async (training) =>{
    await api.salvaTraining(training);
  }


  //PRENDERE LO SCRIPT
  getScriptTraining = async (AuthOld) =>{
    const { data } = await api.getScriptTraining();
    this.setState({
      scriptTraining : data
    })

    this.impostaSessionStorage(AuthOld)
  }

  //Imposta session storage
  impostaSessionStorage = (AuthOld) =>{
    var stringifyAuth = JSON.stringify(AuthOld);
    var oldContext = JSON.stringify(this.state);
    sessionStorage.setItem('pazCont',oldContext);
    sessionStorage.setItem('authCont',stringifyAuth);
  }

  //Imposta old context
  refreshContext = (paziente,dati) =>{
    this.setState({...paziente})
    delete axios.defaults.headers.common['Authorization'];
    axios.defaults.headers.common['Authorization'] = "Bearer " + dati.data.jwt;
    
  }

  logout = async () => {
    this.setState(INITIAL_STATE);
    axios.defaults.headers.common['Authorization'] = undefined;
    sessionStorage.removeItem('authCont');
    sessionStorage.removeItem('pazCont');
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
  }

  render(){
      return(
      <PazienteContext.Provider value={{...this.state,
        getScriptTraining:this.getScriptTraining,
        salvaTraining:this.salvaTraining,
        setStato:this.setStato,
        impostaSessionStoragePaziente:this.impostaSessionStorage,
        refreshContextPaziente:this.refreshContext,
        logoutPaziente:this.logout
        }}>
          {this.props.children}
      </PazienteContext.Provider>
      )
  }

}

const PazienteConsumer = PazienteContext.Consumer
export { PazienteProvider, PazienteConsumer, PazienteContext }