import React,{useState,useContext} from 'react'
import {AuthContext} from '../../../authentication'
import {Alert} from 'react-bootstrap'
import Loader from '../../../Loader/Loader'
import VisualizzaScript from '../Menu/SelezioneMenu/VisualizzaScript'
export default function PayloadAllScript(props){

    const [chiamataApi,setChiamataApi]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)

    const { getAllScript } = useContext(AuthContext);

    const doGetAllScript = async () =>{

        setIsLoading(true);

        try{
            await getAllScript()
            .then(function(){
                setError(false)
                setChiamataApi(true)
                setIsLoading(false)
            })
        }catch(error){
            console.log(error)
            setError(true)
            setChiamataApi(true)
            setIsLoading(false)
        }
    }

    return (isLoading === true ?
                <Loader pagina="Med"/>
                :
                error === true ?
                    <Alert variant="danger">Errore nel caricamento degli script</Alert>
                    :
                    chiamataApi === false ? 
                        doGetAllScript()
                        :
                        <><VisualizzaScript setInterfaccia={()=>props.setInterfaccia()}/></>
    )


}