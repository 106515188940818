import React from 'react'
import {Alert} from 'react-bootstrap'
export default function Unauthorized(){
    return(
    <div>
        <Alert variant="danger" style={{marginTop:"5px",height:"300px"}}>
            <h1 style={{marginTop:"20px"}}>Error 401 : permission denied</h1>
            <h3 style={{marginTop:"80px"}}><a href="/">Click here</a> to be redirected to the login page</h3>
        </Alert>
    </div>
    )
}