import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap'
import InfoButton from './InfoButton'
import Modal from 'react-modal'
import './IstruzioniVideo.css'

Modal.setAppElement('#root')
export default function IstruzioniVideo(props){

    const [modalIsOpen,setModalIsOpen]=useState(true)


    const chiudi=()=>{
        setModalIsOpen(false)
        props.setVediInfo()
    }

    return(
    <Modal 
        isOpen={modalIsOpen}
        onRequestClose={()=>setModalIsOpen(false)} 
        shouldCloseOnOverlayClick={false} 
        ariaHideApp={true}
    >
        <h3>
            <div className="iconaChiudi">
               <FontAwesomeIcon icon={faTimesCircle} role="button" onClick={()=>chiudi()}/> 
            </div>
            <br/>
            <strong style={{paddingLeft:"10px"}}>PREMERE :</strong>
            <InfoButton pulsante={{colore:"primary",testo:"RIPRODUCI"}} descrizione="Per riprodurre il video" />
            <InfoButton pulsante={{colore:"danger",testo:"STOP"}} descrizione="Per mettere in pausa la riproduzione del video" />
            <InfoButton pulsante={{colore:"info",testo:"RIPETI"}} descrizione="Per tornare indietro di 15 secondi" />
            <InfoButton pulsante={{colore:"success",testo:"RESTART VIDEO"}} descrizione="Per far ricominciare la riproduzione del video" />
            {/*<InfoButton pulsante={{colore:"warning",testo:"AVVIA REGISTRAZIONE"}} descrizione="Per iniziare la registrazione vocale" />*/}
            <InfoButton pulsante={{colore:"danger",testo:"FINE TRAINIG"}} descrizione="Per ultimare la sessione di training" />
        </h3>
        <br/><br/>
        <div className="divButtonChiudi">
            <Button variant="danger" onClick={()=>chiudi()} className="buttonChiudi" >CHIUDI</Button>
        </div>
    </Modal>
)}