import React,{useState,useContext} from 'react'
import {AuthContext} from '../../../../authentication'
import Loader from '../../../../Loader/Loader'
import {Alert,Button} from 'react-bootstrap'
import Uploader from '../../../VimeoComponents/Uploader/Uploader'

export default function SalvataggioScript(props){

    const { salvaScript , getScriptPaziente } = useContext(AuthContext)

    const [chiamataApi,setChiamataApi]=useState(false);
    const [isLoading,setIsLoading]=useState(false)
    const [error,setError]=useState(false)
    const [uploadEseguito,setUploadEseguito] = useState(false)
    const [uploadError,setUploadError] = useState(false)
    const [scriptDaSalvare,setScriptDaSalvare] = useState(props.script)
    const [messaggioErrore,setMessaggioErrore] = useState("");

    const descrizione = props.script.titolo+" - "+new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()

    const stileButton = { width:"20%" , marginLeft:"75%"}

    const doSalvaScript = async () =>{
        setChiamataApi(true)
        setIsLoading(true)
        try {
            await salvaScript(scriptDaSalvare)
            .then(function(){
                doGetScriptPaziente()
            })
        }catch(error){
            console.log(error)
            setChiamataApi(true)
            setError(true)
            setIsLoading(false)
            setMessaggioErrore("Errore nel salvataggio dei dati,riprovare")
        }
    }

    const doGetScriptPaziente =async () =>{
        var identificatore = {};
        identificatore.idpaziente = props.idpaziente;
        try{
            await getScriptPaziente(identificatore)
            .then(function(){
                setError(false)
                setChiamataApi(true)
                setIsLoading(false)
            })
        }catch(error){
            console.log(error)
            setChiamataApi(true)
            setError(true)
            setIsLoading(false)
            setMessaggioErrore("Errore nel salvataggio dei dati,riprovare")
        }
    }

    const uploadErrorFunction = () =>{
        setUploadError(true)
        setMessaggioErrore("Errore nell'upload del video,riprovare")
    }

    const uploadEseguitoFunction = (linkVideo) =>{
        var nuovoScript = {...props.script,urlVideo:linkVideo}
        setScriptDaSalvare(nuovoScript);
        setUploadError(false);
        setUploadEseguito(true);
    }

    const messErrore = <div><Alert variant="danger"><b>{messaggioErrore}</b></Alert><br/><Button variant="danger" onClick={()=>props.fine()} style={stileButton}>FINE</Button></div>

    const messCorretto =<div><Alert variant="success"><b>Salvataggio avvenuto con successo</b></Alert><br/><Button variant="danger" onClick={()=>props.fine()} style={stileButton}>FINE</Button></div> 



    return(
        <div>
        {
        uploadError ? messErrore :
            uploadEseguito ?
                <>{/*CHIAMATA API DI SALVATAGGIO DATI SU DB*/}
                    {isLoading===true ? <Loader pagina="Med"/> :
                        chiamataApi===false ? doSalvaScript() :
                            error ===true ? messErrore : messCorretto  
                    }
                </>    
                :
                <Uploader fileVideo={props.file} titolo={scriptDaSalvare.titolo} descrizione={descrizione} 
                erroreUpload={()=>uploadErrorFunction()} uploadEseguito={(link)=>uploadEseguitoFunction(link)} />
        }
        </div>
        )

}