import React from 'react'
import {Form,Alert,Row,Col} from 'react-bootstrap'

export default function Viewer(props){

    return (
        <Row>
            <Col  md="6" xs="12">
                <Alert variant="info">
                    {props.testo}
                </Alert>
            </Col>
            <Col md="6" xs="12">
                <Form.Control readOnly defaultValue={props.valore}/>
            </Col>
        </Row>
    )
}