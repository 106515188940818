import React,{useState,useContext} from 'react'
import HeaderDottore from '../Interfaccia-Medico/StaticComponents/HeaderDottore'
import {AuthConsumer,AuthContext} from '../authentication'
import HomePage from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/HomePage'
import NuovoPaziente from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/RegistraNuovoPaziente'
import PayloadAllScript from '../Interfaccia-Medico/Components-Medico/PayloadMenu/PayloadAllScript'
import GestionePazienti from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/GestionePazienti'
import Unauthorized from './Unauthorized'
import { Redirect } from 'react-router-dom'
export default function HomeDottore(props){
    const [interfaccia,setInterfaccia]=useState(0);
    const [reindirizza,setReindirizza]=useState(false)
    const {logout,Pazienti,refreshContext} = useContext(AuthContext);
    /*
    MENU :
    0-Collegamenti + label di ricerca rapida
    1-Nuovo paziente
    2-Gestione pazienti
    3-Libreria script
    */
    window.addEventListener('load', (event) => {
        var dati = sessionStorage.getItem('authCont');
        if(dati!==null){
            var pars = JSON.parse(dati)
            refreshContext(pars);
        }
    });

    const doLogout = () =>{
        logout();
        return <Redirect to="/"/>
    }

    const getBody=()=>{
        if(AuthConsumer._currentValue.role===1 || AuthConsumer._currentValue.role===3) {
            return (
                <>
                    <HeaderDottore user={props.user} setInterfaccia={(selezione)=>setInterfaccia(selezione)} setReindirizza={()=>setReindirizza(true)}/>
                    {interfaccia===0 || interfaccia===4 ?<HomePage interfaccia={interfaccia} setInterfaccia={(selezione)=>setInterfaccia(selezione)}/>:null}
                    {interfaccia===1?<NuovoPaziente setInterfaccia={()=>setInterfaccia(0)} pazienti={Pazienti}/>:null}
                    {interfaccia===2?<GestionePazienti setInterfaccia={()=>setInterfaccia(0)} pazienti={Pazienti}/>:null}
                    {interfaccia===3?<PayloadAllScript setInterfaccia={()=>setInterfaccia(0)}/>:null}
                </>
            )
        }//ifAuthorized
        else{
            let oldContext = JSON.parse(sessionStorage.getItem('authCont'))
            if(oldContext === null) return <Unauthorized/>
            else if(oldContext.isAuth && oldContext.role === 1) refreshContext(oldContext)
            else return <Unauthorized/>
        } 
    }//getBody()
    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            {reindirizza===false? getBody() : doLogout()}
        </div>
    )  

}