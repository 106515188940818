import React,{useState} from 'react'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause} from '@fortawesome/free-solid-svg-icons';
import './VimeoPlayer.css'

export default function VimeoPlayer(props){

    const [playerObject,setPlayerObject] = useState(null);

    const getVideoObject = (event) =>{
        //GESTIONE DEL VIDEO TRAMITE L'OGGETTO CREATO DAL PLAYER
        setPlayerObject(event);
        props.setReady();
    }

    const onPlay = () =>{
        //INIZIALIZZAZIONE LATO PAZIENTE
        //AFASIA -> ORA INIZIO
    }

    const onPause = () =>{
        //PER TENERE CONTO DEL NUMERO DELLE PAUSE
    }

    const restart = () =>{
        //PER FAR RIPARTIRE IL VIDEO
        //AFASIA -> INIZIO NUOVA SESSIONE
        if(playerObject !== null) playerObject.seekTo(0);
        props.restartDone();
    }

    const ripeti = () =>{
        //FAR TORNARE INDIETRO IL VIDEO (secondiIndietro)
        const secondiIndietro = 5
        var secondoAttuale = playerObject.getCurrentTime()
        if(secondoAttuale > secondiIndietro ){
            var nuovoSecondo = secondoAttuale - secondiIndietro;
            playerObject.seekTo(nuovoSecondo);
        }else{
            playerObject.seekTo(0);
        }
        props.ripetizioneDone()
    }

    return(
        <div className='embed-container'>
            {props.restart ? restart() : null }
            {props.ripeti ? ripeti() : null }
            <ReactPlayer url={props.url} controls={false} playing={props.playing} onPause={()=>onPause()} onPlay={()=>onPlay()} onReady={(event)=>getVideoObject(event)} onError={()=>props.setErrore()} loop={true} />
            <div className="preventAction" />
            {props.playing ? null : 
            <div className="pausaViewer">
                <div className="scritta">
                <FontAwesomeIcon icon={faPause}/>
                <br/>IN PAUSA
                </div>
            </div>}
        </div>
        )
}