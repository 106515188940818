import React,{useState} from 'react'
import VimeoPlayer from './VimeoPlayer'
import {Spinner,Alert} from 'react-bootstrap'
import './VimeoComponent.css'
export default function VimeoComponent(props){

    const [ready,setReady] = useState(false);
    const [errore,setErrore] = useState(false);

    const fineTraining = (dati) =>{
        props.concludiTraining(dati)
    }

    return (
        <div>
            {!errore ?
                <div>
                    <div className={ready ? null : "hide" }>
                        <VimeoPlayer url={props.url} idScript={props.idScript} fineTraining={(dati)=>fineTraining(dati)} setReady={()=>setReady(true)} setErrore={()=>setErrore(true)}/>
                    </div>
                    {!ready ? 
                    <div className="loader">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        <br/>
                        <h3>Caricamento video in corso...</h3>
                    </div>
                    :
                    null}
                </div>
                :
                <Alert variant="danger"> Errore nel caricamento del video,provare piu' tardi. </Alert>
            }
        </div>
    )
}