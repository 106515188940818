import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TabellaRicerca from '../Components-Medico/Menu/TabellaPazienti/TabellaRicerca'
import {Form,Row,Alert,Col} from 'react-bootstrap'
import './LabelRicercaDottore.css'


export default function LabelRicercaDottore(props){

    const[valoreFiltro,setValoreFiltro]=useState("")

    const cambioFiltro=(event)=>{
        setValoreFiltro(event.target.value.toUpperCase())
    }
    
    return(
        <>
        <Alert variant="primary" className="Container">
            <Row>
                <Col xs="1" className="colContainer">
                    <Form.Label className="FormLabel">
                        <FontAwesomeIcon icon={faSearch} className="icona"/>
                    </Form.Label>
                </Col>
                <Col>
                    <Form.Control type="text" placeholder="ID Paziente" className="filtro" value={valoreFiltro} onChange={(event)=>cambioFiltro(event)}/>
                </Col>
            </Row>
        </Alert>
        {valoreFiltro!=="" ? <TabellaRicerca filtro={valoreFiltro}/>: null}
        </>
    );
}//class