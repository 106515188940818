import React,{useContext,useState,useRef} from 'react'
import {Redirect} from 'react-router-dom'
import {AuthConsumer} from '../authentication'
import {PazienteContext,PazienteConsumer} from '../context/PazienteContext'
import { AdminContext } from '../context/AdminContext'
import {Button, Container, Form, FormGroup,Col,Row,Badge} from 'react-bootstrap'
import Loader from '../Loader/Loader'
import './Style.css'

export default function FormComponent(props){

    const [richiestaInoltrata,setRichiestaInoltrata] = useState(false)

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    window.addEventListener('keydown',(event)=>{
        if(window.location.pathname === "/") {
            if(event.key === 'Enter') invio()
        }
    })

    const {setStato} = useContext(PazienteContext)
    const {initState} = useContext(AdminContext)

    const checkAuth=()=>{
        if(AuthConsumer._currentValue.role===1 || AuthConsumer._currentValue.role===3) {
            if(props.pazientiEstratti===false && richiestaInoltrata === false) {
                setRichiestaInoltrata(true);
                props.doGetPazienti();
            }
            else {
               if(AuthConsumer._currentValue.protocolli !== null) return <Redirect to="/HomeDottore"/>
            }
        }
        if(AuthConsumer._currentValue.role===2) {
            if(props.scriptEstratto===false && richiestaInoltrata === false) {
                setRichiestaInoltrata(true);
                var authorized = AuthConsumer._currentValue.isAuth;
                var jwt = AuthConsumer._currentValue.data.jwt;
                setStato(authorized,jwt);
                props.doGetScriptTraining();
            }else {
                if(PazienteConsumer._currentValue.scriptTraining !== null) return <Redirect to="/HomePaziente"/>
            }
        }
        if(AuthConsumer._currentValue.role===0) {
            var auth = AuthConsumer._currentValue.isAuth;
            var data = AuthConsumer._currentValue.data;
            initState(auth,data);
            if(!props.utentiEstratti) props.doGetUtenti();
            else return <Redirect to="/Admin" />
        }
    }

    const invio=()=>{
        if(usernameRef.current !== null && passwordRef.current !== null){
        var usr = usernameRef.current.value
        var psw = passwordRef.current.value
        props.doLogin(usr,psw)
        }
    }
    
    return(
        props.loading && props.errore===null ?
        <div className="loaderLoginContainer">
            <Loader/>
        </div>
        :
        <div className="containerLogin">
            {checkAuth()}
            <Container>
                <div style={{marginTop:"20px",marginLeft:"20%",marginRight:"20%",color:"white"}}>{/*Div per l'inserimento delle credenziali*/}
                    <Form>
                        <FormGroup>
                            <Form.Label>
                                Username
                            </Form.Label> 
                            <Form.Control ref={usernameRef} type="text" placeholder="inserisci nome utente" id="username" style={{width:"100%"}}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>
                                Password
                            </Form.Label> 
                            <Form.Control ref={passwordRef} type="password"  autoComplete="on" placeholder="inserisci la password" id="password" style={{width:"100%"}}/>
                        </FormGroup>
                        {props.errore!==null?
                            <>{richiestaInoltrata? setRichiestaInoltrata(false) : null}
                                <Badge variant="danger" style={{width:"100%"}}>
                                    <h6>
                                        {props.errore}
                                    </h6>
                                </Badge>
                                <br/>
                            </>
                        :null}
                        <br/>
                        <Row>
                            <Col>
                                <Button variant="success" onClick={()=>invio()} style={{width:"100%"}}>
                                    LOGIN
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </div>
    )    
}