import React from 'react'

export function getDiagnosi(codice){
    var text=""
    if(codice===1) text="PPA non fluente"
    if(codice===2) text="PPA logopenica"
    if(codice===3) text="PPA semantica"
    if(codice===4) text="altro"
    return text
}

export function getGradoAfasia(codice){
    var text=""
    if(codice===1) text="Lieve"
    if(codice===2) text="Moderata"
    if(codice===3) text="Grave"
    return text
}

export function getDataEsordio(codice){
    var text=""
    if(codice===1) text="< 6 mesi"
    if(codice===2) text="> 6 mesi"
    if(codice===3) text="< 3 anni"
    if(codice===4) text="> 3 anni"
    return text
}