import React,{useContext,useState} from 'react'
import { Redirect } from 'react-router-dom'
import HomePagePaziente from '../Interfaccia-Paziente/Components-Paziente/HomePage/HomePagePaziente'
import Unauthorized from './Unauthorized'
//import context
import {AuthConsumer,AuthContext} from '../authentication'
import { PazienteContext } from '../context/PazienteContext'

export default function HomePaziente(){

    const {refreshContext,logout} = useContext(AuthContext);
    const {refreshContextPaziente,logoutPaziente} = useContext(PazienteContext);
    const [doLogout,setDoLogout] = useState(false)
    
    window.addEventListener('load', (event) => {
        var dati = sessionStorage.getItem('authCont');
        var parsAuth = JSON.parse(dati)
        if(dati!==null){
            refreshContext(parsAuth);
        }
        var datiPazi = sessionStorage.getItem('pazCont');
        if(datiPazi!==null){
            var pars = JSON.parse(dati)
            refreshContextPaziente(pars,parsAuth)
        }
    });

    const getBody=()=>{
        if(AuthConsumer._currentValue.role===2) {
            return <HomePagePaziente doLogout={()=>setDoLogout(true)}/>
        }//ifAuthorized
        else{
            let oldContext = JSON.parse(sessionStorage.getItem('authCont'))
            if(oldContext === null) return <Unauthorized/>
            else if(oldContext.isAuth && oldContext.role === 2) {
                refreshContext(oldContext)
                var oldContextPazienti = JSON.parse(sessionStorage.getItem('pazCont'))
                refreshContextPaziente(oldContextPazienti,oldContext)
            }else return <Unauthorized/>
        } 
    }//getBody()

    const logoutFunction = () =>{
        logoutPaziente();
        logout();
        return <Redirect to="/" />
    }

    return(
        <div>
            {doLogout?logoutFunction(): getBody()}
        </div>
    )
}