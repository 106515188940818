import React,{useState} from 'react'
import {Alert,Row,Col,Button} from 'react-bootstrap'

export default function VisualizzazioneDistrattori(props){

    const [vediDistrattori,setVediDistrattori]=useState(false)

    const getDistrattori = () =>{
        var body=[]
        var cont=0
        if(props.distrattori.length>0){
            props.distrattori.forEach((distrattore)=>{
                    body.push(<li key={cont}>{distrattore}</li>)
                    cont++
            })
        }else{
            body.push(<p key="nessunDistrattore">Questa frase non ha distrattori associati</p>)
        }
        return <ol>{body}</ol>
    }

    return(
        <>
        <Alert variant="info">
            <Row>
                <Col lg="9" md="7" xs="12">
                    {props.testo}
                </Col>
                <Col className="distrattoriButton">
                    <Button onClick={()=>setVediDistrattori(!vediDistrattori)}>Distrattori</Button>
                </Col>
            </Row>
        </Alert>
        {
            vediDistrattori===true?
                <>
                    {getDistrattori()}
                </>:null
        }
        </>
    )
}