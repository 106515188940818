import React,{useState} from 'react'
import {Dropdown,Button} from 'react-bootstrap'
import ElencoProvince from '../../../../Dati/Province.json'
import './Style.css'

export default function Provincie(props){
const province = []
const [testo,setTesto]=useState(props.provinciaSelezionata===""?"Seleziona Provincia di "+props.sezione:props.provinciaSelezionata) 
const [vediElenco,setVediElenco]=useState(false)
const [filtro,setFiltro]=useState("")
function elenco(){
    ElencoProvince.forEach((provincia)=>{
        var nomeProvincia=provincia.nome.toUpperCase()
        var filtroConfronto = filtro.toUpperCase()
      if(nomeProvincia.indexOf(filtroConfronto)>-1)  
        province.push(<li key={provincia.id} role="button" onClick={()=>onSelect(provincia.nome,props.sezione)}>{nomeProvincia}</li>)
    })
}

function onSelect(nome,sezione){
    props.impostazioneProvincia(nome,sezione)
    setTesto(nome)
    setVediElenco(false)
}

return(
<>{elenco()}
    <Button style={props.stile} onClick={()=>setVediElenco(!vediElenco)}>{testo}</Button>
    <br/>
    {vediElenco?
    <Dropdown style={{height:"250px",flex: 1,overflowY:"scroll"}}>
        <Dropdown.Toggle style={{width:"100%"}}>
          <input type="text" placeholder="cerca" style={{width:"90%"}} onChange={(event)=>setFiltro(event.target.value)}/>
        </Dropdown.Toggle>
    <Dropdown.Menu>
        {province}
    </Dropdown.Menu>
    </Dropdown>
    :null
}
</>)
}