import React from 'react';
import Navigation from './Navigation'
import {AuthProvider} from './authentication'
import {PazienteProvider} from './context/PazienteContext'
import {PasswordProvider} from './context/PasswordContext'
import {AdminProvider} from './context/AdminContext'

function App() {
  return (
    <AuthProvider>

      <AdminProvider>

        <PazienteProvider>

          <PasswordProvider>

              <Navigation/>

          </PasswordProvider>

        </PazienteProvider>

      </AdminProvider>
      
    </AuthProvider>
  );
}

export default App;
