import React,{useState} from 'react'
import {Button ,  Alert , Row ,Col} from 'react-bootstrap'
import FrasePrincipale from './FrasePrincipale'
import FormLabel from '../../../../components/FormComponents/FormLabel'
import FormDropdown from '../../../../components/FormComponents/FormDropdown'
import {Severita,Topic} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import ParametriDiEloquioInInserimento from '../TabellaScript/ParametriDiEloquioInInserimento'
import './InterfacciaModificaScript.css'

export default function InterfacciaModificaScript(props){
    /*props: 
    variabile: script
    funzione: chiudi interfaccia 
    */
    const FRASIdefault = props.script.elencoFrasi
    const [frasiScript,setFrasiScript] = useState(null);
    const [frasiPrincipaleScript,setFrasiPrincipaleScript] = useState(null);
    const [distrattoriScript,setDistrattoriScript] = useState(null);
    const [titolo,setTitolo]=useState(props.script.titoloScript);
    const [severita,setSeverita]=useState(props.script.severita)
    const [topic,setTopic]=useState(props.script.topic)

    //Modifica frasi dello script

    //TODO:Calcolo parametri & invio nuovi dati

    const aggiungiFrase = (idFrase,valore) =>{
        const nuovoElencoFrasi = []
        //idFrase n_sillabe frase id
        var objectFrase={};
        var numMax = 1
        if(frasiScript.length !== undefined && frasiScript.length > 0){
            frasiScript.forEach((fr)=>{
                nuovoElencoFrasi.push(fr)
                if(fr.id > numMax) numMax=fr.id
            })
        }
        objectFrase.id = numMax+1;
        objectFrase.n_sillabe = 1;
        objectFrase.frase = idFrase === 0 ? "" : valore;
        objectFrase.idFrase = idFrase === 0 ? numMax+1 : idFrase;
        objectFrase.tipo = idFrase === 0 ? 1 : 2;
        nuovoElencoFrasi.push(objectFrase);
        setFrasiScript(nuovoElencoFrasi);
    }

    const eliminaFrasePrincipale = (id) =>{
        var nuovoElencoFrasi = []
        var nuovoElencoPrincipali = []
        var nuovoElencoDistrattori = []
        frasiScript.forEach((fr)=>{
            if(fr.idFrase!==id) nuovoElencoFrasi.push(fr);
            if(fr.idFrase!==id && fr.tipo===1) nuovoElencoPrincipali.push(fr);
            if(fr.idFrase!==id && fr.tipo===2) nuovoElencoDistrattori.push(fr);
        })

        if(frasiScript!==nuovoElencoFrasi) {
            setFrasiScript(nuovoElencoFrasi);
            setFrasiPrincipaleScript(nuovoElencoPrincipali);
            setDistrattoriScript(nuovoElencoDistrattori);
        }
    }

    const modificaFrasePrincipale = (id,valore) =>{
        var nuovoElencoFrasi = []
        frasiScript.forEach((fr)=>{
            if(fr.id!==id) nuovoElencoFrasi.push(fr);
            else{
                fr.frase = valore
                nuovoElencoFrasi.push(fr);
            }
        })
        if(frasiScript!==nuovoElencoFrasi) setFrasiScript(nuovoElencoFrasi)
    }

    const modificaSillabe = (id,valore) =>{
        var nuovoElencoFrasi = []
        frasiScript.forEach((fr)=>{
            if(fr.id!==id) nuovoElencoFrasi.push(fr);
            else{
                fr.n_sillabe = valore
                nuovoElencoFrasi.push(fr);
            }
        })
        if(frasiScript!==nuovoElencoFrasi) setFrasiScript(nuovoElencoFrasi)
    }

    const eliminaDistrattore = (id) =>{
        var nuovoElencoFrasi = []
        frasiScript.forEach((fr)=>{
            if(fr.id!==id) nuovoElencoFrasi.push(fr);
        })
        if(frasiScript!==nuovoElencoFrasi) setFrasiScript(nuovoElencoFrasi)
    }

   const getBody = () =>{
       if(props.script){
            if(  props.script.elencoFrasi.length > 0){
                var frasiAttuali 
                if(frasiScript === null) frasiAttuali = FRASIdefault
                else frasiAttuali = frasiScript

                if(  frasiAttuali.length > 0){
                const body=[]
                
                var elencoFrasiPrincipali = [];
                var elencoFrasiDistrattori = [];
                const elencoDistrattori = [];
                frasiAttuali.forEach(fr=>{
                    if(fr.tipo === 1) elencoFrasiPrincipali.push(fr);
                    if(fr.tipo === 2) elencoFrasiDistrattori.push(fr);
                });
                elencoFrasiPrincipali.forEach(fr=>{
                    var elencoDistrattoriFrase = []
                    elencoFrasiDistrattori.forEach((distrattore)=>{
                        if (distrattore.idFrase === fr.idFrase) elencoDistrattoriFrase.push(distrattore) 
                    }) 
                    elencoDistrattori.push(elencoDistrattoriFrase);
                    body.push(<FrasePrincipale key={fr.idFrase} frasePrincipale={fr} distrattori={elencoDistrattoriFrase} 
                        eliminaFrasePrincipale={(id)=>eliminaFrasePrincipale(id)} modificaFrasePrincipale={(id,valore)=>modificaFrasePrincipale(id,valore)} 
                        modificaNumeroSillabe={(id,valore)=>modificaSillabe(id,valore)} aggiungiFrase={(idFrase,valore)=>aggiungiFrase(idFrase,valore)}
                        eliminaDistrattore={(id)=>eliminaDistrattore(id)}
                        />)
                })

                if(frasiPrincipaleScript===null){
                    setFrasiPrincipaleScript(elencoFrasiPrincipali);
                    setDistrattoriScript(elencoDistrattori);
                    setFrasiScript(FRASIdefault);
                }

                return <div className="frasiContainer">{body}</div>
                }else return <Alert variant="danger">Nessuna frase inserita</Alert>

            }else return <Alert variant="danger">Errore nella ricezione dei dati,si prega di ricaricare la pagina</Alert> 
       
        }else return <Alert variant="danger">Errore nella ricezione dei dati,si prega di ricaricare la pagina</Alert>      
   }

   const invio = () =>{
       var errori = "Errore:"
       var errore = false
       var erroriFrasi = "\nErrore nelle segueni frasi:"
       var errFrasi = false
       if(titolo.trim() === "" ) {
           errori=errori+"\nInserisci il titolo dello script"
           errore = true
       }
       if(!frasiScript || frasiScript.length===0){
           errori=errori+"\nInserisci almeno una frase"
           errore = true
       }
       if(frasiScript){
            var cont=1
            frasiScript.forEach((fr)=>{
                if(fr.frase.trim()==="") {
                    erroriFrasi=erroriFrasi+"\nFrase in posizione "+cont+" non valida"
                    errFrasi = true;
                }
                if(fr.tipo===1 && (fr.n_sillabe<0)) {
                    erroriFrasi=erroriFrasi+"\nNumero sillabe della frase in posizione "+cont+" non valido"
                    errFrasi = true;
                }
                if(fr.tipo===1) cont++
        });
       }

       if(errore === true || errFrasi === true){
           var messaggio = ""
           if(errore === true) messaggio=messaggio+errori
           if(errFrasi === true) messaggio=messaggio+erroriFrasi
           alert(messaggio)
       }else{
          invioDati();
       }
   }

   const invioDati = () =>{
    //COMPONIMENTO
    var frasiDaInviare = [];
    var ordinamento = [];
    frasiScript.forEach((fr)=>{if(fr.tipo===1) ordinamento.push(fr.idFrase) })
    ordinamento.forEach((index)=>{
        var componimentoFrase = {};
         var distrattoriFrase=[];
         //individuazione frasi
         frasiScript.forEach((fr)=>{
             if(fr.idFrase===index && fr.tipo===1) { 
                 componimentoFrase.testo=fr.frase;
                 componimentoFrase.sillabe=fr.n_sillabe;
             }
             if(fr.idFrase===index && fr.tipo===2) distrattoriFrase.push(fr.frase);
         });
         componimentoFrase.distrattori=distrattoriFrase;
         frasiDaInviare.push(componimentoFrase);
     }) 
    var script = {};
     script.titolo = titolo;
     script.severita = severita;
     script.topic = topic;
     script.frasi = frasiDaInviare;
     script.id = props.script.idScript;
     script.urlVideo = props.script.urlVideo;
     //NB:---NON--- E' DA CONVERTIRE IN STRING 
     //var json = JSON.stringify(script)
     props.invioDati(script);
   }

   return (
   <><br/>
   <Row>
        <Col md="8">
            <FormLabel testo="Titolo" tipo="text" name="titolo" valido={titolo!==""?true:false} funzione={(event)=>setTitolo(event.target.value)} valore={titolo} cl="flabel"/>
            <FormDropdown testo="Severita'" testoDropdown={severita} opzioni={Severita} funzione={(valore)=>setSeverita(valore)} cl="dropdown"/>
            <FormDropdown testo="Topic" testoDropdown={topic} opzioni={Topic} funzione={(valore)=>setTopic(valore)} cl="dropdown"/>
        </Col>
       <Col>
        <ParametriDiEloquioInInserimento elencoFrasi={frasiScript} />
       </Col>
   </Row>
   {getBody()}
   <Button variant="primary" style={{width:"30%",marginLeft:"35%"}} onClick={()=>aggiungiFrase(0)}>
        AGGIUNGI FRASE
   </Button>
   <br/><br/>
   <Button variant="success" onClick={()=>invio()} style={frasiScript && frasiScript.length>0 ? {width:"20%",marginLeft:"70%"} : {display:null}}>INVIO</Button>
   <br/><br/>
   <Button variant="danger" onClick={()=>props.fine()} style={{width:"20%",marginLeft:"70%"}}>ANNULLA</Button>
   </>
   )
}