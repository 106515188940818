import React,{ useState } from 'react'
import Attivita from './Attivita'
import LogoCBIM from '../../assets/cbim-logo-small.png'
import InvioDati from './InvioDati/InvioDati'
import {PazienteConsumer} from '../../context/PazienteContext'
import {Alert} from 'react-bootstrap'
import './Style.css'

export default function DefinizioneAttivita(props){
    const [audio,setAudio]=useState("")
    const [FineAttivita,setFineAttivita]=useState(false)
    const [messaggio,setMessaggio]=useState("")
    const [datiTraining,setDatiTraining]=useState()

    const concludiTraining = (dati) =>{
        setDatiTraining(dati);
        setFineAttivita(true)
    }

    //parser(audio)
    return(
        PazienteConsumer._currentValue.scriptTraining.id ?
        <>{!FineAttivita?
            <div className="ContainerAttivita">
                    <div style={{textAlign:"center",fontFamily:"Roboto",height:'auto'}}>
                        <h1>Training</h1>
                    </div>
                    <Attivita setAudio={(audio)=>{setAudio(audio)}} concludiTraining={(dati)=>concludiTraining(dati)}
                     setMessaggio={(messaggio)=>setMessaggio(messaggio)} identificativo={props.id} setVediInfo={(value)=>props.setVediInfo(value)} 
                     idScript={PazienteConsumer._currentValue.scriptTraining.id}/> 
            </div>:
                <>
                    <InvioDati datiTraining={datiTraining}/>
                </>}
            <br/>
            <a href="http://www.cbim.it/" >
                <img src={LogoCBIM} alt="C.B.I.M." style={{width:"4%",marginLeft:"48%"}}/>
            </a>
        </>
        :
        <Alert variant="danger">Non e' stato ancora assegnato nessuno script.</Alert>
    )
}