import React from 'react'
import Logo from '../../assets/CUP.png'
import {Row,Dropdown,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './HeaderPaziente.css'

export default function HeaderPaziente(props){
    return(
      <>
        <Alert className="AlertHeader">
          <Row>
              <div className="colLogo">
                <img src={Logo} alt="TecnoMed" className="LogoPic"/>
              </div>
              <div className="colMenu">
                <Dropdown className="DropDown" >
                    <Dropdown.Toggle id="dropdown" className="DropMenu">
                        <span className="MenuText" > 
                        <span  className="iconaMenu">
                        <FontAwesomeIcon icon={faBars} />&ensp;
                        </span>
                        MENU</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={()=>props.modificaPassword()}>
                          <h5>Modifica Password</h5>
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={()=>props.doLogout()}>
                          <h5>Logout</h5>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>  
        </Alert>
    </>
    )
}