import React,{useState,useContext} from 'react'
import StoricoScript from '../../../../components/BasicComponents/StoricoScript'
import SelezioneScriptDaAssegnare from './SelezioneScriptDaAssegnare'
import {Row,Col,Button,Alert} from 'react-bootstrap'
import parser from 'react-html-parser'
import {AuthContext,AuthConsumer} from '../../../../authentication'
import './ElencoScriptAssegnabili.css'

export default function ElencoScriptAssegnabili(props){
    const [scriptInAnteprima,setScriptInAnteprima]=useState(null)
    const [testoScript,setTestoScript]=useState("")
    const [cambiaScriptAssegnato,setCambiaScriptAssegnato]=useState(false)
    const [visualizzaStorico,setVisualizzaStorico]=useState(false)
    const [defaultNonEsiste,setDefaultNonEsiste]=useState(false)
    
    const {storicoPaziente} = useContext(AuthContext);

    const cambiaAnteprima=(script)=>{
        var testo=""
        var ordinamento =[]
        var frasiEstratte=[]
        if(script.elencoFrasi.length>0){
            script.elencoFrasi.forEach((frase)=>{
               if(frase.tipo === 1) {
                   ordinamento.push(frase.idFrase)
                   frasiEstratte.push(frase)
                }
            })
            ordinamento.sort();
            
            ordinamento.forEach((ordine)=>{
                frasiEstratte.forEach((fraseEstratta)=>{
                  if(ordine === fraseEstratta.idFrase) testo = testo+fraseEstratta.frase+'<br/>'  
                })
            })
            if(scriptInAnteprima !== script){
                setTestoScript(testo)
                setScriptInAnteprima(script)
                props.setScriptSelezionato(script)
            }
            
        }else{
            setTestoScript("Errore nel caricamento dei dati dello script")
        }   
    }

    const getDefaultScript=()=>{
        //Ultimo script assegnato
        var elencoDateAssegnazione=[]
        var ultimoScript = null
        if(storicoPaziente.length>0){
            storicoPaziente.forEach((assegnazione)=>{
                elencoDateAssegnazione.push(assegnazione.dataAssegnazione)
            })
            elencoDateAssegnazione.sort();
            var ultimoIndex = elencoDateAssegnazione.length - 1
            storicoPaziente.forEach((assegnazione)=>{
                if(assegnazione.visibile === true)
                    if(assegnazione.dataAssegnazione===elencoDateAssegnazione[ultimoIndex]) ultimoScript = assegnazione;
            })
            props.setUltimoScript(ultimoScript)
            if(ultimoScript !== null)
                cambiaAnteprima(ultimoScript);
        }else{
            setDefaultNonEsiste(true)
        }
    }

    return(
        <div>
            <br/>
            <Row>
                <Col>{AuthConsumer._currentValue.role===1 ?
                    <Button variant={cambiaScriptAssegnato===true?"primary":"outline-primary"} 
                    onClick={()=>{setCambiaScriptAssegnato(!cambiaScriptAssegnato);setVisualizzaStorico(false)}} 
                    style={{width:"32%"}}>
                        Assegna Script
                    </Button>
                    :null
                    }
                    <Button variant={visualizzaStorico===true?"primary":"outline-primary"}
                    onClick={()=>{setCambiaScriptAssegnato(false);setVisualizzaStorico(!visualizzaStorico)}}
                    style={AuthConsumer._currentValue.role===1 ? {marginLeft:"2%",width:"32%"} : {width:"49%"}}>
                        Storico script
                    </Button>
                    <Button variant="outline-primary" style={AuthConsumer._currentValue.role===1 ? {marginLeft:"2%",width:"32%"} : {marginLeft:"2%",width:"49%"}} onClick={()=>props.creaScript()}>
                        Crea nuovo script
                    </Button>
                    <br/><br/>
                    {cambiaScriptAssegnato?<SelezioneScriptDaAssegnare cambiaAnteprima={(script)=>cambiaAnteprima(script)} infoScript={(script)=>props.infoScript(script)}/>
                    :null}
                    {visualizzaStorico === true?
                    <StoricoScript idPaziente={props.idPaziente} infoScript={(script)=>props.infoScript(script)} 
                    cambiaAnteprima={(script)=>cambiaAnteprima(script)} anteprima={scriptInAnteprima}/>
                    :null}
                </Col>
                <Col xs="12" className="breakerGestione" />
                <Col>
                    <br/>
                    {scriptInAnteprima!==null?
                    <div>
                        <Row>
                         {/*   <Col md="4">
                                URL:
                            </Col>
                            <Col md="8">
                                {elemento!==undefined?
                                <a href={"https://www.youtube.com/watch?v="+elemento.IDVideo} target="_blank" rel="noreferrer">{elemento.IDVideo}</a>:null} 
                            </Col>*/}
                        </Row>
                        <Row>
                            <Col lg="2" md="4" s="3" xs="4">
                                Titolo:
                            </Col>
                            <Col style={{color:"red"}} lg="8" md="8" s="9" xs="8">
                                <strong>{scriptInAnteprima.titoloScript}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="2" md="4" s="3" xs="4">
                                Testo:
                            </Col>
                            <Col lg="8" md="8" s="9" xs="8">
                                <>{parser(testoScript)}</>
                            </Col>
                        </Row>
                        <br/>
                    </div>
                    :
                        defaultNonEsiste===true?
                        <Alert variant="danger">Non è stato ancora assegnato nessuno script al paziente con id : <b>{props.nominativo}</b></Alert>
                        :
                        getDefaultScript()}
                </Col>
            </Row>
        </div>
    )//return
}//Elenco script assegnabili component