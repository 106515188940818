import React, { Component } from 'react';
import axios from 'axios';

import api from '../api';

const PasswordContext = React.createContext();

const INITIAL_STATE = {
    ultimaRichiesta:null
}

class PasswordProvider extends Component {
    state = INITIAL_STATE;
  
    resetState = () =>{
        this.setState({...INITIAL_STATE});
    }

    modificaPassword =async (richiesta) =>{
        if(richiesta !== this.state.ultimaRichiesta){
            this.setState({ultimaRichiesta:richiesta})
            await api.modificaPassword(richiesta)
        }
    }

  render(){
      return(
      <PasswordContext.Provider value={{...this.state,modificaPassword:this.modificaPassword}}>
          {this.props.children}
      </PasswordContext.Provider>
      )
  }

}

const PasswordConsumer = PasswordContext.Consumer
export { PasswordProvider, PasswordConsumer, PasswordContext }